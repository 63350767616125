@charset "UTF-8";

:root {
	--blue: #5779D1;
	--indigo: #2d1582;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #F7394E;
	--orange: #fd7e14;
	--yellow: #F6BF33;
	--green: #28a745;
	--teal: #17AA81;
	--cyan: #00dffc;
	--white: #ffffff;
	--gray: #8c98a4;
	--gray-dark: #343a40;
	--primary: #5DD39E;
	--secondary: #50575e;
	--success: #2e843f;
	--info: #3952AF;
	--warning: #FFDD4A;
	--danger: #F26868;
	--light: #ffffff;
	--dark: #2F3249;
	--cheddar: #E8BB28;
	--whales: #3C5DE2;
	--super: #ccc;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--breakpoint-xxl: 1480px;
	--font-family-sans-serif: "Source Sans Pro", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
	box-sizing: border-box
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block
}

body {
	margin: 0;
	font-family: "Source Sans Pro", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.7;
	color: #0b103f;
	text-align: left;
	background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: .5rem
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0
}

dt {
	font-weight: 700
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

b,
strong {
	font-weight: bolder
}

small {
	font-size: 80%
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: #3952af;
	text-decoration: none;
	background-color: transparent
}

a:hover {
	color: #263775;
	text-decoration: underline
}

a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none
}

code,
kbd,
pre,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar
}

figure {
	margin: 0 0 1rem
}

img {
	vertical-align: middle;
	border-style: none
}

svg {
	overflow: hidden;
	vertical-align: middle
}

table {
	border-collapse: collapse
}

caption {
	padding-top: .75rem;
	padding-bottom: .75rem;
	color: #585858;
	text-align: left;
	caption-side: bottom
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent
}

label {
	display: inline-block;
	margin-bottom: .5rem
}

button {
	border-radius: 0
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

[role=button] {
	cursor: pointer
}

select {
	word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
	-webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
	cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
	padding: 0;
	border-style: none
}

input[type=checkbox],
input[type=radio] {
	box-sizing: border-box;
	padding: 0
}

textarea {
	overflow: auto;
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal
}

@media (max-width:1200px) {
	legend {
		font-size: calc(1.275rem + .3vw)
	}
}

progress {
	vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
	height: auto
}

[type=search] {
	outline-offset: -2px;
	-webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button
}

output {
	display: inline-block
}

summary {
	display: list-item;
	cursor: pointer
}

template {
	display: none
}

[hidden] {
	display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: .5rem;
	font-family: "Source Sans Pro", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	line-height: 1.2
}

.h1,
h1 {
	font-size: 2.5rem
}

@media (max-width:1200px) {

	.h1,
	h1 {
		font-size: calc(1.375rem + 1.5vw)
	}
}

.h2,
h2 {
	font-size: 2rem
}

@media (max-width:1200px) {

	.h2,
	h2 {
		font-size: calc(1.325rem + .9vw)
	}
}

.h3,
h3 {
	font-size: 1.75rem
}

@media (max-width:1200px) {

	.h3,
	h3 {
		font-size: calc(1.3rem + .6vw)
	}
}

.h4,
h4 {
	font-size: 1.5rem
}

@media (max-width:1200px) {

	.h4,
	h4 {
		font-size: calc(1.275rem + .3vw)
	}
}

.h5,
h5 {
	font-size: 1.25rem
}

.h6,
h6 {
	font-size: 1rem
}

.lead {
	font-size: 1.25rem;
	font-weight: 300
}

.display-1 {
	font-size: 6rem;
	font-weight: 700;
	line-height: .95
}

@media (max-width:1200px) {
	.display-1 {
		font-size: calc(1.725rem + 5.7vw)
	}
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 700;
	line-height: .95
}

@media (max-width:1200px) {
	.display-2 {
		font-size: calc(1.675rem + 5.1vw)
	}
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 700;
	line-height: .95
}

@media (max-width:1200px) {
	.display-3 {
		font-size: calc(1.575rem + 3.9vw)
	}
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 700;
	line-height: .95
}

@media (max-width:1200px) {
	.display-4 {
		font-size: calc(1.475rem + 2.7vw)
	}
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
	font-size: 80%;
	font-weight: 400
}

.mark,
mark {
	padding: .2em;
	background-color: #fcf8e3
}

.list-unstyled,
.secondary-menu ul {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none
}

.list-inline-item {
	display: inline-block
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem
}

.initialism {
	font-size: 90%;
	text-transform: uppercase
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #8c98a4
}

.blockquote-footer::before {
	content: "— "
}

.img-fluid {
	max-width: 100%;
	height: auto
}

.img-thumbnail {
	padding: .25rem;
	background-color: #fff;
	border: 1px solid #eff4f9;
	border-radius: .25rem;
	max-width: 100%;
	height: auto
}

.figure {
	display: inline-block
}

.figure-img {
	margin-bottom: .5rem;
	line-height: 1
}

.figure-caption {
	font-size: 90%;
	color: #8c98a4
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto
}

@media (min-width:576px) {

	.container,
	.container-sm {
		max-width: 540px
	}
}

@media (min-width:768px) {

	.container,
	.container-md,
	.container-sm {
		max-width: 720px
	}
}

@media (min-width:992px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px
	}
}

@media (min-width:1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px
	}
}

@media (min-width:1480px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1420px
	}
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -1rem;
	margin-left: -1rem
}

.no-gutters {
	margin-right: 0;
	margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
	padding-right: 0;
	padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto {
	position: relative;
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%
}

.row-cols-1>* {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%
}

.row-cols-2>* {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%
}

.row-cols-3>* {
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%
}

.row-cols-4>* {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%
}

.row-cols-5>* {
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%
}

.row-cols-6>* {
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%
}

.col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%
}

.col-1 {
	-ms-flex: 0 0 8.3333333333%;
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%
}

.col-2 {
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%
}

.col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%
}

.col-4 {
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%
}

.col-5 {
	-ms-flex: 0 0 41.6666666667%;
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%
}

.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%
}

.col-7 {
	-ms-flex: 0 0 58.3333333333%;
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%
}

.col-8 {
	-ms-flex: 0 0 66.6666666667%;
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%
}

.col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%
}

.col-10 {
	-ms-flex: 0 0 83.3333333333%;
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%
}

.col-11 {
	-ms-flex: 0 0 91.6666666667%;
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%
}

.col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%
}

.order-first {
	-ms-flex-order: -1;
	order: -1
}

.order-last {
	-ms-flex-order: 13;
	order: 13
}

.order-0 {
	-ms-flex-order: 0;
	order: 0
}

.order-1 {
	-ms-flex-order: 1;
	order: 1
}

.order-2 {
	-ms-flex-order: 2;
	order: 2
}

.order-3 {
	-ms-flex-order: 3;
	order: 3
}

.order-4 {
	-ms-flex-order: 4;
	order: 4
}

.order-5 {
	-ms-flex-order: 5;
	order: 5
}

.order-6 {
	-ms-flex-order: 6;
	order: 6
}

.order-7 {
	-ms-flex-order: 7;
	order: 7
}

.order-8 {
	-ms-flex-order: 8;
	order: 8
}

.order-9 {
	-ms-flex-order: 9;
	order: 9
}

.order-10 {
	-ms-flex-order: 10;
	order: 10
}

.order-11 {
	-ms-flex-order: 11;
	order: 11
}

.order-12 {
	-ms-flex-order: 12;
	order: 12
}

.offset-1 {
	margin-left: 8.3333333333%
}

.offset-2 {
	margin-left: 16.6666666667%
}

.offset-3 {
	margin-left: 25%
}

.offset-4 {
	margin-left: 33.3333333333%
}

.offset-5 {
	margin-left: 41.6666666667%
}

.offset-6 {
	margin-left: 50%
}

.offset-7 {
	margin-left: 58.3333333333%
}

.offset-8 {
	margin-left: 66.6666666667%
}

.offset-9 {
	margin-left: 75%
}

.offset-10 {
	margin-left: 83.3333333333%
}

.offset-11 {
	margin-left: 91.6666666667%
}

@media (min-width:576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.row-cols-sm-1>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.row-cols-sm-2>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.row-cols-sm-3>* {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.row-cols-sm-4>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.row-cols-sm-5>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%
	}

	.row-cols-sm-6>* {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-sm-1 {
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}

	.col-sm-2 {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-sm-4 {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.col-sm-5 {
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}

	.col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-sm-7 {
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}

	.col-sm-8 {
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}

	.col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-sm-10 {
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}

	.col-sm-11 {
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}

	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-sm-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-sm-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-sm-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-sm-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-sm-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-sm-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-sm-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-sm-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-sm-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-sm-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-sm-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-sm-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-sm-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-sm-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-sm-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-sm-0 {
		margin-left: 0
	}

	.offset-sm-1 {
		margin-left: 8.3333333333%
	}

	.offset-sm-2 {
		margin-left: 16.6666666667%
	}

	.offset-sm-3 {
		margin-left: 25%
	}

	.offset-sm-4 {
		margin-left: 33.3333333333%
	}

	.offset-sm-5 {
		margin-left: 41.6666666667%
	}

	.offset-sm-6 {
		margin-left: 50%
	}

	.offset-sm-7 {
		margin-left: 58.3333333333%
	}

	.offset-sm-8 {
		margin-left: 66.6666666667%
	}

	.offset-sm-9 {
		margin-left: 75%
	}

	.offset-sm-10 {
		margin-left: 83.3333333333%
	}

	.offset-sm-11 {
		margin-left: 91.6666666667%
	}
}

@media (min-width:768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.row-cols-md-1>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.row-cols-md-2>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.row-cols-md-3>* {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.row-cols-md-4>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.row-cols-md-5>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%
	}

	.row-cols-md-6>* {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-md-1 {
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}

	.col-md-2 {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-md-4 {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.col-md-5 {
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}

	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-md-7 {
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}

	.col-md-8 {
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}

	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-md-10 {
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}

	.col-md-11 {
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}

	.col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-md-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-md-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-md-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-md-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-md-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-md-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-md-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-md-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-md-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-md-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-md-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-md-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-md-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-md-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-md-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-md-0 {
		margin-left: 0
	}

	.offset-md-1 {
		margin-left: 8.3333333333%
	}

	.offset-md-2 {
		margin-left: 16.6666666667%
	}

	.offset-md-3 {
		margin-left: 25%
	}

	.offset-md-4 {
		margin-left: 33.3333333333%
	}

	.offset-md-5 {
		margin-left: 41.6666666667%
	}

	.offset-md-6 {
		margin-left: 50%
	}

	.offset-md-7 {
		margin-left: 58.3333333333%
	}

	.offset-md-8 {
		margin-left: 66.6666666667%
	}

	.offset-md-9 {
		margin-left: 75%
	}

	.offset-md-10 {
		margin-left: 83.3333333333%
	}

	.offset-md-11 {
		margin-left: 91.6666666667%
	}
}

@media (min-width:992px) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.row-cols-lg-1>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.row-cols-lg-2>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.row-cols-lg-3>* {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.row-cols-lg-4>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.row-cols-lg-5>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%
	}

	.row-cols-lg-6>* {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-lg-1 {
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}

	.col-lg-2 {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-lg-4 {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.col-lg-5 {
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}

	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-lg-7 {
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}

	.col-lg-8 {
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}

	.col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-lg-10 {
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}

	.col-lg-11 {
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}

	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-lg-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-lg-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-lg-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-lg-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-lg-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-lg-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-lg-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-lg-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-lg-0 {
		margin-left: 0
	}

	.offset-lg-1 {
		margin-left: 8.3333333333%
	}

	.offset-lg-2 {
		margin-left: 16.6666666667%
	}

	.offset-lg-3 {
		margin-left: 25%
	}

	.offset-lg-4 {
		margin-left: 33.3333333333%
	}

	.offset-lg-5 {
		margin-left: 41.6666666667%
	}

	.offset-lg-6 {
		margin-left: 50%
	}

	.offset-lg-7 {
		margin-left: 58.3333333333%
	}

	.offset-lg-8 {
		margin-left: 66.6666666667%
	}

	.offset-lg-9 {
		margin-left: 75%
	}

	.offset-lg-10 {
		margin-left: 83.3333333333%
	}

	.offset-lg-11 {
		margin-left: 91.6666666667%
	}
}

@media (min-width:1200px) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.row-cols-xl-1>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.row-cols-xl-2>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.row-cols-xl-3>* {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.row-cols-xl-4>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.row-cols-xl-5>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%
	}

	.row-cols-xl-6>* {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-xl-1 {
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}

	.col-xl-2 {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-xl-4 {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.col-xl-5 {
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}

	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-xl-7 {
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}

	.col-xl-8 {
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}

	.col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-xl-10 {
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}

	.col-xl-11 {
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}

	.col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-xl-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-xl-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-xl-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-xl-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-xl-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-xl-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-xl-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-xl-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-xl-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-xl-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-xl-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-xl-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-xl-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-xl-0 {
		margin-left: 0
	}

	.offset-xl-1 {
		margin-left: 8.3333333333%
	}

	.offset-xl-2 {
		margin-left: 16.6666666667%
	}

	.offset-xl-3 {
		margin-left: 25%
	}

	.offset-xl-4 {
		margin-left: 33.3333333333%
	}

	.offset-xl-5 {
		margin-left: 41.6666666667%
	}

	.offset-xl-6 {
		margin-left: 50%
	}

	.offset-xl-7 {
		margin-left: 58.3333333333%
	}

	.offset-xl-8 {
		margin-left: 66.6666666667%
	}

	.offset-xl-9 {
		margin-left: 75%
	}

	.offset-xl-10 {
		margin-left: 83.3333333333%
	}

	.offset-xl-11 {
		margin-left: 91.6666666667%
	}
}

@media (min-width:1480px) {
	.col-xxl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.row-cols-xxl-1>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.row-cols-xxl-2>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.row-cols-xxl-3>* {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.row-cols-xxl-4>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.row-cols-xxl-5>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%
	}

	.row-cols-xxl-6>* {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-xxl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-xxl-1 {
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}

	.col-xxl-2 {
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}

	.col-xxl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-xxl-4 {
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}

	.col-xxl-5 {
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}

	.col-xxl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-xxl-7 {
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}

	.col-xxl-8 {
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}

	.col-xxl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-xxl-10 {
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}

	.col-xxl-11 {
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}

	.col-xxl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-xxl-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-xxl-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-xxl-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-xxl-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-xxl-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-xxl-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-xxl-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-xxl-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-xxl-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-xxl-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-xxl-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-xxl-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-xxl-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-xxl-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-xxl-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-xxl-0 {
		margin-left: 0
	}

	.offset-xxl-1 {
		margin-left: 8.3333333333%
	}

	.offset-xxl-2 {
		margin-left: 16.6666666667%
	}

	.offset-xxl-3 {
		margin-left: 25%
	}

	.offset-xxl-4 {
		margin-left: 33.3333333333%
	}

	.offset-xxl-5 {
		margin-left: 41.6666666667%
	}

	.offset-xxl-6 {
		margin-left: 50%
	}

	.offset-xxl-7 {
		margin-left: 58.3333333333%
	}

	.offset-xxl-8 {
		margin-left: 66.6666666667%
	}

	.offset-xxl-9 {
		margin-left: 75%
	}

	.offset-xxl-10 {
		margin-left: 83.3333333333%
	}

	.offset-xxl-11 {
		margin-left: 91.6666666667%
	}
}

.table,
.text-formatted table {
	width: 100%;
	margin-bottom: 1rem;
	color: #0b103f
}

.table td,
.table th,
.text-formatted table td,
.text-formatted table th {
	padding: .75rem;
	vertical-align: top;
	border-top: 1px solid #eff4f9
}

.table thead th,
.text-formatted table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #eff4f9
}

.table tbody+tbody,
.text-formatted table tbody+tbody {
	border-top: 2px solid #eff4f9
}

.table-sm td,
.table-sm th {
	padding: .3rem
}

.table-bordered,
.text-formatted table {
	border: 1px solid #eff4f9
}

.table-bordered td,
.table-bordered th,
.text-formatted table td,
.text-formatted table th {
	border: 1px solid #eff4f9
}

.table-bordered thead td,
.table-bordered thead th,
.text-formatted table thead td,
.text-formatted table thead th {
	border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
	border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
	color: #0b103f;
	background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
	background-color: #d2f3e4
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
	border-color: #abe8cd
}

.table-hover .table-primary:hover {
	background-color: #beeed8
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #beeed8
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
	background-color: #ced0d2
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
	border-color: #a4a8ab
}

.table-hover .table-secondary:hover {
	background-color: #c1c3c6
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
	background-color: #c1c3c6
}

.table-success,
.table-success>td,
.table-success>th {
	background-color: #c4ddc9
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
	border-color: #92bf9b
}

.table-hover .table-success:hover {
	background-color: #b4d4ba
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #b4d4ba
}

.table-info,
.table-info>td,
.table-info>th {
	background-color: #c8cfe9
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
	border-color: #98a5d5
}

.table-hover .table-info:hover {
	background-color: #b6bfe2
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #b6bfe2
}

.table-warning,
.table-warning>td,
.table-warning>th {
	background-color: #fff5cc
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
	border-color: #ffeda1
}

.table-hover .table-warning:hover {
	background-color: #fff0b3
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #fff0b3
}

.table-danger,
.table-danger>td,
.table-danger>th {
	background-color: #fbd5d5
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
	border-color: #f8b0b0
}

.table-hover .table-danger:hover {
	background-color: #f9bebe
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #f9bebe
}

.table-light,
.table-light>td,
.table-light>th {
	background-color: #fff
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
	border-color: #fff
}

.table-hover .table-light:hover {
	background-color: #f2f2f2
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
	background-color: #f2f2f2
}

.table-dark,
.table-dark>td,
.table-dark>th {
	background-color: #c5c6cc
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
	border-color: #9394a0
}

.table-hover .table-dark:hover {
	background-color: #b7b9c0
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
	background-color: #b7b9c0
}

.table-cheddar,
.table-cheddar>td,
.table-cheddar>th {
	background-color: #f9ecc3
}

.table-cheddar tbody+tbody,
.table-cheddar td,
.table-cheddar th,
.table-cheddar thead th {
	border-color: #f3dc8f
}

.table-hover .table-cheddar:hover {
	background-color: #f7e5ac
}

.table-hover .table-cheddar:hover>td,
.table-hover .table-cheddar:hover>th {
	background-color: #f7e5ac
}

.table-whales,
.table-whales>td,
.table-whales>th {
	background-color: #c8d2f7
}

.table-whales tbody+tbody,
.table-whales td,
.table-whales th,
.table-whales thead th {
	border-color: #9aabf0
}

.table-hover .table-whales:hover {
	background-color: #b2c0f4
}

.table-hover .table-whales:hover>td,
.table-hover .table-whales:hover>th {
	background-color: #b2c0f4
}

.table-super,
.table-super>td,
.table-super>th {
	background-color: #f1f1f1
}

.table-super tbody+tbody,
.table-super td,
.table-super th,
.table-super thead th {
	border-color: #e4e4e4
}

.table-hover .table-super:hover {
	background-color: #e4e4e4
}

.table-hover .table-super:hover>td,
.table-hover .table-super:hover>th {
	background-color: #e4e4e4
}

.table-active,
.table-active>td,
.table-active>th {
	background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th,
.text-formatted table .thead-dark th {
	color: #fff;
	background-color: #2f3249;
	border-color: #3e4260
}

.table .thead-light th,
.text-formatted table .thead-light th {
	color: #77838f;
	background-color: #f8fafc;
	border-color: #eff4f9
}

.table-dark {
	color: #fff;
	background-color: #2f3249
}

.table-dark td,
.table-dark th,
.table-dark thead th {
	border-color: #3e4260
}

.table-dark.table-bordered,
.text-formatted table.table-dark {
	border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}

	.table-responsive-sm>.table-bordered,
	.text-formatted .table-responsive-sm>table {
		border: 0
	}
}

@media (max-width:767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}

	.table-responsive-md>.table-bordered,
	.text-formatted .table-responsive-md>table {
		border: 0
	}
}

@media (max-width:991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}

	.table-responsive-lg>.table-bordered,
	.text-formatted .table-responsive-lg>table {
		border: 0
	}
}

@media (max-width:1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}

	.table-responsive-xl>.table-bordered,
	.text-formatted .table-responsive-xl>table {
		border: 0
	}
}

@media (max-width:1479.98px) {
	.table-responsive-xxl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}

	.table-responsive-xxl>.table-bordered,
	.text-formatted .table-responsive-xxl>table {
		border: 0
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered,
.text-formatted .table-responsive>table {
	border: 0
}

.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 1.625rem + 2px);
	padding: .8125rem 1.875rem;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #0b103f;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e7eaed;
	border-radius: 6px;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-control {
		transition: none
	}
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0
}

.form-control:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #0b103f
}

.form-control:focus {
	color: #0b103f;
	background-color: #fff;
	border-color: #c1eeda;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .25)
}

.form-control::-webkit-input-placeholder {
	color: rgba(47, 50, 73, .6);
	opacity: 1
}

.form-control::-moz-placeholder {
	color: rgba(47, 50, 73, .6);
	opacity: 1
}

.form-control:-ms-input-placeholder {
	color: rgba(47, 50, 73, .6);
	opacity: 1
}

.form-control::-ms-input-placeholder {
	color: rgba(47, 50, 73, .6);
	opacity: 1
}

.form-control::placeholder {
	color: rgba(47, 50, 73, .6);
	opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #f8fafc;
	opacity: 1
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

select.form-control:focus::-ms-value {
	color: #0b103f;
	background-color: #fff
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%
}

.col-form-label {
	padding-top: calc(.8125rem + 1px);
	padding-bottom: calc(.8125rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5
}

.col-form-label-lg {
	padding-top: calc(.96875rem + 1px);
	padding-bottom: calc(.96875rem + 1px);
	font-size: 1.125rem;
	line-height: 1.5
}

.col-form-label-sm {
	padding-top: calc(.71875rem + 1px);
	padding-bottom: calc(.71875rem + 1px);
	font-size: 1.125rem;
	line-height: 1.5
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: .8125rem 0;
	margin-bottom: 0;
	font-size: 1.125rem;
	line-height: 1.5;
	color: #0b103f;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
	padding-right: 0;
	padding-left: 0
}

.form-control-sm {
	height: calc(1.5em + 1.4375rem + 2px);
	padding: .71875rem 1.5rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

.form-control-lg {
	height: calc(1.5em + 1.9375rem + 2px);
	padding: .96875rem 2rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

select.form-control[multiple],
select.form-control[size] {
	height: auto
}

textarea.form-control {
	height: auto
}

.form-group {
	margin-bottom: 1rem
}

.form-text {
	display: block;
	margin-top: .25rem
}

.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
	padding-right: 5px;
	padding-left: 5px
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem
}

.form-check-input {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
	color: #585858
}

.form-check-label {
	margin-bottom: 0
}

.form-check-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: .75rem
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: .3125rem;
	margin-left: 0
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #2e843f
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1.7;
	color: #fff;
	background-color: rgba(46, 132, 63, .9);
	border-radius: .25rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
	display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
	border-color: #2e843f
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
	border-color: #2e843f;
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .25)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
	border-color: #2e843f
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
	border-color: #2e843f;
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
	color: #2e843f
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
	display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
	color: #2e843f
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
	border-color: #2e843f
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
	border-color: #3baa51;
	background-color: #3baa51
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
	border-color: #2e843f
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
	border-color: #2e843f
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
	border-color: #2e843f;
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .25)
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #f26868
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1.7;
	color: #fff;
	background-color: rgba(242, 104, 104, .9);
	border-radius: .25rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
	display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	border-color: #f26868
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
	border-color: #f26868;
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .25)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
	border-color: #f26868
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
	border-color: #f26868;
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
	color: #f26868
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
	display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
	color: #f26868
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
	border-color: #f26868
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
	border-color: #f69797;
	background-color: #f69797
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
	border-color: #f26868
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
	border-color: #f26868
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
	border-color: #f26868;
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .25)
}

.form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center
}

.form-inline .form-check {
	width: 100%
}

@media (min-width:576px) {
	.form-inline label {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-bottom: 0
	}

	.form-inline .form-group {
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 0
	}

	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}

	.form-inline .form-control-plaintext {
		display: inline-block
	}

	.form-inline .custom-select,
	.form-inline .input-group {
		width: auto
	}

	.form-inline .form-check {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: auto;
		padding-left: 0
	}

	.form-inline .form-check-input {
		position: relative;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-top: 0;
		margin-right: .25rem;
		margin-left: 0
	}

	.form-inline .custom-control {
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center
	}

	.form-inline .custom-control-label {
		margin-bottom: 0
	}
}

.btn {
	display: inline-block;
	font-weight: 600;
	color: #0b103f;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .8125rem 1.875rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.btn {
		transition: none
	}
}

.btn:hover {
	color: #0b103f;
	text-decoration: none
}

.btn.focus,
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .25)
}

.btn.disabled,
.btn:disabled {
	opacity: .65
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none
}

.btn-primary {
	color: #1e2022;
	background-color: #5dd39e;
	border-color: #5dd39e
}

.btn-primary:hover {
	color: #1e2022;
	background-color: #3fcb8c;
	border-color: #36c786
}

.btn-primary.focus,
.btn-primary:focus {
	color: #1e2022;
	background-color: #3fcb8c;
	border-color: #36c786;
	box-shadow: 0 0 0 .2rem rgba(84, 184, 139, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: #1e2022;
	background-color: #5dd39e;
	border-color: #5dd39e
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #36c786;
	border-color: #33bd7f
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(84, 184, 139, .5)
}

.btn-secondary {
	color: #fff;
	background-color: #50575e;
	border-color: #50575e
}

.btn-secondary:hover {
	color: #fff;
	background-color: #3e4449;
	border-color: #393e42
}

.btn-secondary.focus,
.btn-secondary:focus {
	color: #fff;
	background-color: #3e4449;
	border-color: #393e42;
	box-shadow: 0 0 0 .2rem rgba(106, 112, 118, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #50575e;
	border-color: #50575e
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #393e42;
	border-color: #33373c
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(106, 112, 118, .5)
}

.btn-success {
	color: #fff;
	background-color: #2e843f;
	border-color: #2e843f
}

.btn-success:hover {
	color: #fff;
	background-color: #246831;
	border-color: #215e2d
}

.btn-success.focus,
.btn-success:focus {
	color: #fff;
	background-color: #246831;
	border-color: #215e2d;
	box-shadow: 0 0 0 .2rem rgba(77, 150, 92, .5)
}

.btn-success.disabled,
.btn-success:disabled {
	color: #fff;
	background-color: #2e843f;
	border-color: #2e843f
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #215e2d;
	border-color: #1e5528
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(77, 150, 92, .5)
}

.btn-info {
	color: #fff;
	background-color: #3952af;
	border-color: #3952af
}

.btn-info:hover {
	color: #fff;
	background-color: #304492;
	border-color: #2c4089
}

.btn-info.focus,
.btn-info:focus {
	color: #fff;
	background-color: #304492;
	border-color: #2c4089;
	box-shadow: 0 0 0 .2rem rgba(87, 108, 187, .5)
}

.btn-info.disabled,
.btn-info:disabled {
	color: #fff;
	background-color: #3952af;
	border-color: #3952af
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
	color: #fff;
	background-color: #2c4089;
	border-color: #293b7f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(87, 108, 187, .5)
}

.btn-warning {
	color: #1e2022;
	background-color: #ffdd4a;
	border-color: #ffdd4a
}

.btn-warning:hover {
	color: #1e2022;
	background-color: #ffd624;
	border-color: #ffd317
}

.btn-warning.focus,
.btn-warning:focus {
	color: #1e2022;
	background-color: #ffd624;
	border-color: #ffd317;
	box-shadow: 0 0 0 .2rem rgba(221, 193, 68, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #1e2022;
	background-color: #ffdd4a;
	border-color: #ffdd4a
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
	color: #1e2022;
	background-color: #ffd317;
	border-color: #ffd10a
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(221, 193, 68, .5)
}

.btn-danger {
	color: #fff;
	background-color: #f26868;
	border-color: #f26868
}

.btn-danger:hover {
	color: #fff;
	background-color: #ef4545;
	border-color: #ee3939
}

.btn-danger.focus,
.btn-danger:focus {
	color: #fff;
	background-color: #ef4545;
	border-color: #ee3939;
	box-shadow: 0 0 0 .2rem rgba(244, 127, 127, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
	color: #fff;
	background-color: #f26868;
	border-color: #f26868
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #ee3939;
	border-color: #ed2d2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(244, 127, 127, .5)
}

.btn-light {
	color: #1e2022;
	background-color: #fff;
	border-color: #fff
}

.btn-light:hover {
	color: #1e2022;
	background-color: #ececec;
	border-color: #e6e6e6
}

.btn-light.focus,
.btn-light:focus {
	color: #1e2022;
	background-color: #ececec;
	border-color: #e6e6e6;
	box-shadow: 0 0 0 .2rem rgba(221, 222, 222, .5)
}

.btn-light.disabled,
.btn-light:disabled {
	color: #1e2022;
	background-color: #fff;
	border-color: #fff
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
	color: #1e2022;
	background-color: #e6e6e6;
	border-color: #dfdfdf
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(221, 222, 222, .5)
}

.btn-dark {
	color: #fff;
	background-color: #2f3249;
	border-color: #2f3249
}

.btn-dark:hover {
	color: #fff;
	background-color: #202232;
	border-color: #1b1d2a
}

.btn-dark.focus,
.btn-dark:focus {
	color: #fff;
	background-color: #202232;
	border-color: #1b1d2a;
	box-shadow: 0 0 0 .2rem rgba(78, 81, 100, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
	color: #fff;
	background-color: #2f3249;
	border-color: #2f3249
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1b1d2a;
	border-color: #161722
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(78, 81, 100, .5)
}

.btn-cheddar {
	color: #1e2022;
	background-color: #e8bb28;
	border-color: #e8bb28
}

.btn-cheddar:hover {
	color: #1e2022;
	background-color: #d3a717;
	border-color: #c89e15
}

.btn-cheddar.focus,
.btn-cheddar:focus {
	color: #1e2022;
	background-color: #d3a717;
	border-color: #c89e15;
	box-shadow: 0 0 0 .2rem rgba(202, 164, 39, .5)
}

.btn-cheddar.disabled,
.btn-cheddar:disabled {
	color: #1e2022;
	background-color: #e8bb28;
	border-color: #e8bb28
}

.btn-cheddar:not(:disabled):not(.disabled).active,
.btn-cheddar:not(:disabled):not(.disabled):active,
.show>.btn-cheddar.dropdown-toggle {
	color: #1e2022;
	background-color: #c89e15;
	border-color: #bc9514
}

.btn-cheddar:not(:disabled):not(.disabled).active:focus,
.btn-cheddar:not(:disabled):not(.disabled):active:focus,
.show>.btn-cheddar.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(202, 164, 39, .5)
}

.btn-whales {
	color: #fff;
	background-color: #3c5de2;
	border-color: #3c5de2
}

.btn-whales:hover {
	color: #fff;
	background-color: #2045d8;
	border-color: #1e41cd
}

.btn-whales.focus,
.btn-whales:focus {
	color: #fff;
	background-color: #2045d8;
	border-color: #1e41cd;
	box-shadow: 0 0 0 .2rem rgba(89, 117, 230, .5)
}

.btn-whales.disabled,
.btn-whales:disabled {
	color: #fff;
	background-color: #3c5de2;
	border-color: #3c5de2
}

.btn-whales:not(:disabled):not(.disabled).active,
.btn-whales:not(:disabled):not(.disabled):active,
.show>.btn-whales.dropdown-toggle {
	color: #fff;
	background-color: #1e41cd;
	border-color: #1d3ec1
}

.btn-whales:not(:disabled):not(.disabled).active:focus,
.btn-whales:not(:disabled):not(.disabled):active:focus,
.show>.btn-whales.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(89, 117, 230, .5)
}

.btn-super {
	color: #1e2022;
	background-color: #ccc;
	border-color: #ccc
}

.btn-super:hover {
	color: #1e2022;
	background-color: #b9b9b9;
	border-color: #b3b3b3
}

.btn-super.focus,
.btn-super:focus {
	color: #1e2022;
	background-color: #b9b9b9;
	border-color: #b3b3b3;
	box-shadow: 0 0 0 .2rem rgba(178, 178, 179, .5)
}

.btn-super.disabled,
.btn-super:disabled {
	color: #1e2022;
	background-color: #ccc;
	border-color: #ccc
}

.btn-super:not(:disabled):not(.disabled).active,
.btn-super:not(:disabled):not(.disabled):active,
.show>.btn-super.dropdown-toggle {
	color: #1e2022;
	background-color: #b3b3b3;
	border-color: #acacac
}

.btn-super:not(:disabled):not(.disabled).active:focus,
.btn-super:not(:disabled):not(.disabled):active:focus,
.show>.btn-super.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(178, 178, 179, .5)
}

.btn-outline-primary {
	color: #5dd39e;
	border-color: #5dd39e
}

.btn-outline-primary:hover {
	color: #1e2022;
	background-color: #5dd39e;
	border-color: #5dd39e
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #5dd39e;
	background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #1e2022;
	background-color: #5dd39e;
	border-color: #5dd39e
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .5)
}

.btn-outline-secondary {
	color: #50575e;
	border-color: #50575e
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #50575e;
	border-color: #50575e
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
	box-shadow: 0 0 0 .2rem rgba(80, 87, 94, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #50575e;
	background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #50575e;
	border-color: #50575e
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(80, 87, 94, .5)
}

.btn-outline-success {
	color: #2e843f;
	border-color: #2e843f
}

.btn-outline-success:hover {
	color: #fff;
	background-color: #2e843f;
	border-color: #2e843f
}

.btn-outline-success.focus,
.btn-outline-success:focus {
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #2e843f;
	background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: #2e843f;
	border-color: #2e843f
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(46, 132, 63, .5)
}

.btn-outline-info {
	color: #3952af;
	border-color: #3952af
}

.btn-outline-info:hover {
	color: #fff;
	background-color: #3952af;
	border-color: #3952af
}

.btn-outline-info.focus,
.btn-outline-info:focus {
	box-shadow: 0 0 0 .2rem rgba(57, 82, 175, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #3952af;
	background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #3952af;
	border-color: #3952af
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(57, 82, 175, .5)
}

.btn-outline-warning {
	color: #ffdd4a;
	border-color: #ffdd4a
}

.btn-outline-warning:hover {
	color: #1e2022;
	background-color: #ffdd4a;
	border-color: #ffdd4a
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 74, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #ffdd4a;
	background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #1e2022;
	background-color: #ffdd4a;
	border-color: #ffdd4a
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 74, .5)
}

.btn-outline-danger {
	color: #f26868;
	border-color: #f26868
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #f26868;
	border-color: #f26868
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #f26868;
	background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #f26868;
	border-color: #f26868
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(242, 104, 104, .5)
}

.btn-outline-light {
	color: #fff;
	border-color: #fff
}

.btn-outline-light:hover {
	color: #1e2022;
	background-color: #fff;
	border-color: #fff
}

.btn-outline-light.focus,
.btn-outline-light:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #fff;
	background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
	color: #1e2022;
	background-color: #fff;
	border-color: #fff
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-dark {
	color: #2f3249;
	border-color: #2f3249
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #2f3249;
	border-color: #2f3249
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
	box-shadow: 0 0 0 .2rem rgba(47, 50, 73, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #2f3249;
	background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #2f3249;
	border-color: #2f3249
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(47, 50, 73, .5)
}

.btn-outline-cheddar {
	color: #e8bb28;
	border-color: #e8bb28
}

.btn-outline-cheddar:hover {
	color: #1e2022;
	background-color: #e8bb28;
	border-color: #e8bb28
}

.btn-outline-cheddar.focus,
.btn-outline-cheddar:focus {
	box-shadow: 0 0 0 .2rem rgba(232, 187, 40, .5)
}

.btn-outline-cheddar.disabled,
.btn-outline-cheddar:disabled {
	color: #e8bb28;
	background-color: transparent
}

.btn-outline-cheddar:not(:disabled):not(.disabled).active,
.btn-outline-cheddar:not(:disabled):not(.disabled):active,
.show>.btn-outline-cheddar.dropdown-toggle {
	color: #1e2022;
	background-color: #e8bb28;
	border-color: #e8bb28
}

.btn-outline-cheddar:not(:disabled):not(.disabled).active:focus,
.btn-outline-cheddar:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-cheddar.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(232, 187, 40, .5)
}

.btn-outline-whales {
	color: #3c5de2;
	border-color: #3c5de2
}

.btn-outline-whales:hover {
	color: #fff;
	background-color: #3c5de2;
	border-color: #3c5de2
}

.btn-outline-whales.focus,
.btn-outline-whales:focus {
	box-shadow: 0 0 0 .2rem rgba(60, 93, 226, .5)
}

.btn-outline-whales.disabled,
.btn-outline-whales:disabled {
	color: #3c5de2;
	background-color: transparent
}

.btn-outline-whales:not(:disabled):not(.disabled).active,
.btn-outline-whales:not(:disabled):not(.disabled):active,
.show>.btn-outline-whales.dropdown-toggle {
	color: #fff;
	background-color: #3c5de2;
	border-color: #3c5de2
}

.btn-outline-whales:not(:disabled):not(.disabled).active:focus,
.btn-outline-whales:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-whales.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(60, 93, 226, .5)
}

.btn-outline-super {
	color: #ccc;
	border-color: #ccc
}

.btn-outline-super:hover {
	color: #1e2022;
	background-color: #ccc;
	border-color: #ccc
}

.btn-outline-super.focus,
.btn-outline-super:focus {
	box-shadow: 0 0 0 .2rem rgba(204, 204, 204, .5)
}

.btn-outline-super.disabled,
.btn-outline-super:disabled {
	color: #ccc;
	background-color: transparent
}

.btn-outline-super:not(:disabled):not(.disabled).active,
.btn-outline-super:not(:disabled):not(.disabled):active,
.show>.btn-outline-super.dropdown-toggle {
	color: #1e2022;
	background-color: #ccc;
	border-color: #ccc
}

.btn-outline-super:not(:disabled):not(.disabled).active:focus,
.btn-outline-super:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-super.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(204, 204, 204, .5)
}

.btn-link {
	font-weight: 400;
	color: #3952af;
	text-decoration: none
}

.btn-link:hover {
	color: #263775;
	text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
	text-decoration: underline
}

.btn-link.disabled,
.btn-link:disabled {
	color: #8c98a4;
	pointer-events: none
}

.btn-lg {
	padding: .96875rem 2rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

.btn-sm {
	padding: .71875rem 1.5rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

.btn-block {
	display: block;
	width: 100%
}

.btn-block+.btn-block {
	margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
	width: 100%
}

.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
	margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
	z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
	z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group>.custom-file {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
	display: -ms-flexbox;
	display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
	position: relative;
	z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
	z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
	margin-left: -1px
}

.input-group-prepend {
	margin-right: -1px
}

.input-group-append {
	margin-left: -1px
}

.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: .8125rem 1.875rem;
	margin-bottom: 0;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #0b103f;
	text-align: center;
	white-space: nowrap;
	background-color: #f8fafc;
	border: 1px solid #e7eaed;
	border-radius: 6px
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
	margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
	height: calc(1.5em + 1.9375rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
	padding: .96875rem 2rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
	height: calc(1.5em + 1.4375rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
	padding: .71875rem 1.5rem;
	font-size: 1.125rem;
	line-height: 1.5;
	border-radius: 6px
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
	padding-right: 2.875rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.custom-control {
	position: relative;
	z-index: 1;
	display: block;
	min-height: 1.7rem;
	padding-left: 1.5rem;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem
}

.custom-control-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.35rem;
	opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: #5dd39e;
	background-color: #5dd39e
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: #c1eeda
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	color: #fff;
	background-color: #e9f9f2;
	border-color: #e9f9f2
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
	color: #8c98a4
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
	background-color: #f8fafc
}

.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top
}

.custom-control-label::before {
	position: absolute;
	top: .35rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	background-color: #fff;
	border: #97a4af solid 1px
}

.custom-control-label::after {
	position: absolute;
	top: .35rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
	border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
	border-color: #5dd39e;
	background-color: #5dd39e
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(93, 211, 158, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
	background-color: rgba(93, 211, 158, .5)
}

.custom-radio .custom-control-label::before {
	border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(93, 211, 158, .5)
}

.custom-switch {
	padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
	left: -2.25rem;
	width: 1.75rem;
	pointer-events: all;
	border-radius: .5rem
}

.custom-switch .custom-control-label::after {
	top: calc(.35rem + 2px);
	left: calc(-2.25rem + 2px);
	width: calc(1rem - 4px);
	height: calc(1rem - 4px);
	background-color: #97a4af;
	border-radius: .5rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
	transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.custom-switch .custom-control-label::after {
		transition: none
	}
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
	background-color: #fff;
	-webkit-transform: translateX(.75rem);
	transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(93, 211, 158, .5)
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 1.625rem + 2px);
	padding: .8125rem 2.875rem .8125rem 1.875rem;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #0b103f;
	vertical-align: middle;
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.875rem center/8px 10px;
	border: 1px solid #e7eaed;
	border-radius: .25rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

.custom-select:focus {
	border-color: #c1eeda;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(93, 211, 158, .25)
}

.custom-select:focus::-ms-value {
	color: #0b103f;
	background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
	height: auto;
	padding-right: 1.875rem;
	background-image: none
}

.custom-select:disabled {
	color: #8c98a4;
	background-color: #f8fafc
}

.custom-select::-ms-expand {
	display: none
}

.custom-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #0b103f
}

.custom-select-sm {
	height: calc(1.5em + 1.4375rem + 2px);
	padding-top: .71875rem;
	padding-bottom: .71875rem;
	padding-left: 1.5rem;
	font-size: 1.125rem
}

.custom-select-lg {
	height: calc(1.5em + 1.9375rem + 2px);
	padding-top: .96875rem;
	padding-bottom: .96875rem;
	padding-left: 2rem;
	font-size: 1.125rem
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

	.custom-control-label::before,
	.custom-file-label,
	.custom-select {
		transition: none
	}
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem
}

.alert-heading {
	color: inherit
}

.alert-link {
	font-weight: 700
}

.alert-dismissible {
	padding-right: 4rem
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: .75rem 1.25rem;
	color: inherit
}

.alert-primary {
	color: #306e52;
	background-color: #dff6ec;
	border-color: #d2f3e4
}

.alert-primary hr {
	border-top-color: #beeed8
}

.alert-primary .alert-link {
	color: #214a38
}

.alert-secondary {
	color: #2a2d31;
	background-color: #dcdddf;
	border-color: #ced0d2
}

.alert-secondary hr {
	border-top-color: #c1c3c6
}

.alert-secondary .alert-link {
	color: #121416
}

.alert-success {
	color: #184521;
	background-color: #d5e6d9;
	border-color: #c4ddc9
}

.alert-success hr {
	border-top-color: #b4d4ba
}

.alert-success .alert-link {
	color: #0b1f0f
}

.alert-info {
	color: #1e2b5b;
	background-color: #d7dcef;
	border-color: #c8cfe9
}

.alert-info hr {
	border-top-color: #b6bfe2
}

.alert-info .alert-link {
	color: #111935
}

.alert-warning {
	color: #857326;
	background-color: #fff8db;
	border-color: #fff5cc
}

.alert-warning hr {
	border-top-color: #fff0b3
}

.alert-warning .alert-link {
	color: #5d511b
}

.alert-danger {
	color: #7e3636;
	background-color: #fce1e1;
	border-color: #fbd5d5
}

.alert-danger hr {
	border-top-color: #f9bebe
}

.alert-danger .alert-link {
	color: #5a2727
}

.alert-light {
	color: #858585;
	background-color: #fff;
	border-color: #fff
}

.alert-light hr {
	border-top-color: #f2f2f2
}

.alert-light .alert-link {
	color: #6c6c6c
}

.alert-dark {
	color: #181a26;
	background-color: #d5d6db;
	border-color: #c5c6cc
}

.alert-dark hr {
	border-top-color: #b7b9c0
}

.alert-dark .alert-link {
	color: #040507
}

.alert-cheddar {
	color: #796115;
	background-color: #faf1d4;
	border-color: #f9ecc3
}

.alert-cheddar hr {
	border-top-color: #f7e5ac
}

.alert-cheddar .alert-link {
	color: #4e3e0d
}

.alert-whales {
	color: #1f3076;
	background-color: #d8dff9;
	border-color: #c8d2f7
}

.alert-whales hr {
	border-top-color: #b2c0f4
}

.alert-whales .alert-link {
	color: #14204e
}

.alert-super {
	color: #6a6a6a;
	background-color: #f5f5f5;
	border-color: #f1f1f1
}

.alert-super hr {
	border-top-color: #e4e4e4
}

.alert-super .alert-link {
	color: #515151
}

@-webkit-keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: .25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: .2em
}

@-webkit-keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}

	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

@keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}

	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: spinner-grow .75s linear infinite;
	animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem
}

.align-baseline {
	vertical-align: baseline !important
}

.align-top {
	vertical-align: top !important
}

.align-middle {
	vertical-align: middle !important
}

.align-bottom {
	vertical-align: bottom !important
}

.align-text-bottom {
	vertical-align: text-bottom !important
}

.align-text-top {
	vertical-align: text-top !important
}

.bg-primary {
	background-color: #5dd39e !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #36c786 !important
}

.bg-secondary {
	background-color: #50575e !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
	background-color: #393e42 !important
}

.bg-success {
	background-color: #2e843f !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
	background-color: #215e2d !important
}

.bg-info {
	background-color: #3952af !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
	background-color: #2c4089 !important
}

.bg-warning {
	background-color: #ffdd4a !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
	background-color: #ffd317 !important
}

.bg-danger {
	background-color: #f26868 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
	background-color: #ee3939 !important
}

.bg-light {
	background-color: #fff !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
	background-color: #e6e6e6 !important
}

.bg-dark {
	background-color: #2f3249 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
	background-color: #1b1d2a !important
}

.bg-cheddar {
	background-color: #e8bb28 !important
}

a.bg-cheddar:focus,
a.bg-cheddar:hover,
button.bg-cheddar:focus,
button.bg-cheddar:hover {
	background-color: #c89e15 !important
}

.bg-whales {
	background-color: #3c5de2 !important
}

a.bg-whales:focus,
a.bg-whales:hover,
button.bg-whales:focus,
button.bg-whales:hover {
	background-color: #1e41cd !important
}

.bg-super {
	background-color: #ccc !important
}

a.bg-super:focus,
a.bg-super:hover,
button.bg-super:focus,
button.bg-super:hover {
	background-color: #b3b3b3 !important
}

.bg-white {
	background-color: #fff !important
}

.bg-transparent {
	background-color: transparent !important
}

.border {
	border: 1px solid #eff4f9 !important
}

.border-top {
	border-top: 1px solid #eff4f9 !important
}

.border-right {
	border-right: 1px solid #eff4f9 !important
}

.border-bottom {
	border-bottom: 1px solid #eff4f9 !important
}

.border-left {
	border-left: 1px solid #eff4f9 !important
}

.border-0 {
	border: 0 !important
}

.border-top-0 {
	border-top: 0 !important
}

.border-right-0 {
	border-right: 0 !important
}

.border-bottom-0 {
	border-bottom: 0 !important
}

.border-left-0 {
	border-left: 0 !important
}

.border-primary {
	border-color: #5dd39e !important
}

.border-secondary {
	border-color: #50575e !important
}

.border-success {
	border-color: #2e843f !important
}

.border-info {
	border-color: #3952af !important
}

.border-warning {
	border-color: #ffdd4a !important
}

.border-danger {
	border-color: #f26868 !important
}

.border-light {
	border-color: #fff !important
}

.border-dark {
	border-color: #2f3249 !important
}

.border-cheddar {
	border-color: #e8bb28 !important
}

.border-whales {
	border-color: #3c5de2 !important
}

.border-super {
	border-color: #ccc !important
}

.border-white {
	border-color: #fff !important
}

.rounded-sm {
	border-radius: .15rem !important
}

.rounded {
	border-radius: .25rem !important
}

.rounded-top {
	border-top-left-radius: .25rem !important;
	border-top-right-radius: .25rem !important
}

.rounded-right {
	border-top-right-radius: .25rem !important;
	border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
	border-bottom-right-radius: .25rem !important;
	border-bottom-left-radius: .25rem !important
}

.rounded-left {
	border-top-left-radius: .25rem !important;
	border-bottom-left-radius: .25rem !important
}

.rounded-lg {
	border-radius: .75rem !important
}

.rounded-circle {
	border-radius: 50% !important
}

.rounded-pill {
	border-radius: 50rem !important
}

.rounded-0 {
	border-radius: 0 !important
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.d-none {
	display: none !important
}

.d-inline {
	display: inline !important
}

.d-inline-block {
	display: inline-block !important
}

.d-block {
	display: block !important
}

.d-table {
	display: table !important
}

.d-table-row {
	display: table-row !important
}

.d-table-cell {
	display: table-cell !important
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important
}

.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important
}

@media (min-width:576px) {
	.d-sm-none {
		display: none !important
	}

	.d-sm-inline {
		display: inline !important
	}

	.d-sm-inline-block {
		display: inline-block !important
	}

	.d-sm-block {
		display: block !important
	}

	.d-sm-table {
		display: table !important
	}

	.d-sm-table-row {
		display: table-row !important
	}

	.d-sm-table-cell {
		display: table-cell !important
	}

	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

@media (min-width:768px) {
	.d-md-none {
		display: none !important
	}

	.d-md-inline {
		display: inline !important
	}

	.d-md-inline-block {
		display: inline-block !important
	}

	.d-md-block {
		display: block !important
	}

	.d-md-table {
		display: table !important
	}

	.d-md-table-row {
		display: table-row !important
	}

	.d-md-table-cell {
		display: table-cell !important
	}

	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

@media (min-width:992px) {
	.d-lg-none {
		display: none !important
	}

	.d-lg-inline {
		display: inline !important
	}

	.d-lg-inline-block {
		display: inline-block !important
	}

	.d-lg-block {
		display: block !important
	}

	.d-lg-table {
		display: table !important
	}

	.d-lg-table-row {
		display: table-row !important
	}

	.d-lg-table-cell {
		display: table-cell !important
	}

	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

@media (min-width:1200px) {
	.d-xl-none {
		display: none !important
	}

	.d-xl-inline {
		display: inline !important
	}

	.d-xl-inline-block {
		display: inline-block !important
	}

	.d-xl-block {
		display: block !important
	}

	.d-xl-table {
		display: table !important
	}

	.d-xl-table-row {
		display: table-row !important
	}

	.d-xl-table-cell {
		display: table-cell !important
	}

	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

@media (min-width:1480px) {
	.d-xxl-none {
		display: none !important
	}

	.d-xxl-inline {
		display: inline !important
	}

	.d-xxl-inline-block {
		display: inline-block !important
	}

	.d-xxl-block {
		display: block !important
	}

	.d-xxl-table {
		display: table !important
	}

	.d-xxl-table-row {
		display: table-row !important
	}

	.d-xxl-table-cell {
		display: table-cell !important
	}

	.d-xxl-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-xxl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

@media print {
	.d-print-none {
		display: none !important
	}

	.d-print-inline {
		display: inline !important
	}

	.d-print-inline-block {
		display: inline-block !important
	}

	.d-print-block {
		display: block !important
	}

	.d-print-table {
		display: table !important
	}

	.d-print-table-row {
		display: table-row !important
	}

	.d-print-table-cell {
		display: table-cell !important
	}

	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important
	}

	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden
}

.embed-responsive::before {
	display: block;
	content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0
}

.embed-responsive-21by9::before {
	padding-top: 42.8571428571%
}

.embed-responsive-16by9::before {
	padding-top: 56.25%
}

.embed-responsive-4by3::before {
	padding-top: 75%
}

.embed-responsive-1by1::before {
	padding-top: 100%
}

.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important
}

.event-banner-content,
.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important
}

.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important
}

.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important
}

.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important
}

.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important
}

.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important
}

.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important
}

.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important
}

.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important
}

.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important
}

.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important
}

.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important
}

.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important
}

.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important
}

.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important
}

.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important
}

.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important
}

.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important
}

.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important
}

.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important
}

.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important
}

.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important
}

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important
}

.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important
}

.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important
}

@media (min-width:576px) {
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important
	}

	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important
	}

	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important
	}

	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important
	}

	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important
	}

	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important
	}

	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important
	}

	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important
	}

	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important
	}

	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important
	}

	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important
	}

	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important
	}

	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}

	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}

	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important
	}

	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important
	}

	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important
	}

	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important
	}

	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important
	}

	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important
	}

	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important
	}

	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important
	}

	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important
	}

	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important
	}

	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important
	}

	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important
	}

	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important
	}

	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important
	}

	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important
	}

	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important
	}

	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important
	}

	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important
	}

	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important
	}
}

@media (min-width:768px) {
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important
	}

	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important
	}

	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important
	}

	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important
	}

	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important
	}

	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important
	}

	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important
	}

	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important
	}

	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important
	}

	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important
	}

	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important
	}

	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important
	}

	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}

	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}

	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important
	}

	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important
	}

	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important
	}

	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important
	}

	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important
	}

	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important
	}

	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important
	}

	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important
	}

	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important
	}

	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important
	}

	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important
	}

	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important
	}

	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important
	}

	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important
	}

	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important
	}

	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important
	}

	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important
	}

	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important
	}

	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important
	}
}

@media (min-width:992px) {
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important
	}

	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important
	}

	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important
	}

	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important
	}

	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important
	}

	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important
	}

	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important
	}

	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important
	}

	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important
	}

	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important
	}

	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important
	}

	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important
	}

	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}

	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}

	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important
	}

	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important
	}

	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important
	}

	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important
	}

	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important
	}

	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important
	}

	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important
	}

	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important
	}

	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important
	}

	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important
	}

	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important
	}

	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important
	}

	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important
	}

	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important
	}

	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important
	}

	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important
	}

	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important
	}

	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important
	}

	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important
	}
}

@media (min-width:1200px) {
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important
	}

	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important
	}

	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important
	}

	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important
	}

	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important
	}

	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important
	}

	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important
	}

	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important
	}

	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important
	}

	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important
	}

	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important
	}

	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important
	}

	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}

	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}

	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important
	}

	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important
	}

	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important
	}

	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important
	}

	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important
	}

	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important
	}

	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important
	}

	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important
	}

	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important
	}

	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important
	}

	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important
	}

	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important
	}

	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important
	}

	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important
	}

	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important
	}

	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important
	}

	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important
	}

	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important
	}

	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important
	}
}

@media (min-width:1480px) {
	.flex-xxl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important
	}

	.flex-xxl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important
	}

	.flex-xxl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important
	}

	.flex-xxl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important
	}

	.flex-xxl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important
	}

	.flex-xxl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important
	}

	.flex-xxl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important
	}

	.flex-xxl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important
	}

	.flex-xxl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important
	}

	.flex-xxl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important
	}

	.flex-xxl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important
	}

	.flex-xxl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important
	}

	.justify-content-xxl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}

	.justify-content-xxl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.justify-content-xxl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}

	.justify-content-xxl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important
	}

	.justify-content-xxl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important
	}

	.align-items-xxl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important
	}

	.align-items-xxl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important
	}

	.align-items-xxl-center {
		-ms-flex-align: center !important;
		align-items: center !important
	}

	.align-items-xxl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important
	}

	.align-items-xxl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important
	}

	.align-content-xxl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important
	}

	.align-content-xxl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important
	}

	.align-content-xxl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important
	}

	.align-content-xxl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important
	}

	.align-content-xxl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important
	}

	.align-content-xxl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important
	}

	.align-self-xxl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important
	}

	.align-self-xxl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important
	}

	.align-self-xxl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important
	}

	.align-self-xxl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important
	}

	.align-self-xxl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important
	}

	.align-self-xxl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important
	}
}

.overflow-auto {
	overflow: auto !important
}

.overflow-hidden {
	overflow: hidden !important
}

.position-static {
	position: static !important
}

.position-relative {
	position: relative !important
}

.position-absolute {
	position: absolute !important
}

.position-fixed {
	position: fixed !important
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal
}

.shadow-sm {
	box-shadow: 0 0 46px rgba(0, 0, 0, .08) !important
}

.shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
	box-shadow: none !important
}

.w-25 {
	width: 25% !important
}

.w-50 {
	width: 50% !important
}

.w-75 {
	width: 75% !important
}

.w-100 {
	width: 100% !important
}

.w-auto {
	width: auto !important
}

.h-25 {
	height: 25% !important
}

.h-50 {
	height: 50% !important
}

.h-75 {
	height: 75% !important
}

.h-100 {
	height: 100% !important
}

.h-auto {
	height: auto !important
}

.mw-100 {
	max-width: 100% !important
}

.mh-100 {
	max-height: 100% !important
}

.min-vw-100 {
	min-width: 100vw !important
}

.min-vh-100 {
	min-height: 100vh !important
}

.vw-100 {
	width: 100vw !important
}

.vh-100 {
	height: 100vh !important
}

.m-0 {
	margin: 0 !important
}

.mt-0,
.my-0 {
	margin-top: 0 !important
}

.mr-0,
.mx-0 {
	margin-right: 0 !important
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
	margin-left: 0 !important
}

.m-1 {
	margin: .25rem !important
}

.mt-1,
.my-1 {
	margin-top: .25rem !important
}

.mr-1,
.mx-1 {
	margin-right: .25rem !important
}

.mb-1,
.my-1 {
	margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
	margin-left: .25rem !important
}

.m-2 {
	margin: .5rem !important
}

.mt-2,
.my-2 {
	margin-top: .5rem !important
}

.mr-2,
.mx-2 {
	margin-right: .5rem !important
}

.mb-2,
.my-2 {
	margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
	margin-left: .5rem !important
}

.m-3 {
	margin: 1rem !important
}

.mt-3,
.my-3 {
	margin-top: 1rem !important
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important
}

.m-4 {
	margin: 1.5rem !important
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important
}

.m-5 {
	margin: 2rem !important
}

.mt-5,
.my-5 {
	margin-top: 2rem !important
}

.mr-5,
.mx-5 {
	margin-right: 2rem !important
}

.mb-5,
.my-5 {
	margin-bottom: 2rem !important
}

.ml-5,
.mx-5 {
	margin-left: 2rem !important
}

.m-6 {
	margin: 3rem !important
}

.mt-6,
.my-6 {
	margin-top: 3rem !important
}

.mr-6,
.mx-6 {
	margin-right: 3rem !important
}

.mb-6,
.my-6 {
	margin-bottom: 3rem !important
}

.ml-6,
.mx-6 {
	margin-left: 3rem !important
}

.m-7 {
	margin: 4.5rem !important
}

.mt-7,
.my-7 {
	margin-top: 4.5rem !important
}

.mr-7,
.mx-7 {
	margin-right: 4.5rem !important
}

.mb-7,
.my-7 {
	margin-bottom: 4.5rem !important
}

.ml-7,
.mx-7 {
	margin-left: 4.5rem !important
}

.m-8 {
	margin: 6rem !important
}

.mt-8,
.my-8 {
	margin-top: 6rem !important
}

.mr-8,
.mx-8 {
	margin-right: 6rem !important
}

.mb-8,
.my-8 {
	margin-bottom: 6rem !important
}

.ml-8,
.mx-8 {
	margin-left: 6rem !important
}

.m-9 {
	margin: 8rem !important
}

.mt-9,
.my-9 {
	margin-top: 8rem !important
}

.mr-9,
.mx-9 {
	margin-right: 8rem !important
}

.mb-9,
.my-9 {
	margin-bottom: 8rem !important
}

.ml-9,
.mx-9 {
	margin-left: 8rem !important
}

.p-0 {
	padding: 0 !important
}

.pt-0,
.py-0 {
	padding-top: 0 !important
}

.pr-0,
.px-0 {
	padding-right: 0 !important
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important
}

.pl-0,
.px-0 {
	padding-left: 0 !important
}

.p-1 {
	padding: .25rem !important
}

.pt-1,
.py-1 {
	padding-top: .25rem !important
}

.pr-1,
.px-1 {
	padding-right: .25rem !important
}

.pb-1,
.py-1 {
	padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
	padding-left: .25rem !important
}

.p-2 {
	padding: .5rem !important
}

.pt-2,
.py-2 {
	padding-top: .5rem !important
}

.pr-2,
.px-2 {
	padding-right: .5rem !important
}

.pb-2,
.py-2 {
	padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
	padding-left: .5rem !important
}

.p-3 {
	padding: 1rem !important
}

.pt-3,
.py-3 {
	padding-top: 1rem !important
}

.pr-3,
.px-3 {
	padding-right: 1rem !important
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
	padding-left: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important
}

.p-5 {
	padding: 2rem !important
}

.pt-5,
.py-5 {
	padding-top: 2rem !important
}

.pr-5,
.px-5 {
	padding-right: 2rem !important
}

.pb-5,
.py-5 {
	padding-bottom: 2rem !important
}

.pl-5,
.px-5 {
	padding-left: 2rem !important
}

.p-6 {
	padding: 3rem !important
}

.pt-6,
.py-6 {
	padding-top: 3rem !important
}

.pr-6,
.px-6 {
	padding-right: 3rem !important
}

.pb-6,
.py-6 {
	padding-bottom: 3rem !important
}

.pl-6,
.px-6 {
	padding-left: 3rem !important
}

.p-7 {
	padding: 4.5rem !important
}

.pt-7,
.py-7 {
	padding-top: 4.5rem !important
}

.pr-7,
.px-7 {
	padding-right: 4.5rem !important
}

.pb-7,
.py-7 {
	padding-bottom: 4.5rem !important
}

.pl-7,
.px-7 {
	padding-left: 4.5rem !important
}

.p-8 {
	padding: 6rem !important
}

.pt-8,
.py-8 {
	padding-top: 6rem !important
}

.pr-8,
.px-8 {
	padding-right: 6rem !important
}

.pb-8,
.py-8 {
	padding-bottom: 6rem !important
}

.pl-8,
.px-8 {
	padding-left: 6rem !important
}

.p-9 {
	padding: 8rem !important
}

.pt-9,
.py-9 {
	padding-top: 8rem !important
}

.pr-9,
.px-9 {
	padding-right: 8rem !important
}

.pb-9,
.py-9 {
	padding-bottom: 8rem !important
}

.pl-9,
.px-9 {
	padding-left: 8rem !important
}

.m-n1 {
	margin: -.25rem !important
}

.mt-n1,
.my-n1 {
	margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
	margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
	margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
	margin-left: -.25rem !important
}

.m-n2 {
	margin: -.5rem !important
}

.mt-n2,
.my-n2 {
	margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
	margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
	margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
	margin-left: -.5rem !important
}

.m-n3 {
	margin: -1rem !important
}

.mt-n3,
.my-n3 {
	margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
	margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
	margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
	margin-left: -1rem !important
}

.m-n4 {
	margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
	margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
	margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
	margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
	margin-left: -1.5rem !important
}

.m-n5 {
	margin: -2rem !important
}

.mt-n5,
.my-n5 {
	margin-top: -2rem !important
}

.mr-n5,
.mx-n5 {
	margin-right: -2rem !important
}

.mb-n5,
.my-n5 {
	margin-bottom: -2rem !important
}

.ml-n5,
.mx-n5 {
	margin-left: -2rem !important
}

.m-n6 {
	margin: -3rem !important
}

.mt-n6,
.my-n6 {
	margin-top: -3rem !important
}

.mr-n6,
.mx-n6 {
	margin-right: -3rem !important
}

.mb-n6,
.my-n6 {
	margin-bottom: -3rem !important
}

.ml-n6,
.mx-n6 {
	margin-left: -3rem !important
}

.m-n7 {
	margin: -4.5rem !important
}

.mt-n7,
.my-n7 {
	margin-top: -4.5rem !important
}

.mr-n7,
.mx-n7 {
	margin-right: -4.5rem !important
}

.mb-n7,
.my-n7 {
	margin-bottom: -4.5rem !important
}

.ml-n7,
.mx-n7 {
	margin-left: -4.5rem !important
}

.m-n8 {
	margin: -6rem !important
}

.mt-n8,
.my-n8 {
	margin-top: -6rem !important
}

.mr-n8,
.mx-n8 {
	margin-right: -6rem !important
}

.mb-n8,
.my-n8 {
	margin-bottom: -6rem !important
}

.ml-n8,
.mx-n8 {
	margin-left: -6rem !important
}

.m-n9 {
	margin: -8rem !important
}

.mt-n9,
.my-n9 {
	margin-top: -8rem !important
}

.mr-n9,
.mx-n9 {
	margin-right: -8rem !important
}

.mb-n9,
.my-n9 {
	margin-bottom: -8rem !important
}

.ml-n9,
.mx-n9 {
	margin-left: -8rem !important
}

.m-auto {
	margin: auto !important
}

.mt-auto,
.my-auto {
	margin-top: auto !important
}

.mr-auto,
.mx-auto {
	margin-right: auto !important
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
	margin-left: auto !important
}

@media (min-width:576px) {
	.m-sm-0 {
		margin: 0 !important
	}

	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0 !important
	}

	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0 !important
	}

	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0 !important
	}

	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0 !important
	}

	.m-sm-1 {
		margin: .25rem !important
	}

	.mt-sm-1,
	.my-sm-1 {
		margin-top: .25rem !important
	}

	.mr-sm-1,
	.mx-sm-1 {
		margin-right: .25rem !important
	}

	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: .25rem !important
	}

	.ml-sm-1,
	.mx-sm-1 {
		margin-left: .25rem !important
	}

	.m-sm-2 {
		margin: .5rem !important
	}

	.mt-sm-2,
	.my-sm-2 {
		margin-top: .5rem !important
	}

	.mr-sm-2,
	.mx-sm-2 {
		margin-right: .5rem !important
	}

	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: .5rem !important
	}

	.ml-sm-2,
	.mx-sm-2 {
		margin-left: .5rem !important
	}

	.m-sm-3 {
		margin: 1rem !important
	}

	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem !important
	}

	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem !important
	}

	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem !important
	}

	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem !important
	}

	.m-sm-4 {
		margin: 1.5rem !important
	}

	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem !important
	}

	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem !important
	}

	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem !important
	}

	.m-sm-5 {
		margin: 2rem !important
	}

	.mt-sm-5,
	.my-sm-5 {
		margin-top: 2rem !important
	}

	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 2rem !important
	}

	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 2rem !important
	}

	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 2rem !important
	}

	.m-sm-6 {
		margin: 3rem !important
	}

	.mt-sm-6,
	.my-sm-6 {
		margin-top: 3rem !important
	}

	.mr-sm-6,
	.mx-sm-6 {
		margin-right: 3rem !important
	}

	.mb-sm-6,
	.my-sm-6 {
		margin-bottom: 3rem !important
	}

	.ml-sm-6,
	.mx-sm-6 {
		margin-left: 3rem !important
	}

	.m-sm-7 {
		margin: 4.5rem !important
	}

	.mt-sm-7,
	.my-sm-7 {
		margin-top: 4.5rem !important
	}

	.mr-sm-7,
	.mx-sm-7 {
		margin-right: 4.5rem !important
	}

	.mb-sm-7,
	.my-sm-7 {
		margin-bottom: 4.5rem !important
	}

	.ml-sm-7,
	.mx-sm-7 {
		margin-left: 4.5rem !important
	}

	.m-sm-8 {
		margin: 6rem !important
	}

	.mt-sm-8,
	.my-sm-8 {
		margin-top: 6rem !important
	}

	.mr-sm-8,
	.mx-sm-8 {
		margin-right: 6rem !important
	}

	.mb-sm-8,
	.my-sm-8 {
		margin-bottom: 6rem !important
	}

	.ml-sm-8,
	.mx-sm-8 {
		margin-left: 6rem !important
	}

	.m-sm-9 {
		margin: 8rem !important
	}

	.mt-sm-9,
	.my-sm-9 {
		margin-top: 8rem !important
	}

	.mr-sm-9,
	.mx-sm-9 {
		margin-right: 8rem !important
	}

	.mb-sm-9,
	.my-sm-9 {
		margin-bottom: 8rem !important
	}

	.ml-sm-9,
	.mx-sm-9 {
		margin-left: 8rem !important
	}

	.p-sm-0 {
		padding: 0 !important
	}

	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0 !important
	}

	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0 !important
	}

	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0 !important
	}

	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0 !important
	}

	.p-sm-1 {
		padding: .25rem !important
	}

	.pt-sm-1,
	.py-sm-1 {
		padding-top: .25rem !important
	}

	.pr-sm-1,
	.px-sm-1 {
		padding-right: .25rem !important
	}

	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: .25rem !important
	}

	.pl-sm-1,
	.px-sm-1 {
		padding-left: .25rem !important
	}

	.p-sm-2 {
		padding: .5rem !important
	}

	.pt-sm-2,
	.py-sm-2 {
		padding-top: .5rem !important
	}

	.pr-sm-2,
	.px-sm-2 {
		padding-right: .5rem !important
	}

	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: .5rem !important
	}

	.pl-sm-2,
	.px-sm-2 {
		padding-left: .5rem !important
	}

	.p-sm-3 {
		padding: 1rem !important
	}

	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem !important
	}

	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem !important
	}

	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem !important
	}

	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem !important
	}

	.p-sm-4 {
		padding: 1.5rem !important
	}

	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem !important
	}

	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem !important
	}

	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem !important
	}

	.p-sm-5 {
		padding: 2rem !important
	}

	.pt-sm-5,
	.py-sm-5 {
		padding-top: 2rem !important
	}

	.pr-sm-5,
	.px-sm-5 {
		padding-right: 2rem !important
	}

	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 2rem !important
	}

	.pl-sm-5,
	.px-sm-5 {
		padding-left: 2rem !important
	}

	.p-sm-6 {
		padding: 3rem !important
	}

	.pt-sm-6,
	.py-sm-6 {
		padding-top: 3rem !important
	}

	.pr-sm-6,
	.px-sm-6 {
		padding-right: 3rem !important
	}

	.pb-sm-6,
	.py-sm-6 {
		padding-bottom: 3rem !important
	}

	.pl-sm-6,
	.px-sm-6 {
		padding-left: 3rem !important
	}

	.p-sm-7 {
		padding: 4.5rem !important
	}

	.pt-sm-7,
	.py-sm-7 {
		padding-top: 4.5rem !important
	}

	.pr-sm-7,
	.px-sm-7 {
		padding-right: 4.5rem !important
	}

	.pb-sm-7,
	.py-sm-7 {
		padding-bottom: 4.5rem !important
	}

	.pl-sm-7,
	.px-sm-7 {
		padding-left: 4.5rem !important
	}

	.p-sm-8 {
		padding: 6rem !important
	}

	.pt-sm-8,
	.py-sm-8 {
		padding-top: 6rem !important
	}

	.pr-sm-8,
	.px-sm-8 {
		padding-right: 6rem !important
	}

	.pb-sm-8,
	.py-sm-8 {
		padding-bottom: 6rem !important
	}

	.pl-sm-8,
	.px-sm-8 {
		padding-left: 6rem !important
	}

	.p-sm-9 {
		padding: 8rem !important
	}

	.pt-sm-9,
	.py-sm-9 {
		padding-top: 8rem !important
	}

	.pr-sm-9,
	.px-sm-9 {
		padding-right: 8rem !important
	}

	.pb-sm-9,
	.py-sm-9 {
		padding-bottom: 8rem !important
	}

	.pl-sm-9,
	.px-sm-9 {
		padding-left: 8rem !important
	}

	.m-sm-n1 {
		margin: -.25rem !important
	}

	.mt-sm-n1,
	.my-sm-n1 {
		margin-top: -.25rem !important
	}

	.mr-sm-n1,
	.mx-sm-n1 {
		margin-right: -.25rem !important
	}

	.mb-sm-n1,
	.my-sm-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-sm-n1,
	.mx-sm-n1 {
		margin-left: -.25rem !important
	}

	.m-sm-n2 {
		margin: -.5rem !important
	}

	.mt-sm-n2,
	.my-sm-n2 {
		margin-top: -.5rem !important
	}

	.mr-sm-n2,
	.mx-sm-n2 {
		margin-right: -.5rem !important
	}

	.mb-sm-n2,
	.my-sm-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-sm-n2,
	.mx-sm-n2 {
		margin-left: -.5rem !important
	}

	.m-sm-n3 {
		margin: -1rem !important
	}

	.mt-sm-n3,
	.my-sm-n3 {
		margin-top: -1rem !important
	}

	.mr-sm-n3,
	.mx-sm-n3 {
		margin-right: -1rem !important
	}

	.mb-sm-n3,
	.my-sm-n3 {
		margin-bottom: -1rem !important
	}

	.ml-sm-n3,
	.mx-sm-n3 {
		margin-left: -1rem !important
	}

	.m-sm-n4 {
		margin: -1.5rem !important
	}

	.mt-sm-n4,
	.my-sm-n4 {
		margin-top: -1.5rem !important
	}

	.mr-sm-n4,
	.mx-sm-n4 {
		margin-right: -1.5rem !important
	}

	.mb-sm-n4,
	.my-sm-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-sm-n4,
	.mx-sm-n4 {
		margin-left: -1.5rem !important
	}

	.m-sm-n5 {
		margin: -2rem !important
	}

	.mt-sm-n5,
	.my-sm-n5 {
		margin-top: -2rem !important
	}

	.mr-sm-n5,
	.mx-sm-n5 {
		margin-right: -2rem !important
	}

	.mb-sm-n5,
	.my-sm-n5 {
		margin-bottom: -2rem !important
	}

	.ml-sm-n5,
	.mx-sm-n5 {
		margin-left: -2rem !important
	}

	.m-sm-n6 {
		margin: -3rem !important
	}

	.mt-sm-n6,
	.my-sm-n6 {
		margin-top: -3rem !important
	}

	.mr-sm-n6,
	.mx-sm-n6 {
		margin-right: -3rem !important
	}

	.mb-sm-n6,
	.my-sm-n6 {
		margin-bottom: -3rem !important
	}

	.ml-sm-n6,
	.mx-sm-n6 {
		margin-left: -3rem !important
	}

	.m-sm-n7 {
		margin: -4.5rem !important
	}

	.mt-sm-n7,
	.my-sm-n7 {
		margin-top: -4.5rem !important
	}

	.mr-sm-n7,
	.mx-sm-n7 {
		margin-right: -4.5rem !important
	}

	.mb-sm-n7,
	.my-sm-n7 {
		margin-bottom: -4.5rem !important
	}

	.ml-sm-n7,
	.mx-sm-n7 {
		margin-left: -4.5rem !important
	}

	.m-sm-n8 {
		margin: -6rem !important
	}

	.mt-sm-n8,
	.my-sm-n8 {
		margin-top: -6rem !important
	}

	.mr-sm-n8,
	.mx-sm-n8 {
		margin-right: -6rem !important
	}

	.mb-sm-n8,
	.my-sm-n8 {
		margin-bottom: -6rem !important
	}

	.ml-sm-n8,
	.mx-sm-n8 {
		margin-left: -6rem !important
	}

	.m-sm-n9 {
		margin: -8rem !important
	}

	.mt-sm-n9,
	.my-sm-n9 {
		margin-top: -8rem !important
	}

	.mr-sm-n9,
	.mx-sm-n9 {
		margin-right: -8rem !important
	}

	.mb-sm-n9,
	.my-sm-n9 {
		margin-bottom: -8rem !important
	}

	.ml-sm-n9,
	.mx-sm-n9 {
		margin-left: -8rem !important
	}

	.m-sm-auto {
		margin: auto !important
	}

	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto !important
	}

	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto !important
	}

	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto !important
	}

	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto !important
	}
}

@media (min-width:768px) {
	.m-md-0 {
		margin: 0 !important
	}

	.mt-md-0,
	.my-md-0 {
		margin-top: 0 !important
	}

	.mr-md-0,
	.mx-md-0 {
		margin-right: 0 !important
	}

	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0 !important
	}

	.ml-md-0,
	.mx-md-0 {
		margin-left: 0 !important
	}

	.m-md-1 {
		margin: .25rem !important
	}

	.mt-md-1,
	.my-md-1 {
		margin-top: .25rem !important
	}

	.mr-md-1,
	.mx-md-1 {
		margin-right: .25rem !important
	}

	.mb-md-1,
	.my-md-1 {
		margin-bottom: .25rem !important
	}

	.ml-md-1,
	.mx-md-1 {
		margin-left: .25rem !important
	}

	.m-md-2 {
		margin: .5rem !important
	}

	.mt-md-2,
	.my-md-2 {
		margin-top: .5rem !important
	}

	.mr-md-2,
	.mx-md-2 {
		margin-right: .5rem !important
	}

	.mb-md-2,
	.my-md-2 {
		margin-bottom: .5rem !important
	}

	.ml-md-2,
	.mx-md-2 {
		margin-left: .5rem !important
	}

	.m-md-3 {
		margin: 1rem !important
	}

	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem !important
	}

	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem !important
	}

	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem !important
	}

	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem !important
	}

	.m-md-4 {
		margin: 1.5rem !important
	}

	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem !important
	}

	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem !important
	}

	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem !important
	}

	.m-md-5 {
		margin: 2rem !important
	}

	.mt-md-5,
	.my-md-5 {
		margin-top: 2rem !important
	}

	.mr-md-5,
	.mx-md-5 {
		margin-right: 2rem !important
	}

	.mb-md-5,
	.my-md-5 {
		margin-bottom: 2rem !important
	}

	.ml-md-5,
	.mx-md-5 {
		margin-left: 2rem !important
	}

	.m-md-6 {
		margin: 3rem !important
	}

	.mt-md-6,
	.my-md-6 {
		margin-top: 3rem !important
	}

	.mr-md-6,
	.mx-md-6 {
		margin-right: 3rem !important
	}

	.mb-md-6,
	.my-md-6 {
		margin-bottom: 3rem !important
	}

	.ml-md-6,
	.mx-md-6 {
		margin-left: 3rem !important
	}

	.m-md-7 {
		margin: 4.5rem !important
	}

	.mt-md-7,
	.my-md-7 {
		margin-top: 4.5rem !important
	}

	.mr-md-7,
	.mx-md-7 {
		margin-right: 4.5rem !important
	}

	.mb-md-7,
	.my-md-7 {
		margin-bottom: 4.5rem !important
	}

	.ml-md-7,
	.mx-md-7 {
		margin-left: 4.5rem !important
	}

	.m-md-8 {
		margin: 6rem !important
	}

	.mt-md-8,
	.my-md-8 {
		margin-top: 6rem !important
	}

	.mr-md-8,
	.mx-md-8 {
		margin-right: 6rem !important
	}

	.mb-md-8,
	.my-md-8 {
		margin-bottom: 6rem !important
	}

	.ml-md-8,
	.mx-md-8 {
		margin-left: 6rem !important
	}

	.m-md-9 {
		margin: 8rem !important
	}

	.mt-md-9,
	.my-md-9 {
		margin-top: 8rem !important
	}

	.mr-md-9,
	.mx-md-9 {
		margin-right: 8rem !important
	}

	.mb-md-9,
	.my-md-9 {
		margin-bottom: 8rem !important
	}

	.ml-md-9,
	.mx-md-9 {
		margin-left: 8rem !important
	}

	.p-md-0 {
		padding: 0 !important
	}

	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important
	}

	.pr-md-0,
	.px-md-0 {
		padding-right: 0 !important
	}

	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0 !important
	}

	.pl-md-0,
	.px-md-0 {
		padding-left: 0 !important
	}

	.p-md-1 {
		padding: .25rem !important
	}

	.pt-md-1,
	.py-md-1 {
		padding-top: .25rem !important
	}

	.pr-md-1,
	.px-md-1 {
		padding-right: .25rem !important
	}

	.pb-md-1,
	.py-md-1 {
		padding-bottom: .25rem !important
	}

	.pl-md-1,
	.px-md-1 {
		padding-left: .25rem !important
	}

	.p-md-2 {
		padding: .5rem !important
	}

	.pt-md-2,
	.py-md-2 {
		padding-top: .5rem !important
	}

	.pr-md-2,
	.px-md-2 {
		padding-right: .5rem !important
	}

	.pb-md-2,
	.py-md-2 {
		padding-bottom: .5rem !important
	}

	.pl-md-2,
	.px-md-2 {
		padding-left: .5rem !important
	}

	.p-md-3 {
		padding: 1rem !important
	}

	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem !important
	}

	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem !important
	}

	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem !important
	}

	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem !important
	}

	.p-md-4 {
		padding: 1.5rem !important
	}

	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem !important
	}

	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important
	}

	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem !important
	}

	.p-md-5 {
		padding: 2rem !important
	}

	.pt-md-5,
	.py-md-5 {
		padding-top: 2rem !important
	}

	.pr-md-5,
	.px-md-5 {
		padding-right: 2rem !important
	}

	.pb-md-5,
	.py-md-5 {
		padding-bottom: 2rem !important
	}

	.pl-md-5,
	.px-md-5 {
		padding-left: 2rem !important
	}

	.p-md-6 {
		padding: 3rem !important
	}

	.pt-md-6,
	.py-md-6 {
		padding-top: 3rem !important
	}

	.pr-md-6,
	.px-md-6 {
		padding-right: 3rem !important
	}

	.pb-md-6,
	.py-md-6 {
		padding-bottom: 3rem !important
	}

	.pl-md-6,
	.px-md-6 {
		padding-left: 3rem !important
	}

	.p-md-7 {
		padding: 4.5rem !important
	}

	.pt-md-7,
	.py-md-7 {
		padding-top: 4.5rem !important
	}

	.pr-md-7,
	.px-md-7 {
		padding-right: 4.5rem !important
	}

	.pb-md-7,
	.py-md-7 {
		padding-bottom: 4.5rem !important
	}

	.pl-md-7,
	.px-md-7 {
		padding-left: 4.5rem !important
	}

	.p-md-8 {
		padding: 6rem !important
	}

	.pt-md-8,
	.py-md-8 {
		padding-top: 6rem !important
	}

	.pr-md-8,
	.px-md-8 {
		padding-right: 6rem !important
	}

	.pb-md-8,
	.py-md-8 {
		padding-bottom: 6rem !important
	}

	.pl-md-8,
	.px-md-8 {
		padding-left: 6rem !important
	}

	.p-md-9 {
		padding: 8rem !important
	}

	.pt-md-9,
	.py-md-9 {
		padding-top: 8rem !important
	}

	.pr-md-9,
	.px-md-9 {
		padding-right: 8rem !important
	}

	.pb-md-9,
	.py-md-9 {
		padding-bottom: 8rem !important
	}

	.pl-md-9,
	.px-md-9 {
		padding-left: 8rem !important
	}

	.m-md-n1 {
		margin: -.25rem !important
	}

	.mt-md-n1,
	.my-md-n1 {
		margin-top: -.25rem !important
	}

	.mr-md-n1,
	.mx-md-n1 {
		margin-right: -.25rem !important
	}

	.mb-md-n1,
	.my-md-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-md-n1,
	.mx-md-n1 {
		margin-left: -.25rem !important
	}

	.m-md-n2 {
		margin: -.5rem !important
	}

	.mt-md-n2,
	.my-md-n2 {
		margin-top: -.5rem !important
	}

	.mr-md-n2,
	.mx-md-n2 {
		margin-right: -.5rem !important
	}

	.mb-md-n2,
	.my-md-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-md-n2,
	.mx-md-n2 {
		margin-left: -.5rem !important
	}

	.m-md-n3 {
		margin: -1rem !important
	}

	.mt-md-n3,
	.my-md-n3 {
		margin-top: -1rem !important
	}

	.mr-md-n3,
	.mx-md-n3 {
		margin-right: -1rem !important
	}

	.mb-md-n3,
	.my-md-n3 {
		margin-bottom: -1rem !important
	}

	.ml-md-n3,
	.mx-md-n3 {
		margin-left: -1rem !important
	}

	.m-md-n4 {
		margin: -1.5rem !important
	}

	.mt-md-n4,
	.my-md-n4 {
		margin-top: -1.5rem !important
	}

	.mr-md-n4,
	.mx-md-n4 {
		margin-right: -1.5rem !important
	}

	.mb-md-n4,
	.my-md-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-md-n4,
	.mx-md-n4 {
		margin-left: -1.5rem !important
	}

	.m-md-n5 {
		margin: -2rem !important
	}

	.mt-md-n5,
	.my-md-n5 {
		margin-top: -2rem !important
	}

	.mr-md-n5,
	.mx-md-n5 {
		margin-right: -2rem !important
	}

	.mb-md-n5,
	.my-md-n5 {
		margin-bottom: -2rem !important
	}

	.ml-md-n5,
	.mx-md-n5 {
		margin-left: -2rem !important
	}

	.m-md-n6 {
		margin: -3rem !important
	}

	.mt-md-n6,
	.my-md-n6 {
		margin-top: -3rem !important
	}

	.mr-md-n6,
	.mx-md-n6 {
		margin-right: -3rem !important
	}

	.mb-md-n6,
	.my-md-n6 {
		margin-bottom: -3rem !important
	}

	.ml-md-n6,
	.mx-md-n6 {
		margin-left: -3rem !important
	}

	.m-md-n7 {
		margin: -4.5rem !important
	}

	.mt-md-n7,
	.my-md-n7 {
		margin-top: -4.5rem !important
	}

	.mr-md-n7,
	.mx-md-n7 {
		margin-right: -4.5rem !important
	}

	.mb-md-n7,
	.my-md-n7 {
		margin-bottom: -4.5rem !important
	}

	.ml-md-n7,
	.mx-md-n7 {
		margin-left: -4.5rem !important
	}

	.m-md-n8 {
		margin: -6rem !important
	}

	.mt-md-n8,
	.my-md-n8 {
		margin-top: -6rem !important
	}

	.mr-md-n8,
	.mx-md-n8 {
		margin-right: -6rem !important
	}

	.mb-md-n8,
	.my-md-n8 {
		margin-bottom: -6rem !important
	}

	.ml-md-n8,
	.mx-md-n8 {
		margin-left: -6rem !important
	}

	.m-md-n9 {
		margin: -8rem !important
	}

	.mt-md-n9,
	.my-md-n9 {
		margin-top: -8rem !important
	}

	.mr-md-n9,
	.mx-md-n9 {
		margin-right: -8rem !important
	}

	.mb-md-n9,
	.my-md-n9 {
		margin-bottom: -8rem !important
	}

	.ml-md-n9,
	.mx-md-n9 {
		margin-left: -8rem !important
	}

	.m-md-auto {
		margin: auto !important
	}

	.mt-md-auto,
	.my-md-auto {
		margin-top: auto !important
	}

	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto !important
	}

	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto !important
	}

	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto !important
	}
}

@media (min-width:992px) {
	.m-lg-0 {
		margin: 0 !important
	}

	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0 !important
	}

	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0 !important
	}

	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0 !important
	}

	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0 !important
	}

	.m-lg-1 {
		margin: .25rem !important
	}

	.mt-lg-1,
	.my-lg-1 {
		margin-top: .25rem !important
	}

	.mr-lg-1,
	.mx-lg-1 {
		margin-right: .25rem !important
	}

	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: .25rem !important
	}

	.ml-lg-1,
	.mx-lg-1 {
		margin-left: .25rem !important
	}

	.m-lg-2 {
		margin: .5rem !important
	}

	.mt-lg-2,
	.my-lg-2 {
		margin-top: .5rem !important
	}

	.mr-lg-2,
	.mx-lg-2 {
		margin-right: .5rem !important
	}

	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: .5rem !important
	}

	.ml-lg-2,
	.mx-lg-2 {
		margin-left: .5rem !important
	}

	.m-lg-3 {
		margin: 1rem !important
	}

	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem !important
	}

	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem !important
	}

	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem !important
	}

	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem !important
	}

	.m-lg-4 {
		margin: 1.5rem !important
	}

	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem !important
	}

	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem !important
	}

	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem !important
	}

	.m-lg-5 {
		margin: 2rem !important
	}

	.mt-lg-5,
	.my-lg-5 {
		margin-top: 2rem !important
	}

	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 2rem !important
	}

	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 2rem !important
	}

	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 2rem !important
	}

	.m-lg-6 {
		margin: 3rem !important
	}

	.mt-lg-6,
	.my-lg-6 {
		margin-top: 3rem !important
	}

	.mr-lg-6,
	.mx-lg-6 {
		margin-right: 3rem !important
	}

	.mb-lg-6,
	.my-lg-6 {
		margin-bottom: 3rem !important
	}

	.ml-lg-6,
	.mx-lg-6 {
		margin-left: 3rem !important
	}

	.m-lg-7 {
		margin: 4.5rem !important
	}

	.mt-lg-7,
	.my-lg-7 {
		margin-top: 4.5rem !important
	}

	.mr-lg-7,
	.mx-lg-7 {
		margin-right: 4.5rem !important
	}

	.mb-lg-7,
	.my-lg-7 {
		margin-bottom: 4.5rem !important
	}

	.ml-lg-7,
	.mx-lg-7 {
		margin-left: 4.5rem !important
	}

	.m-lg-8 {
		margin: 6rem !important
	}

	.mt-lg-8,
	.my-lg-8 {
		margin-top: 6rem !important
	}

	.mr-lg-8,
	.mx-lg-8 {
		margin-right: 6rem !important
	}

	.mb-lg-8,
	.my-lg-8 {
		margin-bottom: 6rem !important
	}

	.ml-lg-8,
	.mx-lg-8 {
		margin-left: 6rem !important
	}

	.m-lg-9 {
		margin: 8rem !important
	}

	.mt-lg-9,
	.my-lg-9 {
		margin-top: 8rem !important
	}

	.mr-lg-9,
	.mx-lg-9 {
		margin-right: 8rem !important
	}

	.mb-lg-9,
	.my-lg-9 {
		margin-bottom: 8rem !important
	}

	.ml-lg-9,
	.mx-lg-9 {
		margin-left: 8rem !important
	}

	.p-lg-0 {
		padding: 0 !important
	}

	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0 !important
	}

	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0 !important
	}

	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0 !important
	}

	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0 !important
	}

	.p-lg-1 {
		padding: .25rem !important
	}

	.pt-lg-1,
	.py-lg-1 {
		padding-top: .25rem !important
	}

	.pr-lg-1,
	.px-lg-1 {
		padding-right: .25rem !important
	}

	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: .25rem !important
	}

	.pl-lg-1,
	.px-lg-1 {
		padding-left: .25rem !important
	}

	.p-lg-2 {
		padding: .5rem !important
	}

	.pt-lg-2,
	.py-lg-2 {
		padding-top: .5rem !important
	}

	.pr-lg-2,
	.px-lg-2 {
		padding-right: .5rem !important
	}

	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: .5rem !important
	}

	.pl-lg-2,
	.px-lg-2 {
		padding-left: .5rem !important
	}

	.p-lg-3 {
		padding: 1rem !important
	}

	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem !important
	}

	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem !important
	}

	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem !important
	}

	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem !important
	}

	.p-lg-4 {
		padding: 1.5rem !important
	}

	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem !important
	}

	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem !important
	}

	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem !important
	}

	.p-lg-5 {
		padding: 2rem !important
	}

	.pt-lg-5,
	.py-lg-5 {
		padding-top: 2rem !important
	}

	.pr-lg-5,
	.px-lg-5 {
		padding-right: 2rem !important
	}

	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 2rem !important
	}

	.pl-lg-5,
	.px-lg-5 {
		padding-left: 2rem !important
	}

	.p-lg-6 {
		padding: 3rem !important
	}

	.pt-lg-6,
	.py-lg-6 {
		padding-top: 3rem !important
	}

	.pr-lg-6,
	.px-lg-6 {
		padding-right: 3rem !important
	}

	.pb-lg-6,
	.py-lg-6 {
		padding-bottom: 3rem !important
	}

	.pl-lg-6,
	.px-lg-6 {
		padding-left: 3rem !important
	}

	.p-lg-7 {
		padding: 4.5rem !important
	}

	.pt-lg-7,
	.py-lg-7 {
		padding-top: 4.5rem !important
	}

	.pr-lg-7,
	.px-lg-7 {
		padding-right: 4.5rem !important
	}

	.pb-lg-7,
	.py-lg-7 {
		padding-bottom: 4.5rem !important
	}

	.pl-lg-7,
	.px-lg-7 {
		padding-left: 4.5rem !important
	}

	.p-lg-8 {
		padding: 6rem !important
	}

	.pt-lg-8,
	.py-lg-8 {
		padding-top: 6rem !important
	}

	.pr-lg-8,
	.px-lg-8 {
		padding-right: 6rem !important
	}

	.pb-lg-8,
	.py-lg-8 {
		padding-bottom: 6rem !important
	}

	.pl-lg-8,
	.px-lg-8 {
		padding-left: 6rem !important
	}

	.p-lg-9 {
		padding: 8rem !important
	}

	.pt-lg-9,
	.py-lg-9 {
		padding-top: 8rem !important
	}

	.pr-lg-9,
	.px-lg-9 {
		padding-right: 8rem !important
	}

	.pb-lg-9,
	.py-lg-9 {
		padding-bottom: 8rem !important
	}

	.pl-lg-9,
	.px-lg-9 {
		padding-left: 8rem !important
	}

	.m-lg-n1 {
		margin: -.25rem !important
	}

	.mt-lg-n1,
	.my-lg-n1 {
		margin-top: -.25rem !important
	}

	.mr-lg-n1,
	.mx-lg-n1 {
		margin-right: -.25rem !important
	}

	.mb-lg-n1,
	.my-lg-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-lg-n1,
	.mx-lg-n1 {
		margin-left: -.25rem !important
	}

	.m-lg-n2 {
		margin: -.5rem !important
	}

	.mt-lg-n2,
	.my-lg-n2 {
		margin-top: -.5rem !important
	}

	.mr-lg-n2,
	.mx-lg-n2 {
		margin-right: -.5rem !important
	}

	.mb-lg-n2,
	.my-lg-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-lg-n2,
	.mx-lg-n2 {
		margin-left: -.5rem !important
	}

	.m-lg-n3 {
		margin: -1rem !important
	}

	.mt-lg-n3,
	.my-lg-n3 {
		margin-top: -1rem !important
	}

	.mr-lg-n3,
	.mx-lg-n3 {
		margin-right: -1rem !important
	}

	.mb-lg-n3,
	.my-lg-n3 {
		margin-bottom: -1rem !important
	}

	.ml-lg-n3,
	.mx-lg-n3 {
		margin-left: -1rem !important
	}

	.m-lg-n4 {
		margin: -1.5rem !important
	}

	.mt-lg-n4,
	.my-lg-n4 {
		margin-top: -1.5rem !important
	}

	.mr-lg-n4,
	.mx-lg-n4 {
		margin-right: -1.5rem !important
	}

	.mb-lg-n4,
	.my-lg-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-lg-n4,
	.mx-lg-n4 {
		margin-left: -1.5rem !important
	}

	.m-lg-n5 {
		margin: -2rem !important
	}

	.mt-lg-n5,
	.my-lg-n5 {
		margin-top: -2rem !important
	}

	.mr-lg-n5,
	.mx-lg-n5 {
		margin-right: -2rem !important
	}

	.mb-lg-n5,
	.my-lg-n5 {
		margin-bottom: -2rem !important
	}

	.ml-lg-n5,
	.mx-lg-n5 {
		margin-left: -2rem !important
	}

	.m-lg-n6 {
		margin: -3rem !important
	}

	.mt-lg-n6,
	.my-lg-n6 {
		margin-top: -3rem !important
	}

	.mr-lg-n6,
	.mx-lg-n6 {
		margin-right: -3rem !important
	}

	.mb-lg-n6,
	.my-lg-n6 {
		margin-bottom: -3rem !important
	}

	.ml-lg-n6,
	.mx-lg-n6 {
		margin-left: -3rem !important
	}

	.m-lg-n7 {
		margin: -4.5rem !important
	}

	.mt-lg-n7,
	.my-lg-n7 {
		margin-top: -4.5rem !important
	}

	.mr-lg-n7,
	.mx-lg-n7 {
		margin-right: -4.5rem !important
	}

	.mb-lg-n7,
	.my-lg-n7 {
		margin-bottom: -4.5rem !important
	}

	.ml-lg-n7,
	.mx-lg-n7 {
		margin-left: -4.5rem !important
	}

	.m-lg-n8 {
		margin: -6rem !important
	}

	.mt-lg-n8,
	.my-lg-n8 {
		margin-top: -6rem !important
	}

	.mr-lg-n8,
	.mx-lg-n8 {
		margin-right: -6rem !important
	}

	.mb-lg-n8,
	.my-lg-n8 {
		margin-bottom: -6rem !important
	}

	.ml-lg-n8,
	.mx-lg-n8 {
		margin-left: -6rem !important
	}

	.m-lg-n9 {
		margin: -8rem !important
	}

	.mt-lg-n9,
	.my-lg-n9 {
		margin-top: -8rem !important
	}

	.mr-lg-n9,
	.mx-lg-n9 {
		margin-right: -8rem !important
	}

	.mb-lg-n9,
	.my-lg-n9 {
		margin-bottom: -8rem !important
	}

	.ml-lg-n9,
	.mx-lg-n9 {
		margin-left: -8rem !important
	}

	.m-lg-auto {
		margin: auto !important
	}

	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto !important
	}

	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto !important
	}

	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto !important
	}

	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto !important
	}
}

@media (min-width:1200px) {
	.m-xl-0 {
		margin: 0 !important
	}

	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0 !important
	}

	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0 !important
	}

	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0 !important
	}

	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0 !important
	}

	.m-xl-1 {
		margin: .25rem !important
	}

	.mt-xl-1,
	.my-xl-1 {
		margin-top: .25rem !important
	}

	.mr-xl-1,
	.mx-xl-1 {
		margin-right: .25rem !important
	}

	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: .25rem !important
	}

	.ml-xl-1,
	.mx-xl-1 {
		margin-left: .25rem !important
	}

	.m-xl-2 {
		margin: .5rem !important
	}

	.mt-xl-2,
	.my-xl-2 {
		margin-top: .5rem !important
	}

	.mr-xl-2,
	.mx-xl-2 {
		margin-right: .5rem !important
	}

	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: .5rem !important
	}

	.ml-xl-2,
	.mx-xl-2 {
		margin-left: .5rem !important
	}

	.m-xl-3 {
		margin: 1rem !important
	}

	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem !important
	}

	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem !important
	}

	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem !important
	}

	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem !important
	}

	.m-xl-4 {
		margin: 1.5rem !important
	}

	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem !important
	}

	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem !important
	}

	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem !important
	}

	.m-xl-5 {
		margin: 2rem !important
	}

	.mt-xl-5,
	.my-xl-5 {
		margin-top: 2rem !important
	}

	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 2rem !important
	}

	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 2rem !important
	}

	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 2rem !important
	}

	.m-xl-6 {
		margin: 3rem !important
	}

	.mt-xl-6,
	.my-xl-6 {
		margin-top: 3rem !important
	}

	.mr-xl-6,
	.mx-xl-6 {
		margin-right: 3rem !important
	}

	.mb-xl-6,
	.my-xl-6 {
		margin-bottom: 3rem !important
	}

	.ml-xl-6,
	.mx-xl-6 {
		margin-left: 3rem !important
	}

	.m-xl-7 {
		margin: 4.5rem !important
	}

	.mt-xl-7,
	.my-xl-7 {
		margin-top: 4.5rem !important
	}

	.mr-xl-7,
	.mx-xl-7 {
		margin-right: 4.5rem !important
	}

	.mb-xl-7,
	.my-xl-7 {
		margin-bottom: 4.5rem !important
	}

	.ml-xl-7,
	.mx-xl-7 {
		margin-left: 4.5rem !important
	}

	.m-xl-8 {
		margin: 6rem !important
	}

	.mt-xl-8,
	.my-xl-8 {
		margin-top: 6rem !important
	}

	.mr-xl-8,
	.mx-xl-8 {
		margin-right: 6rem !important
	}

	.mb-xl-8,
	.my-xl-8 {
		margin-bottom: 6rem !important
	}

	.ml-xl-8,
	.mx-xl-8 {
		margin-left: 6rem !important
	}

	.m-xl-9 {
		margin: 8rem !important
	}

	.mt-xl-9,
	.my-xl-9 {
		margin-top: 8rem !important
	}

	.mr-xl-9,
	.mx-xl-9 {
		margin-right: 8rem !important
	}

	.mb-xl-9,
	.my-xl-9 {
		margin-bottom: 8rem !important
	}

	.ml-xl-9,
	.mx-xl-9 {
		margin-left: 8rem !important
	}

	.p-xl-0 {
		padding: 0 !important
	}

	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important
	}

	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0 !important
	}

	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important
	}

	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0 !important
	}

	.p-xl-1 {
		padding: .25rem !important
	}

	.pt-xl-1,
	.py-xl-1 {
		padding-top: .25rem !important
	}

	.pr-xl-1,
	.px-xl-1 {
		padding-right: .25rem !important
	}

	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: .25rem !important
	}

	.pl-xl-1,
	.px-xl-1 {
		padding-left: .25rem !important
	}

	.p-xl-2 {
		padding: .5rem !important
	}

	.pt-xl-2,
	.py-xl-2 {
		padding-top: .5rem !important
	}

	.pr-xl-2,
	.px-xl-2 {
		padding-right: .5rem !important
	}

	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: .5rem !important
	}

	.pl-xl-2,
	.px-xl-2 {
		padding-left: .5rem !important
	}

	.p-xl-3 {
		padding: 1rem !important
	}

	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem !important
	}

	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem !important
	}

	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem !important
	}

	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem !important
	}

	.p-xl-4 {
		padding: 1.5rem !important
	}

	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem !important
	}

	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem !important
	}

	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem !important
	}

	.p-xl-5 {
		padding: 2rem !important
	}

	.pt-xl-5,
	.py-xl-5 {
		padding-top: 2rem !important
	}

	.pr-xl-5,
	.px-xl-5 {
		padding-right: 2rem !important
	}

	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 2rem !important
	}

	.pl-xl-5,
	.px-xl-5 {
		padding-left: 2rem !important
	}

	.p-xl-6 {
		padding: 3rem !important
	}

	.pt-xl-6,
	.py-xl-6 {
		padding-top: 3rem !important
	}

	.pr-xl-6,
	.px-xl-6 {
		padding-right: 3rem !important
	}

	.pb-xl-6,
	.py-xl-6 {
		padding-bottom: 3rem !important
	}

	.pl-xl-6,
	.px-xl-6 {
		padding-left: 3rem !important
	}

	.p-xl-7 {
		padding: 4.5rem !important
	}

	.pt-xl-7,
	.py-xl-7 {
		padding-top: 4.5rem !important
	}

	.pr-xl-7,
	.px-xl-7 {
		padding-right: 4.5rem !important
	}

	.pb-xl-7,
	.py-xl-7 {
		padding-bottom: 4.5rem !important
	}

	.pl-xl-7,
	.px-xl-7 {
		padding-left: 4.5rem !important
	}

	.p-xl-8 {
		padding: 6rem !important
	}

	.pt-xl-8,
	.py-xl-8 {
		padding-top: 6rem !important
	}

	.pr-xl-8,
	.px-xl-8 {
		padding-right: 6rem !important
	}

	.pb-xl-8,
	.py-xl-8 {
		padding-bottom: 6rem !important
	}

	.pl-xl-8,
	.px-xl-8 {
		padding-left: 6rem !important
	}

	.p-xl-9 {
		padding: 8rem !important
	}

	.pt-xl-9,
	.py-xl-9 {
		padding-top: 8rem !important
	}

	.pr-xl-9,
	.px-xl-9 {
		padding-right: 8rem !important
	}

	.pb-xl-9,
	.py-xl-9 {
		padding-bottom: 8rem !important
	}

	.pl-xl-9,
	.px-xl-9 {
		padding-left: 8rem !important
	}

	.m-xl-n1 {
		margin: -.25rem !important
	}

	.mt-xl-n1,
	.my-xl-n1 {
		margin-top: -.25rem !important
	}

	.mr-xl-n1,
	.mx-xl-n1 {
		margin-right: -.25rem !important
	}

	.mb-xl-n1,
	.my-xl-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-xl-n1,
	.mx-xl-n1 {
		margin-left: -.25rem !important
	}

	.m-xl-n2 {
		margin: -.5rem !important
	}

	.mt-xl-n2,
	.my-xl-n2 {
		margin-top: -.5rem !important
	}

	.mr-xl-n2,
	.mx-xl-n2 {
		margin-right: -.5rem !important
	}

	.mb-xl-n2,
	.my-xl-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-xl-n2,
	.mx-xl-n2 {
		margin-left: -.5rem !important
	}

	.m-xl-n3 {
		margin: -1rem !important
	}

	.mt-xl-n3,
	.my-xl-n3 {
		margin-top: -1rem !important
	}

	.mr-xl-n3,
	.mx-xl-n3 {
		margin-right: -1rem !important
	}

	.mb-xl-n3,
	.my-xl-n3 {
		margin-bottom: -1rem !important
	}

	.ml-xl-n3,
	.mx-xl-n3 {
		margin-left: -1rem !important
	}

	.m-xl-n4 {
		margin: -1.5rem !important
	}

	.mt-xl-n4,
	.my-xl-n4 {
		margin-top: -1.5rem !important
	}

	.mr-xl-n4,
	.mx-xl-n4 {
		margin-right: -1.5rem !important
	}

	.mb-xl-n4,
	.my-xl-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-xl-n4,
	.mx-xl-n4 {
		margin-left: -1.5rem !important
	}

	.m-xl-n5 {
		margin: -2rem !important
	}

	.mt-xl-n5,
	.my-xl-n5 {
		margin-top: -2rem !important
	}

	.mr-xl-n5,
	.mx-xl-n5 {
		margin-right: -2rem !important
	}

	.mb-xl-n5,
	.my-xl-n5 {
		margin-bottom: -2rem !important
	}

	.ml-xl-n5,
	.mx-xl-n5 {
		margin-left: -2rem !important
	}

	.m-xl-n6 {
		margin: -3rem !important
	}

	.mt-xl-n6,
	.my-xl-n6 {
		margin-top: -3rem !important
	}

	.mr-xl-n6,
	.mx-xl-n6 {
		margin-right: -3rem !important
	}

	.mb-xl-n6,
	.my-xl-n6 {
		margin-bottom: -3rem !important
	}

	.ml-xl-n6,
	.mx-xl-n6 {
		margin-left: -3rem !important
	}

	.m-xl-n7 {
		margin: -4.5rem !important
	}

	.mt-xl-n7,
	.my-xl-n7 {
		margin-top: -4.5rem !important
	}

	.mr-xl-n7,
	.mx-xl-n7 {
		margin-right: -4.5rem !important
	}

	.mb-xl-n7,
	.my-xl-n7 {
		margin-bottom: -4.5rem !important
	}

	.ml-xl-n7,
	.mx-xl-n7 {
		margin-left: -4.5rem !important
	}

	.m-xl-n8 {
		margin: -6rem !important
	}

	.mt-xl-n8,
	.my-xl-n8 {
		margin-top: -6rem !important
	}

	.mr-xl-n8,
	.mx-xl-n8 {
		margin-right: -6rem !important
	}

	.mb-xl-n8,
	.my-xl-n8 {
		margin-bottom: -6rem !important
	}

	.ml-xl-n8,
	.mx-xl-n8 {
		margin-left: -6rem !important
	}

	.m-xl-n9 {
		margin: -8rem !important
	}

	.mt-xl-n9,
	.my-xl-n9 {
		margin-top: -8rem !important
	}

	.mr-xl-n9,
	.mx-xl-n9 {
		margin-right: -8rem !important
	}

	.mb-xl-n9,
	.my-xl-n9 {
		margin-bottom: -8rem !important
	}

	.ml-xl-n9,
	.mx-xl-n9 {
		margin-left: -8rem !important
	}

	.m-xl-auto {
		margin: auto !important
	}

	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto !important
	}

	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto !important
	}

	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto !important
	}

	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto !important
	}
}

@media (min-width:1480px) {
	.m-xxl-0 {
		margin: 0 !important
	}

	.mt-xxl-0,
	.my-xxl-0 {
		margin-top: 0 !important
	}

	.mr-xxl-0,
	.mx-xxl-0 {
		margin-right: 0 !important
	}

	.mb-xxl-0,
	.my-xxl-0 {
		margin-bottom: 0 !important
	}

	.ml-xxl-0,
	.mx-xxl-0 {
		margin-left: 0 !important
	}

	.m-xxl-1 {
		margin: .25rem !important
	}

	.mt-xxl-1,
	.my-xxl-1 {
		margin-top: .25rem !important
	}

	.mr-xxl-1,
	.mx-xxl-1 {
		margin-right: .25rem !important
	}

	.mb-xxl-1,
	.my-xxl-1 {
		margin-bottom: .25rem !important
	}

	.ml-xxl-1,
	.mx-xxl-1 {
		margin-left: .25rem !important
	}

	.m-xxl-2 {
		margin: .5rem !important
	}

	.mt-xxl-2,
	.my-xxl-2 {
		margin-top: .5rem !important
	}

	.mr-xxl-2,
	.mx-xxl-2 {
		margin-right: .5rem !important
	}

	.mb-xxl-2,
	.my-xxl-2 {
		margin-bottom: .5rem !important
	}

	.ml-xxl-2,
	.mx-xxl-2 {
		margin-left: .5rem !important
	}

	.m-xxl-3 {
		margin: 1rem !important
	}

	.mt-xxl-3,
	.my-xxl-3 {
		margin-top: 1rem !important
	}

	.mr-xxl-3,
	.mx-xxl-3 {
		margin-right: 1rem !important
	}

	.mb-xxl-3,
	.my-xxl-3 {
		margin-bottom: 1rem !important
	}

	.ml-xxl-3,
	.mx-xxl-3 {
		margin-left: 1rem !important
	}

	.m-xxl-4 {
		margin: 1.5rem !important
	}

	.mt-xxl-4,
	.my-xxl-4 {
		margin-top: 1.5rem !important
	}

	.mr-xxl-4,
	.mx-xxl-4 {
		margin-right: 1.5rem !important
	}

	.mb-xxl-4,
	.my-xxl-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-xxl-4,
	.mx-xxl-4 {
		margin-left: 1.5rem !important
	}

	.m-xxl-5 {
		margin: 2rem !important
	}

	.mt-xxl-5,
	.my-xxl-5 {
		margin-top: 2rem !important
	}

	.mr-xxl-5,
	.mx-xxl-5 {
		margin-right: 2rem !important
	}

	.mb-xxl-5,
	.my-xxl-5 {
		margin-bottom: 2rem !important
	}

	.ml-xxl-5,
	.mx-xxl-5 {
		margin-left: 2rem !important
	}

	.m-xxl-6 {
		margin: 3rem !important
	}

	.mt-xxl-6,
	.my-xxl-6 {
		margin-top: 3rem !important
	}

	.mr-xxl-6,
	.mx-xxl-6 {
		margin-right: 3rem !important
	}

	.mb-xxl-6,
	.my-xxl-6 {
		margin-bottom: 3rem !important
	}

	.ml-xxl-6,
	.mx-xxl-6 {
		margin-left: 3rem !important
	}

	.m-xxl-7 {
		margin: 4.5rem !important
	}

	.mt-xxl-7,
	.my-xxl-7 {
		margin-top: 4.5rem !important
	}

	.mr-xxl-7,
	.mx-xxl-7 {
		margin-right: 4.5rem !important
	}

	.mb-xxl-7,
	.my-xxl-7 {
		margin-bottom: 4.5rem !important
	}

	.ml-xxl-7,
	.mx-xxl-7 {
		margin-left: 4.5rem !important
	}

	.m-xxl-8 {
		margin: 6rem !important
	}

	.mt-xxl-8,
	.my-xxl-8 {
		margin-top: 6rem !important
	}

	.mr-xxl-8,
	.mx-xxl-8 {
		margin-right: 6rem !important
	}

	.mb-xxl-8,
	.my-xxl-8 {
		margin-bottom: 6rem !important
	}

	.ml-xxl-8,
	.mx-xxl-8 {
		margin-left: 6rem !important
	}

	.m-xxl-9 {
		margin: 8rem !important
	}

	.mt-xxl-9,
	.my-xxl-9 {
		margin-top: 8rem !important
	}

	.mr-xxl-9,
	.mx-xxl-9 {
		margin-right: 8rem !important
	}

	.mb-xxl-9,
	.my-xxl-9 {
		margin-bottom: 8rem !important
	}

	.ml-xxl-9,
	.mx-xxl-9 {
		margin-left: 8rem !important
	}

	.p-xxl-0 {
		padding: 0 !important
	}

	.pt-xxl-0,
	.py-xxl-0 {
		padding-top: 0 !important
	}

	.pr-xxl-0,
	.px-xxl-0 {
		padding-right: 0 !important
	}

	.pb-xxl-0,
	.py-xxl-0 {
		padding-bottom: 0 !important
	}

	.pl-xxl-0,
	.px-xxl-0 {
		padding-left: 0 !important
	}

	.p-xxl-1 {
		padding: .25rem !important
	}

	.pt-xxl-1,
	.py-xxl-1 {
		padding-top: .25rem !important
	}

	.pr-xxl-1,
	.px-xxl-1 {
		padding-right: .25rem !important
	}

	.pb-xxl-1,
	.py-xxl-1 {
		padding-bottom: .25rem !important
	}

	.pl-xxl-1,
	.px-xxl-1 {
		padding-left: .25rem !important
	}

	.p-xxl-2 {
		padding: .5rem !important
	}

	.pt-xxl-2,
	.py-xxl-2 {
		padding-top: .5rem !important
	}

	.pr-xxl-2,
	.px-xxl-2 {
		padding-right: .5rem !important
	}

	.pb-xxl-2,
	.py-xxl-2 {
		padding-bottom: .5rem !important
	}

	.pl-xxl-2,
	.px-xxl-2 {
		padding-left: .5rem !important
	}

	.p-xxl-3 {
		padding: 1rem !important
	}

	.pt-xxl-3,
	.py-xxl-3 {
		padding-top: 1rem !important
	}

	.pr-xxl-3,
	.px-xxl-3 {
		padding-right: 1rem !important
	}

	.pb-xxl-3,
	.py-xxl-3 {
		padding-bottom: 1rem !important
	}

	.pl-xxl-3,
	.px-xxl-3 {
		padding-left: 1rem !important
	}

	.p-xxl-4 {
		padding: 1.5rem !important
	}

	.pt-xxl-4,
	.py-xxl-4 {
		padding-top: 1.5rem !important
	}

	.pr-xxl-4,
	.px-xxl-4 {
		padding-right: 1.5rem !important
	}

	.pb-xxl-4,
	.py-xxl-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-xxl-4,
	.px-xxl-4 {
		padding-left: 1.5rem !important
	}

	.p-xxl-5 {
		padding: 2rem !important
	}

	.pt-xxl-5,
	.py-xxl-5 {
		padding-top: 2rem !important
	}

	.pr-xxl-5,
	.px-xxl-5 {
		padding-right: 2rem !important
	}

	.pb-xxl-5,
	.py-xxl-5 {
		padding-bottom: 2rem !important
	}

	.pl-xxl-5,
	.px-xxl-5 {
		padding-left: 2rem !important
	}

	.p-xxl-6 {
		padding: 3rem !important
	}

	.pt-xxl-6,
	.py-xxl-6 {
		padding-top: 3rem !important
	}

	.pr-xxl-6,
	.px-xxl-6 {
		padding-right: 3rem !important
	}

	.pb-xxl-6,
	.py-xxl-6 {
		padding-bottom: 3rem !important
	}

	.pl-xxl-6,
	.px-xxl-6 {
		padding-left: 3rem !important
	}

	.p-xxl-7 {
		padding: 4.5rem !important
	}

	.pt-xxl-7,
	.py-xxl-7 {
		padding-top: 4.5rem !important
	}

	.pr-xxl-7,
	.px-xxl-7 {
		padding-right: 4.5rem !important
	}

	.pb-xxl-7,
	.py-xxl-7 {
		padding-bottom: 4.5rem !important
	}

	.pl-xxl-7,
	.px-xxl-7 {
		padding-left: 4.5rem !important
	}

	.p-xxl-8 {
		padding: 6rem !important
	}

	.pt-xxl-8,
	.py-xxl-8 {
		padding-top: 6rem !important
	}

	.pr-xxl-8,
	.px-xxl-8 {
		padding-right: 6rem !important
	}

	.pb-xxl-8,
	.py-xxl-8 {
		padding-bottom: 6rem !important
	}

	.pl-xxl-8,
	.px-xxl-8 {
		padding-left: 6rem !important
	}

	.p-xxl-9 {
		padding: 8rem !important
	}

	.pt-xxl-9,
	.py-xxl-9 {
		padding-top: 8rem !important
	}

	.pr-xxl-9,
	.px-xxl-9 {
		padding-right: 8rem !important
	}

	.pb-xxl-9,
	.py-xxl-9 {
		padding-bottom: 8rem !important
	}

	.pl-xxl-9,
	.px-xxl-9 {
		padding-left: 8rem !important
	}

	.m-xxl-n1 {
		margin: -.25rem !important
	}

	.mt-xxl-n1,
	.my-xxl-n1 {
		margin-top: -.25rem !important
	}

	.mr-xxl-n1,
	.mx-xxl-n1 {
		margin-right: -.25rem !important
	}

	.mb-xxl-n1,
	.my-xxl-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-xxl-n1,
	.mx-xxl-n1 {
		margin-left: -.25rem !important
	}

	.m-xxl-n2 {
		margin: -.5rem !important
	}

	.mt-xxl-n2,
	.my-xxl-n2 {
		margin-top: -.5rem !important
	}

	.mr-xxl-n2,
	.mx-xxl-n2 {
		margin-right: -.5rem !important
	}

	.mb-xxl-n2,
	.my-xxl-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-xxl-n2,
	.mx-xxl-n2 {
		margin-left: -.5rem !important
	}

	.m-xxl-n3 {
		margin: -1rem !important
	}

	.mt-xxl-n3,
	.my-xxl-n3 {
		margin-top: -1rem !important
	}

	.mr-xxl-n3,
	.mx-xxl-n3 {
		margin-right: -1rem !important
	}

	.mb-xxl-n3,
	.my-xxl-n3 {
		margin-bottom: -1rem !important
	}

	.ml-xxl-n3,
	.mx-xxl-n3 {
		margin-left: -1rem !important
	}

	.m-xxl-n4 {
		margin: -1.5rem !important
	}

	.mt-xxl-n4,
	.my-xxl-n4 {
		margin-top: -1.5rem !important
	}

	.mr-xxl-n4,
	.mx-xxl-n4 {
		margin-right: -1.5rem !important
	}

	.mb-xxl-n4,
	.my-xxl-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-xxl-n4,
	.mx-xxl-n4 {
		margin-left: -1.5rem !important
	}

	.m-xxl-n5 {
		margin: -2rem !important
	}

	.mt-xxl-n5,
	.my-xxl-n5 {
		margin-top: -2rem !important
	}

	.mr-xxl-n5,
	.mx-xxl-n5 {
		margin-right: -2rem !important
	}

	.mb-xxl-n5,
	.my-xxl-n5 {
		margin-bottom: -2rem !important
	}

	.ml-xxl-n5,
	.mx-xxl-n5 {
		margin-left: -2rem !important
	}

	.m-xxl-n6 {
		margin: -3rem !important
	}

	.mt-xxl-n6,
	.my-xxl-n6 {
		margin-top: -3rem !important
	}

	.mr-xxl-n6,
	.mx-xxl-n6 {
		margin-right: -3rem !important
	}

	.mb-xxl-n6,
	.my-xxl-n6 {
		margin-bottom: -3rem !important
	}

	.ml-xxl-n6,
	.mx-xxl-n6 {
		margin-left: -3rem !important
	}

	.m-xxl-n7 {
		margin: -4.5rem !important
	}

	.mt-xxl-n7,
	.my-xxl-n7 {
		margin-top: -4.5rem !important
	}

	.mr-xxl-n7,
	.mx-xxl-n7 {
		margin-right: -4.5rem !important
	}

	.mb-xxl-n7,
	.my-xxl-n7 {
		margin-bottom: -4.5rem !important
	}

	.ml-xxl-n7,
	.mx-xxl-n7 {
		margin-left: -4.5rem !important
	}

	.m-xxl-n8 {
		margin: -6rem !important
	}

	.mt-xxl-n8,
	.my-xxl-n8 {
		margin-top: -6rem !important
	}

	.mr-xxl-n8,
	.mx-xxl-n8 {
		margin-right: -6rem !important
	}

	.mb-xxl-n8,
	.my-xxl-n8 {
		margin-bottom: -6rem !important
	}

	.ml-xxl-n8,
	.mx-xxl-n8 {
		margin-left: -6rem !important
	}

	.m-xxl-n9 {
		margin: -8rem !important
	}

	.mt-xxl-n9,
	.my-xxl-n9 {
		margin-top: -8rem !important
	}

	.mr-xxl-n9,
	.mx-xxl-n9 {
		margin-right: -8rem !important
	}

	.mb-xxl-n9,
	.my-xxl-n9 {
		margin-bottom: -8rem !important
	}

	.ml-xxl-n9,
	.mx-xxl-n9 {
		margin-left: -8rem !important
	}

	.m-xxl-auto {
		margin: auto !important
	}

	.mt-xxl-auto,
	.my-xxl-auto {
		margin-top: auto !important
	}

	.mr-xxl-auto,
	.mx-xxl-auto {
		margin-right: auto !important
	}

	.mb-xxl-auto,
	.my-xxl-auto {
		margin-bottom: auto !important
	}

	.ml-xxl-auto,
	.mx-xxl-auto {
		margin-left: auto !important
	}
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0, 0, 0, 0)
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
	text-align: justify !important
}

.text-wrap {
	white-space: normal !important
}

.text-nowrap {
	white-space: nowrap !important
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.text-left {
	text-align: left !important
}

.text-right {
	text-align: right !important
}

.text-center {
	text-align: center !important
}

@media (min-width:576px) {
	.text-sm-left {
		text-align: left !important
	}

	.text-sm-right {
		text-align: right !important
	}

	.text-sm-center {
		text-align: center !important
	}
}

@media (min-width:768px) {
	.text-md-left {
		text-align: left !important
	}

	.text-md-right {
		text-align: right !important
	}

	.text-md-center {
		text-align: center !important
	}
}

@media (min-width:992px) {
	.text-lg-left {
		text-align: left !important
	}

	.text-lg-right {
		text-align: right !important
	}

	.text-lg-center {
		text-align: center !important
	}
}

@media (min-width:1200px) {
	.text-xl-left {
		text-align: left !important
	}

	.text-xl-right {
		text-align: right !important
	}

	.text-xl-center {
		text-align: center !important
	}
}

@media (min-width:1480px) {
	.text-xxl-left {
		text-align: left !important
	}

	.text-xxl-right {
		text-align: right !important
	}

	.text-xxl-center {
		text-align: center !important
	}
}

.text-lowercase {
	text-transform: lowercase !important
}

.text-uppercase {
	text-transform: uppercase !important
}

.text-capitalize {
	text-transform: capitalize !important
}

.font-weight-light {
	font-weight: 300 !important
}

.font-weight-lighter {
	font-weight: lighter !important
}

.font-weight-normal {
	font-weight: 400 !important
}

.font-weight-bold {
	font-weight: 700 !important
}

.font-weight-bolder {
	font-weight: bolder !important
}

.font-italic {
	font-style: italic !important
}

.text-white {
	color: #fff !important
}

.text-primary {
	color: #5dd39e !important
}

a.text-primary:focus,
a.text-primary:hover {
	color: #31b378 !important
}

.text-secondary {
	color: #50575e !important
}

a.text-secondary:focus,
a.text-secondary:hover {
	color: #2d3135 !important
}

.text-success {
	color: #2e843f !important
}

a.text-success:focus,
a.text-success:hover {
	color: #1a4b24 !important
}

.text-info {
	color: #3952af !important
}

a.text-info:focus,
a.text-info:hover {
	color: #263775 !important
}

.text-warning {
	color: #ffdd4a !important
}

a.text-warning:focus,
a.text-warning:hover {
	color: #fdcd00 !important
}

.text-danger {
	color: #f26868 !important
}

a.text-danger:focus,
a.text-danger:hover {
	color: #ec2222 !important
}

.text-light {
	color: #fff !important
}

a.text-light:focus,
a.text-light:hover {
	color: #d9d9d9 !important
}

.text-dark {
	color: #2f3249 !important
}

a.text-dark:focus,
a.text-dark:hover {
	color: #11121a !important
}

.text-cheddar {
	color: #e8bb28 !important
}

a.text-cheddar:focus,
a.text-cheddar:hover {
	color: #b18c13 !important
}

.text-whales {
	color: #3c5de2 !important
}

a.text-whales:focus,
a.text-whales:hover {
	color: #1b3ab6 !important
}

.text-super {
	color: #ccc !important
}

a.text-super:focus,
a.text-super:hover {
	color: #a6a6a6 !important
}

.text-body {
	color: #0b103f !important
}

.text-muted {
	color: #585858 !important
}

.text-black-50 {
	color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
	color: rgba(255, 255, 255, .5) !important
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0
}

.text-decoration-none {
	text-decoration: none !important
}

.text-break {
	word-break: break-word !important;
	word-wrap: break-word !important
}

.text-reset {
	color: inherit !important
}

.visible {
	visibility: visible !important
}

.invisible {
	visibility: hidden !important
}

body,
html {
	height: 100%
}

html.-height-auto,
html.-height-auto body {
	height: auto
}

html {
	font-size: 16px
}

@media (min-width:992px) and (max-width:1479px) {
	html {
		font-size: 13px
	}
}

#main-content {
	position: relative
}

a:not(.btn)[data-focus-method=key] {
	transition-duration: 0s !important
}

a:not(.btn)[data-focus-method=key]:active,
a:not(.btn)[data-focus-method=key]:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af
}

a.stretched-link[data-focus-method=key]:active,
a.stretched-link[data-focus-method=key]:focus {
	outline: 0
}

a.stretched-link[data-focus-method=key]:active:after,
a.stretched-link[data-focus-method=key]:focus:after {
	transition-duration: 0s !important;
	outline-offset: 2px;
	outline: 1px solid #3952af;
	border-radius: inherit
}

:root {
	--header-height: 135px;
	--header-top-height: 60px
}

@media (max-width:1479.98px) {
	:root {
		--header-height: 140px;
		--header-top-height: 45px
	}
}

@media (max-width:1199.98px) {
	:root {
		--header-height: 102px
	}
}

@media (max-width:991.98px) {
	:root {
		--header-height: 114px
	}
}

@media (max-width:767.98px) {
	:root {
		--header-height: 120px;
		--header-top-height: 40px
	}
}

@media (max-width:575.98px) {
	:root {
		--header-height: 97px
	}
}

.header {
	position: relative;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	transition: all .2s ease;
	color: #fff;
	will-change: transform;
	height: var(--header-height)
}

.header.-sticky {
	position: -webkit-sticky;
	position: sticky;
	top: calc(0px - var(--header-top-height))
}

.header [class*=col]:first-child {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-left: 2rem
}

@media (max-width:1199.98px) {
	.header [class*=col]:first-child {
		padding-left: 1rem
	}
}

.header [class*=col]:last-child {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 2rem
}

@media (max-width:1199.98px) {
	.header [class*=col]:last-child {
		padding-right: 1rem
	}
}

.header-top {
	height: var(--header-top-height);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	background-color: #2f3249;
	border-bottom: 1px solid rgba(255, 255, 255, .08)
}

@media (max-width:767.98px) {
	.header-top .row {
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse
	}

	.header-top [class*=col] {
		position: relative;
		z-index: 11
	}

	.header-top [class*=col]:first-child {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important
	}

	.header-top [class*=col]:last-child {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important
	}
}

.header-main {
	padding: .6rem 2rem;
	display: grid;
	-ms-flex-align: center;
	align-items: center;
	grid-template-columns: 280px 1fr 280px;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	background-color: #2f3249;
	height: calc(var(--header-height) - var(--header-top-height))
}

@media (max-width:1479.98px) {
	.header-main {
		grid-template-columns: 240px 1fr 240px
	}
}

@media (max-width:1199.98px) {
	.header-main {
		padding: .5rem 1rem;
		grid-template-columns: 200px 1fr 200px
	}
}

@media (max-width:991.98px) {
	.header-main {
		grid-gap: 1.5rem;
		gap: 1rem;
		grid-template-columns: 200px 1fr
	}
}

@media (max-width:575.98px) {
	.header-main {
		grid-template-columns: 140px 1fr
	}
}

@media (max-width:420px) {
	.header-main {
		padding: .5rem .75rem;
		grid-template-columns: 36% 1fr
	}
}

@media (max-width:991.98px) {
	.header-main__menu {
		display: none
	}
}

.header .custom-logo-link {
	display: block
}

.header .custom-logo-link[data-focus-method=key] {
	transition-duration: 0s !important
}

.header .custom-logo-link[data-focus-method=key]:active,
.header .custom-logo-link[data-focus-method=key]:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af
}

.header .icon-link:hover {
	color: #fff
}

@media (max-width:1199.98px) {
	.header .icon-link {
		font-size: 1rem
	}
}

@media (min-width:1200px) {
	.header .back-link {
		font-size: 1.125rem
	}
}

@media (max-width:767.98px) {
	.header .back-link {
		font-size: 12px
	}
}

@media (max-width:1479.98px) {
	.header .btn-wide {
		min-width: 140px
	}
}

@media (max-width:1199.98px) {
	.header .btn-wide {
		min-width: 120px
	}
}

@media (max-width:767.98px) {
	.header .btn-wide {
		min-width: 80px
	}
}

@media (max-width:1199.98px) {
	.header .btn {
		padding: .75rem 1.4rem
	}
}

@media (max-width:767.98px) {
	.header .btn {
		font-size: 14px
	}
}

@media (max-width:575.98px) {
	.header .btn {
		padding: .65rem 1rem;
		font-size: 12px
	}
}

@media (max-width:350px) {
	.header .btn {
		padding: .6rem .4rem;
		font-size: 12px
	}
}

.header .btn-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-left: -.5rem;
	margin-right: -.5rem
}

.header .btn-group .btn {
	margin: 0
}

.header .btn-group>* {
	margin: 0 .5rem !important
}

@media (max-width:767.98px) {
	.header .btn-group .-login {
		padding: 0
	}
}

.header .btn-group .-dashboard {
	color: inherit;
	text-decoration: none;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap
}

@media (max-width:575.98px) {
	.header .btn-group .-dashboard {
		font-size: .875rem
	}
}

.header .btn-group .-dashboard>span {
	-ms-flex-positive: 1;
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

@media (max-width:575.98px) {
	.header .btn-group .-dashboard>span {
		max-width: 120px
	}
}

@media (max-width:575.98px) {
	.header .btn-group .-dashboard .welcome {
		display: none
	}
}

.header .btn-group .-dashboard:focus .name,
.header .btn-group .-dashboard:hover .name {
	text-decoration: underline
}

.header .btn-group .-dashboard .icon {
	margin-left: .5em;
	font-size: 1.5em;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	transition: color .25s ease
}

.header .btn-group .-dashboard:focus .icon,
.header .btn-group .-dashboard:hover .icon {
	color: #5dd39e
}

.header .hamburger {
	position: relative;
	z-index: 10
}

.dark-header .header-main {
	background-color: #2f3249
}

.static-header .header {
	position: relative
}

.skip-to-main-content {
	background-color: rgba(255, 255, 255, .75);
	color: #2f3249;
	padding: .5rem 1rem;
	font-size: 1.25rem;
	position: fixed;
	top: 1rem;
	left: 1rem;
	z-index: 5000
}

.skip-to-main-content:not(:focus) {
	display: block;
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden
}

.site-footer {
	background-color: #2f3249;
	color: #fff;
	font-weight: 400;
	padding-top: 70px
}

.site-footer a {
	transition: color .15s ease
}

.site-footer a:hover {
	text-decoration: underline
}

.site-footer__top {
	padding-bottom: 3.75rem;
	border-bottom: 1px solid rgba(255, 255, 255, .1)
}

.site-footer__bottom {
	font-size: .875rem
}

@media (max-width:575.98px) {
	.site-footer__bottom {
		font-size: 14px
	}
}

.site-footer__bottom a,
.site-footer__disclaimer a {
	color: #b6cbff;
	text-decoration: underline
}

.site-footer__disclaimer {
	font-size: .875rem;
	padding-top: 1rem;
	padding-bottom: 3rem
}

@media (max-width:575.98px) {
	.site-footer__disclaimer {
		font-size: .75rem;
		padding: 0 0 1rem
	}
}

.site-footer__disclaimer .disclaimer {
	border: 1px solid rgba(255, 255, 255, .12);
	border-radius: 1rem;
	padding: 1rem 2rem;
	line-height: 1.5;
	max-width: 800px;
	margin: 0 auto
}

@media (max-width:575.98px) {
	.site-footer__disclaimer .disclaimer {
		padding: .75rem
	}
}

.site-footer .footer-logo {
	max-width: 250px;
	display: block
}

.site-footer .badge-image {
	height: 38px
}

@media (max-width:1199.98px) {
	.site-footer .badge-image {
		height: 32px
	}
}

@media (max-width:991.98px) {
	.site-footer .badge-image {
		height: 36px
	}
}

.site-primary-menu {
	width: 100%;
	position: relative;
	z-index: 5
}

.site-nav {
	--menu-item-gap: 2rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	font-size: 1.375rem
}

@media (max-width:1199.98px) {
	.site-nav {
		font-size: 1rem
	}
}

.site-nav .menu-item {
	position: relative
}

.site-nav .menu-item [data-badge] {
	position: absolute;
	bottom: 75%;
	left: 85%;
	font-size: .6em
}

.site-nav a,
.site-nav button {
	display: block;
	padding: .25em calc(.5 * var(--menu-item-gap));
	color: inherit;
	border: 0;
	background-color: #2f3249;
	text-transform: inherit;
	white-space: nowrap;
	transition: opacity .25s ease;
	position: relative
}

.site-nav a:active,
.site-nav a:focus,
.site-nav button:active,
.site-nav button:focus {
	outline: 0
}

.site-nav a[data-focus-method=key],
.site-nav button[data-focus-method=key] {
	transition-duration: 0s !important
}

.site-nav a[data-focus-method=key]:active,
.site-nav a[data-focus-method=key]:focus,
.site-nav button[data-focus-method=key]:active,
.site-nav button[data-focus-method=key]:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af
}

.site-nav a:hover,
.site-nav button:hover {
	-webkit-text-decoration: underline solid currentColor 1px;
	text-decoration: underline solid currentColor 1px
}

.site-nav button {
	cursor: auto
}

.site-nav button[data-href],
.site-nav button[href] {
	cursor: pointer
}

.site-nav .menu-item-has-children a,
.site-nav .menu-item-has-children button {
	padding-right: 1.25em;
	position: relative
}

.site-nav .menu-item-has-children a .icon,
.site-nav .menu-item-has-children button .icon {
	font-size: .65em;
	position: absolute;
	top: 50%;
	right: .75em;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.site-menu {
	--site-menu-height: 100px;
	--site-menu-width: 450px;
	--site-menu-gap: 2rem;
	--site-menu-spacing: 2rem;
	--site-menu-arrow-size: 12px;
	--site-menu-arrow-offset: 50%;
	--site-menu-arrow-spacing: 8px;
	--site-menu-background-color: #ffffff;
	--site-menu-text-color: #2F3249;
	--site-menu-transition: .25s;
	--site-menu-rotateX: -15deg;
	display: block;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 1000px;
	z-index: 1;
	pointer-events: none !important;
	-webkit-perspective: 2000px;
	perspective: 2000px;
	opacity: 0;
	transition-property: opacity;
	transition-duration: var(--site-menu-transition);
	overflow: hidden
}

.site-menu.-is-active {
	--site-menu-rotateX: 0;
	opacity: 1;
	overflow: visible;
	pointer-events: auto
}

.site-menu.-has-transition {
	overflow: visible
}

.site-menu__arrow {
	display: block;
	width: var(--site-menu-arrow-size);
	height: var(--site-menu-arrow-size);
	background-color: var(--site-menu-background-color);
	position: absolute;
	left: 50%;
	top: var(--site-menu-arrow-spacing);
	margin-top: calc(0px - .5 * var(--site-menu-arrow-size));
	box-shadow: -3px -3px 5px rgba(82, 95, 127, .04);
	-webkit-transform: translateX(-50%) translateX(var(--site-menu-arrow-offset)) rotate(45deg);
	transform: translateX(-50%) translateX(var(--site-menu-arrow-offset)) rotate(45deg);
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform
}

.site-menu.-has-transition .site-menu__arrow {
	transition-duration: var(--site-menu-transition)
}

.site-menu__container {
	height: calc(var(--site-menu-height) + var(--site-menu-arrow-spacing));
	padding-top: var(--site-menu-arrow-spacing);
	width: var(--site-menu-width);
	-webkit-filter: drop-shadow(0 0 32px rgba(20, 48, 62, .22));
	filter: drop-shadow(0 0 32px rgba(20, 48, 62, .22));
	-webkit-transform: translateX(-50%) translateX(var(--site-menu-translateX, 0)) rotateX(var(--site-menu-rotateX));
	transform: translateX(-50%) translateX(var(--site-menu-translateX, 0)) rotateX(var(--site-menu-rotateX));
	transition-property: opacity, width, height, -webkit-transform;
	transition-property: transform, opacity, width, height;
	transition-property: transform, opacity, width, height, -webkit-transform;
	will-change: transform, width, height;
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	position: absolute;
	left: 50%;
	top: 0;
	overflow: hidden
}

.site-menu.-is-active .site-menu__container {
	pointer-events: auto
}

.site-menu.-has-transition .site-menu__container {
	transition-duration: var(--site-menu-transition)
}

.site-menu__container__wrapper {
	height: 100%;
	border-radius: .5rem;
	border: 1px solid #eff4f9;
	background-color: var(--site-menu-background-color);
	color: var(--site-menu-text-color);
	overflow: hidden
}

.site-menu.-has-transition .site-menu__section {
	transition-property: opacity, -webkit-transform;
	transition-property: transform, opacity;
	transition-property: transform, opacity, -webkit-transform;
	transition-duration: var(--site-menu-transition)
}

.site-menu__section {
	position: absolute;
	top: var(--site-menu-arrow-spacing);
	left: 50%;
	-webkit-transform: translateX(-50%) translateX(var(--site-menu-section-offset, 0));
	transform: translateX(-50%) translateX(var(--site-menu-section-offset, 0));
	transition-property: opacity
}

.site-menu__section.-hidden {
	opacity: 0;
	pointer-events: none
}

.site-menu__section.-left {
	--site-menu-section-offset: -150px
}

.site-menu__section.-right {
	--site-menu-section-offset: 150px
}

.site-menu__section__header {
	padding: var(--site-menu-spacing);
	padding-bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: baseline;
	align-items: baseline
}

.site-menu__section__title {
	padding-bottom: 1rem;
	border-bottom: 1px solid #eff4f9;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-weight: 700;
	font-size: 1.125rem
}

.site-menu__section__title.-sidebar {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	width: 200px
}

.site-menu__section__title+.site-menu__section__title {
	margin-left: var(--site-menu-spacing)
}

.site-menu__section__body {
	padding: var(--site-menu-spacing);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start
}

.site-menu__section__footer {
	padding: calc(.5 * var(--site-menu-spacing));
	background-color: #f8fafc;
	border-radius: 0 0 .5rem .5rem
}

.site-menu__section__sidebar {
	width: 200px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: var(--site-menu-spacing)
}

.site-menu__section__sidebar hr {
	border-color: #eff4f9;
	margin: 2rem 0 1.5rem
}

.site-menu__section__layout {
	--layout-columns: 1;
	display: grid;
	grid-template-columns: repeat(var(--layout-columns), 1fr);
	gap: var(--site-menu-gap);
	-ms-flex-positive: 1;
	flex-grow: 1
}

.site-menu__section__layout .site-menu-link {
	min-width: 220px
}

@media (max-width:1479.98px) {
	.site-menu__section__layout .site-menu-link {
		min-width: 190px
	}
}

.site-menu__section__layout.-platform {
	--layout-columns: 2
}

.site-menu__section__layout.-platform .site-menu-link {
	min-width: 300px
}

@media (max-width:1479.98px) {
	.site-menu__section__layout.-platform .site-menu-link {
		min-width: 250px
	}
}

.site-menu__section__layout.-resources {
	--layout-columns: 3
}

.site-menu__section__layout.-partners {
	--layout-columns: 1
}

.site-menu__list__title {
	font-size: 1em;
	color: #585858;
	margin-bottom: .5rem;
	text-transform: uppercase
}

.site-menu__list .site-menu-item:not(:last-child) {
	margin-bottom: .25em
}

.site-menu-item {
	list-style: none
}

.site-menu-link {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: left
}

.site-menu-link__icon {
	width: var(--site-menu-icon-size, 2.5em);
	margin-right: var(--site-menu-icon-offset, 1em);
	-webkit-transform: translateY(.2em);
	transform: translateY(.2em);
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.site-menu-link__content {
	display: block;
	line-height: 1.25
}

.site-menu-link__content__label {
	font-size: 1.125em;
	color: #2f3249;
	font-weight: 700;
	margin: 0;
	position: relative;
	display: inline-block;
	margin-right: 10px
}

.site-menu-link__content__label.-external {
	margin-right: 0
}

.site-menu-link__content__label.-arrow::after {
	content: "";
	width: 10px;
	height: 10px;
	position: absolute;
	left: 100%;
	top: .625em;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' %3E%3Cpath fill='currentColor' d='M190.5 66.9l22.2-22.2a23.9 23.9 0 0133.9 0L441 239a23.9 23.9 0 010 33.9L246.6 467.3a23.9 23.9 0 01-33.9 0l-22.2-22.2a24 24 0 01.4-34.3L311.4 296H24a24 24 0 01-24-24v-32a24 24 0 0124-24h287.4L190.9 101.2a23.9 23.9 0 01-.4-34.3z'/%3E%3C/svg%3E");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	opacity: 0;
	transition: all .25s ease
}

.site-menu-link__content__label .icon {
	font-size: 75%;
	margin-left: .25em;
	vertical-align: 0
}

.site-menu-link__content__label .badge {
	background-color: rgba(47, 50, 73, .12);
	font-size: 60%;
	text-transform: uppercase;
	padding: .3em .75em;
	border-radius: 4px;
	margin-left: .25em;
	vertical-align: middle
}

.site-menu-link__content__description {
	color: #585858;
	transition: color .25s ease;
	margin: 0;
	font-size: 90%;
	line-height: 1;
	margin-top: .15em;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	display: block
}

.site-menu-link:focus {
	outline: 0
}

.site-menu-link:focus .site-menu-link__content__label::after,
.site-menu-link:hover .site-menu-link__content__label::after {
	-webkit-transform: translate(100%, -50%);
	transform: translate(100%, -50%);
	opacity: 1
}

.site-menu-link[target=_blank] .site-menu-link__content__label::after {
	display: none
}

.site-menu-link:focus .site-menu-link__content__description,
.site-menu-link:hover .site-menu-link__content__description {
	color: #2f3249
}

.service-menu-link img {
	margin-bottom: .5rem
}

.service-menu-link__content {
	color: #585858;
	transition: color .25s ease;
	margin: 0;
	font-size: 1rem;
	line-height: 1;
	margin-top: .15em;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	display: block
}

.service-menu-link__content .icon {
	font-size: 75%;
	margin-left: .25em;
	vertical-align: 0
}

.header-menu-secondary {
	position: relative;
	margin-right: -1em;
	margin-left: -1em
}

@media (max-width:1479.98px) {
	.header-menu-secondary {
		margin-right: -.625em;
		margin-left: -.625em
	}
}

@media (max-width:991.98px) {
	.header-menu-secondary {
		font-size: .875rem
	}
}

@media (max-width:991.98px) {
	.header-menu-secondary {
		font-size: .8125rem
	}
}

.header-menu-secondary .menu {
	--menu-item-gap: 2em;
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	font-size: inherit
}

@media (max-width:1479.98px) {
	.header-menu-secondary .menu {
		--menu-item-gap: 1.25em
	}
}

.header-menu-secondary .menu-item {
	text-transform: none
}

.header-menu-secondary .menu-item a,
.header-menu-secondary .menu-item button {
	display: block;
	color: inherit;
	opacity: .7;
	line-height: 1.5;
	white-space: nowrap;
	transition: all .2s ease-out;
	padding-top: 0;
	padding-bottom: 0
}

.header-menu-secondary .menu-item a[data-focus-method=key],
.header-menu-secondary .menu-item button[data-focus-method=key] {
	transition-duration: 0s !important
}

.header-menu-secondary .menu-item a[data-focus-method=key]:active,
.header-menu-secondary .menu-item a[data-focus-method=key]:focus,
.header-menu-secondary .menu-item button[data-focus-method=key]:active,
.header-menu-secondary .menu-item button[data-focus-method=key]:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af
}

.header-menu-secondary .menu-item a:hover,
.header-menu-secondary .menu-item button:hover {
	opacity: 1;
	text-decoration: underline
}

.header-menu-secondary .menu-item a .icon,
.header-menu-secondary .menu-item button .icon {
	font-size: 75%;
	margin-left: .25em
}

.header-menu-secondary .site-menu {
	--site-menu-spacing: 1.75em;
	left: auto;
	right: 0;
	font-size: .875rem;
	z-index: 15
}

@media (max-width:991.98px) {
	.header-menu-secondary .site-menu {
		font-size: .75rem
	}
}

.header-menu-secondary .site-menu__section__layout .site-menu-link {
	min-width: 14em
}

.site-mobile-menu {
	position: absolute;
	top: calc(var(--header-top-height) + 1rem);
	left: 1rem;
	right: 1rem;
	background-color: #fff;
	color: #2f3249;
	border-radius: .5rem;
	z-index: 100;
	max-height: calc(100vh - var(--header-top-height) - 2rem);
	overflow: auto;
	padding: 2em;
	font-size: 14px;
	box-shadow: 0 50px 100px -20px rgba(50, 50, 93, .25), 0 30px 60px -30px rgba(0, 0, 0, .3);
	-webkit-transform: scale(.95);
	transform: scale(.95);
	opacity: 0;
	pointer-events: none;
	transition-property: opacity, -webkit-transform;
	transition-property: transform, opacity;
	transition-property: transform, opacity, -webkit-transform;
	transition-duration: 250ms;
	will-change: transform, opacity;
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0
}

.site-mobile-menu.-is-active {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	pointer-events: auto
}

@media (min-width:992px) {
	.site-mobile-menu {
		display: none !important
	}
}

@media (max-width:575.98px) {
	.site-mobile-menu {
		font-size: 13px
	}
}

@media (max-width:480px) {
	.site-mobile-menu {
		font-size: 12px;
		padding: 1.5em;
		top: calc(var(--header-top-height) + .5rem);
		left: .5rem;
		right: .5rem;
		max-height: calc(100vh - var(--header-top-height) - 1rem)
	}
}

.site-mobile-menu__close {
	width: 2.5em;
	height: 2.5em;
	border: 0;
	background-color: transparent;
	padding: 0;
	position: absolute;
	top: .5em;
	right: .5em
}

.site-mobile-menu__close:focus {
	outline: 0
}

.site-mobile-menu__close svg {
	display: block;
	max-width: 100%;
	height: auto
}

.site-mobile-menu .site-menu__list {
	--columns: 3;
	--gap-x: 1.5em;
	--gap-y: .5em;
	display: grid;
	grid-template-columns: repeat(var(--columns), 1fr);
	grid-gap: var(--gap-y) var(--gap-x);
	gap: var(--gap-y) var(--gap-x)
}

@media (max-width:767.98px) {
	.site-mobile-menu .site-menu__list {
		--columns: 2
	}
}

@media (max-width:480px) {
	.site-mobile-menu .site-menu__list {
		--gap-x: 1em
	}
}

.site-mobile-menu .site-menu__list__title {
	grid-column: span var(--columns)
}

.site-mobile-menu .site-menu__list .site-menu-item,
.site-mobile-menu .site-menu__list__title {
	margin: 0 !important
}

.site-mobile-menu .site-menu__list.-platform {
	--gap-y: 1em
}

@media (max-width:480px) {
	.site-mobile-menu .site-menu__list.-platform {
		--columns: 1;
		--gap-y: 1.25em;
		font-size: 14px
	}
}

.site-mobile-menu .site-menu__list+.site-menu__list {
	margin-top: 1.5em;
	padding-top: 1.5em;
	border-top: 1px dashed #eff4f9
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
	padding: 0 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	outline: 0 !important;
	transition-property: opacity, -webkit-filter;
	transition-property: opacity, filter;
	transition-property: opacity, filter, -webkit-filter;
	transition-duration: .15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible
}

.hamburger:hover {
	opacity: .7
}

.hamburger.is-active:hover {
	opacity: .7
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::after,
.hamburger.is-active .hamburger-inner::before {
	background-color: #5dd39e
}

.hamburger-box {
	width: 20px;
	height: 14px;
	display: inline-block;
	position: relative
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -1px
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
	width: 20px;
	height: 2px;
	background-color: #5dd39e;
	border-radius: 4px;
	position: absolute;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: .15s;
	transition-timing-function: ease
}

.hamburger-inner::after,
.hamburger-inner::before {
	content: "";
	display: block
}

.hamburger-inner::before {
	top: -6px
}

.hamburger-inner::after {
	bottom: -6px
}

.hamburger--spin .hamburger-inner {
	transition-duration: .22s;
	transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin .hamburger-inner::before {
	transition: top .1s .25s ease-in, opacity .1s ease-in
}

.hamburger--spin .hamburger-inner::after {
	transition: bottom .1s .25s ease-in, -webkit-transform .22s cubic-bezier(.55, .055, .675, .19);
	transition: bottom .1s .25s ease-in, transform .22s cubic-bezier(.55, .055, .675, .19);
	transition: bottom .1s .25s ease-in, transform .22s cubic-bezier(.55, .055, .675, .19), -webkit-transform .22s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin.is-active .hamburger-inner {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg);
	transition-delay: .12s;
	transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--spin.is-active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top .1s ease-out, opacity .1s .12s ease-out
}

.hamburger--spin.is-active .hamburger-inner::after {
	bottom: 0;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	transition: bottom .1s ease-out, -webkit-transform .22s .12s cubic-bezier(.215, .61, .355, 1);
	transition: bottom .1s ease-out, transform .22s .12s cubic-bezier(.215, .61, .355, 1);
	transition: bottom .1s ease-out, transform .22s .12s cubic-bezier(.215, .61, .355, 1), -webkit-transform .22s .12s cubic-bezier(.215, .61, .355, 1)
}

.icon {
	-webkit-font-smoothing: antialiased;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: -.125em;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: inherit;
	font-size: 1em;
	height: 1em
}

.icon img,
.icon svg {
	height: 1em;
	max-width: 1em;
	width: auto;
	display: block
}

.icon.fw {
	-ms-flex-pack: center;
	justify-content: center;
	width: 1.25em
}

.icon-sm {
	font-size: .9em;
	height: .9em
}

.icon-sm img,
.icon-sm svg {
	height: .9em;
	max-width: .9em
}

.icon-media {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	height: 3.75rem
}

.icon-media img,
.icon-media svg {
	max-height: 100%;
	width: auto
}

.icon-media--sm {
	height: 3.125rem
}

.btn {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none !important
}

.btn::before {
	content: "";
	display: inline-block;
	width: 2rem;
	height: 2rem;
	border: .25em solid currentColor;
	border-right-color: transparent !important;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -1rem;
	margin-left: -1rem;
	opacity: 0;
	visibility: hidden
}

.btn .icon+* {
	margin-left: .8rem
}

.btn.is-loading {
	color: transparent !important
}

.btn.is-loading::before {
	opacity: 1;
	visibility: visible
}

.btn-wide {
	text-align: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 180px
}

.btn-block {
	text-align: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -ms-flexbox;
	display: flex
}

.btn-twitter {
	padding: .35em .8em;
	font-size: .875rem;
	font-weight: 300;
	background-color: #1da1f2;
	color: #fff
}

.btn-twitter:hover {
	background-color: #0d8cda;
	color: #fff
}

.btn-xs {
	padding: .35rem 1.5rem;
	font-size: .875rem
}

.btn .-btn-debug {
	font-size: 70%;
	white-space: nowrap;
	padding: .2em .5em;
	border-radius: 4px;
	background-color: #3952af;
	color: #fff;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 5;
	font-weight: 400
}

.btn-toggle input[type=checkbox],
.btn-toggle input[type=radio] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.btn-primary::before {
	border-color: #1e2022
}

.btn-secondary::before {
	border-color: #fff
}

.btn-success::before {
	border-color: #fff
}

.btn-info::before {
	border-color: #fff
}

.btn-warning::before {
	border-color: #1e2022
}

.btn-danger::before {
	border-color: #fff
}

.btn-light::before {
	border-color: #1e2022
}

.btn-dark::before {
	border-color: #fff
}

.btn-cheddar::before {
	border-color: #1e2022
}

.btn-whales::before {
	border-color: #fff
}

.btn-super::before {
	border-color: #1e2022
}

@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.btn-group {
	margin-left: -.6rem;
	margin-right: -.6rem
}

.btn-group .btn {
	margin: .4rem .6rem
}

.icon-link {
	color: inherit;
	font-size: 1.25rem;
	font-weight: 600
}

.icon-link:active,
.icon-link:focus {
	outline: 0
}

.link-button {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 600;
	color: inherit;
	position: relative;
	transition: all .2s
}

.link-button *+* {
	margin-left: .8rem
}

.link-button-underline {
	line-height: 1.2
}

.link-button-underline:after {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	top: 100%;
	width: 100%;
	max-width: 100%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	border-bottom: 1px solid #2f3249;
	transition: all .2s
}

.link-button-underline:hover:after {
	max-width: 0
}

.link-button:hover {
	opacity: .7;
	color: inherit
}

.link {
	color: inherit;
	text-decoration: none;
	transition: background-size .2s ease;
	background-image: linear-gradient(0deg, rgba(57, 82, 175, .2) 0, rgba(57, 82, 175, .2) 100%);
	background-position: center bottom;
	background-size: 100% 0;
	background-repeat: no-repeat;
	border-bottom: 1px solid #3952af
}

.link:active,
.link:focus,
.link:hover {
	background-size: 100% 100%;
	text-decoration: none;
	color: inherit
}

.btn-icon {
	width: calc(1.2em + 1.625rem + 2px);
	height: calc(1.2em + 1.625rem + 2px);
	padding: 0;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

.btn-icon .icon {
	font-size: 1rem
}

.btn-icon.btn-xs {
	width: calc(.075em + 1.4375rem + 2px);
	height: calc(.075em + 1.4375rem + 2px)
}

.btn-icon.btn-xs .icon {
	font-size: .75rem
}

.btn-icon.btn-sm {
	width: calc(.9em + 1.4375rem + 2px);
	height: calc(.9em + 1.4375rem + 2px)
}

.btn-icon.btn-sm .icon {
	font-size: .75rem
}

.btn-icon.btn-lg {
	width: calc(1.5em + 1.9375rem + 2px);
	height: calc(1.5em + 1.9375rem + 2px)
}

.btn-icon.btn-lg .icon {
	font-size: 1.25rem
}

.btn-icon.btn-outline-light {
	border-color: rgba(255, 255, 255, .12)
}

.open-button {
	width: 2.5rem;
	height: 2.5rem;
	border: none;
	border-radius: 3px;
	background-color: #5dd39e;
	transition: all .2s;
	background-image: url(../images/icons/icon-plus.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 40% 40%
}

.open-button:hover {
	background-color: #36c786
}

.open-button:active,
.open-button:focus {
	outline: 0
}

.text-formatted {
	line-height: 1.8;
	font-size: 1.125rem;
	border-radius: 4px
}

@media (max-width:767.98px) {
	.text-formatted {
		font-size: 1rem
	}
}

.text-formatted-text {
	width: 910px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto
}

.text-formatted * {
	line-height: inherit
}

.text-formatted .section-heading {
	margin-bottom: 2rem !important
}

.text-formatted .section-heading header {
	margin-bottom: .8rem !important
}

.text-formatted .section-heading-label {
	margin-bottom: .6rem
}

.text-formatted .section-heading-title {
	margin-top: 0
}

.text-formatted .entry-footer {
	padding-top: 1.5rem
}

.text-formatted h1,
.text-formatted h2,
.text-formatted h3,
.text-formatted h4,
.text-formatted h5,
.text-formatted h6 {
	line-height: 1.25;
	margin-bottom: 1.2rem;
	margin-top: 3rem;
	font-family: "Source Sans Pro", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700
}

.text-formatted h1:last-child,
.text-formatted h2:last-child,
.text-formatted h3:last-child,
.text-formatted h4:last-child,
.text-formatted h5:last-child,
.text-formatted h6:last-child {
	margin-bottom: 0
}

.text-formatted>h1,
.text-formatted>h2,
.text-formatted>h3,
.text-formatted>h4,
.text-formatted>h5,
.text-formatted>h6 {
	margin-top: 0
}

.text-formatted>div:first-child h1,
.text-formatted>div:first-child h2,
.text-formatted>div:first-child h3,
.text-formatted>div:first-child h4,
.text-formatted>div:first-child h5,
.text-formatted>div:first-child h6 {
	margin-top: 0
}

.text-formatted p {
	margin-bottom: 1.4rem
}

.text-formatted p:last-child {
	margin-bottom: 0
}

.text-formatted a:not(.btn) {
	color: inherit;
	text-decoration: none;
	transition: background-size .2s ease;
	background-image: linear-gradient(0deg, rgba(57, 82, 175, .2) 0, rgba(57, 82, 175, .2) 100%);
	background-position: center bottom;
	background-size: 100% 0;
	background-repeat: no-repeat;
	border-bottom: 1px solid #3952af
}

.text-formatted a:not(.btn):active,
.text-formatted a:not(.btn):focus,
.text-formatted a:not(.btn):hover {
	background-size: 100% 100%
}

.text-formatted img {
	height: auto;
	max-width: 100%
}

.text-formatted .aligncenter,
.text-formatted .alignnone {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.text-formatted .alignright {
	float: right;
	margin-left: 2rem;
	margin-bottom: 1rem
}

@media (max-width:575.98px) {
	.text-formatted .alignright {
		float: none;
		display: block;
		margin: 1rem auto;
		width: 100%
	}
}

.text-formatted .alignleft {
	float: left;
	margin-right: 2rem;
	margin-bottom: 1rem
}

@media (max-width:575.98px) {
	.text-formatted .alignleft {
		float: none;
		display: block;
		margin: 1rem auto;
		width: 100%
	}
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu) {
	list-style-type: none;
	padding-left: 2rem;
	margin-bottom: 1.6rem;
	list-style-position: inside;
	line-height: 1.8
}

@media (max-width:575.98px) {
	.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu) {
		padding-left: 1rem
	}
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu)+* {
	margin-top: 1.4rem
}

@media (min-width:1200px) {
	.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu)+* {
		margin-top: 1.8rem
	}
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu)>li {
	display: block;
	position: relative;
	padding-left: 2em
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu)>li:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: .9em;
	background-color: #2e843f;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu)>li+li {
	margin-top: .5em
}

.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu) ol,
.text-formatted ul:not(.list-reset):not(.list-block):not(.list-steps):not(.list-check):not(.list-inline):not(.body-menu) ul {
	margin-top: .6rem !important;
	margin-bottom: .2rem !important
}

.text-formatted ol:not([type]) {
	counter-reset: section;
	list-style-type: none;
	padding-left: 2em;
	line-height: 1.8
}

@media (max-width:575.98px) {
	.text-formatted ol:not([type]) {
		padding-left: 1em
	}
}

.text-formatted ol:not([type])>li {
	position: relative;
	padding-left: 2.2em
}

.text-formatted ol:not([type])>li+li {
	margin-top: .5em
}

.text-formatted ol:not([type])>li:before {
	width: 1.6em;
	height: 1.6em;
	font-weight: 700;
	color: #2e843f;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	counter-increment: section;
	position: absolute;
	left: 0;
	top: .2em;
	content: counters(section, ".") "."
}

.text-formatted ol:not([type]) ol,
.text-formatted ol:not([type]) ul {
	margin-top: .6rem !important;
	margin-bottom: .2rem !important
}

.text-formatted ol li::marker {
	font-weight: 600;
	color: #5dd39e;
	font-size: 1rem
}

.text-formatted ol li+li {
	margin-top: .5em
}

.text-formatted figure {
	margin: 3rem auto
}

.text-formatted figure img {
	max-width: 100%;
	height: auto !important
}

.text-formatted iframe {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%
}

.text-mark {
	padding: 5rem 4.6rem 4rem 4.6rem;
	margin-bottom: 3rem;
	margin-top: 3rem;
	background-color: #f7f7f7;
	border-radius: 10px;
	position: relative;
	padding-right: 3rem
}

@media (max-width:1479.98px) {
	.text-mark {
		padding-left: 3rem
	}
}

@media (max-width:575.98px) {
	.text-mark {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 3rem
	}
}

.text-mark>:first-child {
	margin-top: 0 !important
}

.text-mark .wp-block-group__inner-container>:first-child {
	margin-top: 0 !important
}

.text-mark .wp-block-group__inner-container>:last-child {
	margin-bottom: 0 !important
}

.text-mark:before {
	content: "";
	display: block;
	position: absolute;
	left: 4.6rem;
	top: 0;
	width: 2.4rem;
	height: 2.4rem;
	background-size: 100% 100%;
	background-image: url("data:image/svg+xml,%3Csvg height='404pt' viewBox='-58 0 404 404.54135' width='404pt' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2328d4b1' d='m277.527344 0h-267.257813c-5.523437 0-10 4.476562-10 10v374.527344c-.011719 7.503906 4.183594 14.378906 10.855469 17.804687 6.675781 3.429688 14.707031 2.832031 20.796875-1.550781l111.976563-80.265625 111.976562 80.269531c6.097656 4.367188 14.121094 4.960938 20.792969 1.535156 6.667969-3.425781 10.863281-10.292968 10.863281-17.792968v-374.527344c0-5.523438-4.480469-10-10.003906-10zm0 0'/%3E%3C/svg%3E")
}

@media (max-width:1479.98px) {
	.text-mark:before {
		left: 3rem
	}
}

@media (max-width:767.98px) {
	.text-mark:before {
		width: 2rem;
		height: 2rem
	}
}

@media (max-width:575.98px) {
	.text-mark:before {
		left: 2rem
	}
}

blockquote {
	position: relative;
	padding-left: 6.25rem;
	margin: 3.2rem auto !important;
	font-size: 1.375rem;
	font-weight: 700;
	line-height: 1.7;
	text-align: left
}

@media (max-width:767.98px) {
	blockquote {
		padding-left: 3.6rem
	}
}

@media (max-width:575.98px) {
	blockquote {
		font-size: 1.125rem
	}
}

blockquote:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 8px;
	width: 2.5rem;
	height: 2.5rem;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='35' viewBox='0 0 40 35'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%235779D1' d='M22.82.02v17.442h11.409c0 6.41-5.118 11.627-11.409 11.627v5.814c9.437 0 17.113-7.824 17.113-17.441V.02z'/%3E%3C/g%3E%3Cg%3E%3Cpath fill='%235779D1' d='M0 17.462h11.409c0 6.41-5.118 11.627-11.409 11.627v5.814c9.437 0 17.113-7.824 17.113-17.441V.02H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

@media (max-width:767.98px) {
	blockquote:before {
		width: 1.6rem;
		height: 1.6rem
	}
}

.text-summury {
	border-radius: 10px;
	background-color: #f7f7f7;
	position: relative;
	padding: 3rem 4.6rem 3rem 4.6rem;
	margin-bottom: 3rem;
	margin-top: 3rem
}

n {
	padding-right: 3rem
}

@media (max-width:1479.98px) {
	n {
		padding-left: 3rem
	}
}

@media (max-width:575.98px) {
	n {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 3rem
	}
}

n>:first-child {
	margin-top: 0 !important
}

n .wp-block-group__inner-container>:first-child {
	margin-top: 0 !important
}

n .wp-block-group__inner-container>:last-child {
	margin-bottom: 0 !important
}

blockquote {
	position: relative;
	padding-left: 6.25rem;
	font-size: 1.375rem;
	font-weight: 700;
	line-height: 1.7;
	margin: 3.2rem auto !important;
	text-align: left
}

@media (max-width:767.98px) {
	blockquote {
		padding-left: 3.6rem
	}
}

@media (max-width:575.98px) {
	blockquote {
		font-size: 1.125rem
	}
}

blockquote:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 8px;
	width: 2.5rem;
	height: 2.5rem;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='35' viewBox='0 0 40 35'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%2328d4b1' d='M22.82.02v17.442h11.409c0 6.41-5.118 11.627-11.409 11.627v5.814c9.437 0 17.113-7.824 17.113-17.441V.02z'/%3E%3C/g%3E%3Cg%3E%3Cpath fill='%2328d4b1' d='M0 17.462h11.409c0 6.41-5.118 11.627-11.409 11.627v5.814c9.437 0 17.113-7.824 17.113-17.441V.02H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

@media (max-width:767.98px) {
	blockquote:before {
		width: 1.6rem;
		height: 1.6rem
	}
}

.blocks-gallery-caption,
.wp-block-image figcaption {
	display: block !important;
	font-size: 85%;
	text-align: center;
	margin-top: .5rem !important
}

.blocks-gallery-grid {
	padding-left: 0 !important;
	margin-bottom: 0 !important
}

.blocks-gallery-item {
	padding-left: 0 !important
}

.blocks-gallery-item:before {
	display: none !important
}

.text-formatted .aligncenter,
.text-formatted .alignnone {
	text-align: center
}

.ts-price del {
	color: currentColor;
	opacity: .65;
	font-size: 80%;
	margin-right: .25em;
	-webkit-text-decoration-color: #f26868;
	text-decoration-color: #f26868;
	font-weight: 400
}

.ts-price ins {
	text-decoration: none
}

.section-heading:not(:last-child) {
	margin-bottom: 3rem
}

.section-heading header {
	position: relative
}

@media (max-width:1199.98px) {
	.section-heading header.placeholder {
		display: none
	}
}

.section-heading header:not(:last-child) {
	margin-bottom: 1.4rem
}

.section-heading footer {
	margin-top: 1.4rem
}

.section-heading hr {
	border-color: currentColor;
	opacity: .1
}

.section-heading-icon {
	display: block;
	margin-bottom: 2rem
}

.section-heading-label {
	text-transform: uppercase;
	letter-spacing: .1em;
	color: #5dd39e;
	font-size: .875rem;
	font-weight: 700;
	margin-bottom: .25rem
}

.section-heading-label.placeholder {
	color: transparent !important;
	visibility: hidden
}

@media (max-width:1479.98px) {
	.section-heading-label.placeholder {
		display: none
	}
}

.section-heading-title {
	margin-bottom: 0
}

@media (max-width:1479.98px) {
	.section-heading-title.display-3 {
		font-size: 4rem
	}
}

@media (max-width:1479.98px) and (max-width:1200px) {
	.section-heading-title.display-3 {
		font-size: calc(1.525rem + 3.3vw)
	}
}

@media (max-width:1199.98px) {
	.section-heading-title br {
		display: none
	}
}

.section-heading-title.display-3 small,
.section-heading-title.display-4 small {
	font-size: 70%
}

.section-heading-title.placeholder {
	color: transparent !important;
	visibility: hidden
}

@media (max-width:1479.98px) {
	.section-heading-title.placeholder {
		display: none
	}
}

@media (min-width:768px) {
	.section-heading-subtitle {
		font-size: 1.125rem
	}
}

.section-heading-subtitle p:last-child {
	margin-bottom: 0
}

.section-heading-subtitle .text-xl,
.section-heading-subtitle .text-xl p,
.section-heading-subtitle.text-xl,
.section-heading-subtitle.text-xl p {
	line-height: 1.2
}

@media (max-width:1199.98px) {

	.section-heading-subtitle .text-xl,
	.section-heading-subtitle .text-xl p,
	.section-heading-subtitle.text-xl,
	.section-heading-subtitle.text-xl p {
		font-size: 1.25rem !important
	}
}

@media (max-width:767.98px) {

	.section-heading-subtitle .text-xl,
	.section-heading-subtitle .text-xl p,
	.section-heading-subtitle.text-xl,
	.section-heading-subtitle.text-xl p {
		font-size: 1.125rem !important
	}
}

@media (max-width:1199.98px) {

	.section-heading-subtitle .text-lg,
	.section-heading-subtitle .text-lg p,
	.section-heading-subtitle.text-lg,
	.section-heading-subtitle.text-lg p {
		font-size: 1.125rem !important
	}
}

@media (max-width:767.98px) {

	.section-heading-subtitle .text-lg,
	.section-heading-subtitle .text-lg p,
	.section-heading-subtitle.text-lg,
	.section-heading-subtitle.text-lg p {
		font-size: 1rem !important
	}
}

@media (max-width:1199.98px) {

	.section-heading-subtitle .text-md,
	.section-heading-subtitle .text-md p,
	.section-heading-subtitle.text-md,
	.section-heading-subtitle.text-md p {
		font-size: 1rem !important
	}
}

.section-heading-subtitle ul:not(.body-menu) {
	list-style-type: none;
	padding: 0;
	line-height: 1.8
}

.section-heading-subtitle ul:not(.body-menu) li {
	display: block;
	position: relative;
	margin-bottom: 1rem
}

@media (max-width:991.98px) {
	.section-heading-subtitle ul:not(.body-menu) li {
		padding-left: 1.4rem
	}
}

.section-heading-subtitle ul:not(.body-menu) li:last-child {
	margin-bottom: 0
}

.section-heading-subtitle ul:not(.body-menu) li:before {
	content: "";
	display: block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #5dd39e;
	position: absolute;
	left: -1.3rem;
	top: .8em
}

@media (max-width:991.98px) {
	.section-heading-subtitle ul:not(.body-menu) li:before {
		left: 0
	}
}

.section-heading-subtitle ul:not(.body-menu):not(:last-child) {
	margin-bottom: 1rem
}

.body-menu {
	font-size: 1rem;
	font-weight: 300;
	margin: 0 -.25rem -.5rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: center;
	justify-content: center
}

@media (max-width:575.98px) {
	.body-menu {
		font-size: .825rem
	}
}

.body-menu li {
	padding: 0 .25rem;
	position: relative;
	margin-bottom: .5rem
}

.body-menu a,
.body-menu span {
	padding: .25em .75em;
	position: relative;
	display: block;
	color: inherit;
	z-index: 1;
	white-space: nowrap
}

.body-menu a::before,
.body-menu span::before {
	content: "";
	width: 100%;
	height: 100%;
	background-color: currentColor;
	opacity: .05;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: .35em;
	z-index: -1;
	transition: all .15s ease
}

.body-menu a:hover,
.body-menu span:hover {
	text-decoration: none
}

.body-menu a:hover::before,
.body-menu span:hover::before {
	opacity: .15
}

.secondary-menu {
	--gap: 1.5rem;
	font-size: 1.25rem;
	font-weight: 700;
	position: relative
}

@media (max-width:991.98px) {
	.secondary-menu {
		font-size: 1rem
	}
}

.secondary-menu ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0;
	max-width: 100%;
	overflow-x: auto;
	scrollbar-width: none
}

.secondary-menu ul::-webkit-scrollbar {
	width: 0;
	height: 0
}

.secondary-menu ul::-webkit-scrollbar-track {
	background-color: transparent
}

.secondary-menu ul::-webkit-scrollbar-thumb {
	background-color: #eff4f9;
	border-radius: 1rem
}

.secondary-menu ul li {
	margin-right: var(--gap)
}

.secondary-menu ul li:last-child {
	margin-right: 0
}

.secondary-menu li {
	white-space: nowrap
}

.secondary-menu a {
	display: block;
	padding: 1em 0;
	color: inherit;
	border-bottom: 3px solid transparent
}

@media (max-width:991.98px) {
	.secondary-menu a {
		padding: .75em 0;
		border-bottom-width: 2px
	}
}

.secondary-menu--badges {
	line-height: 1.5
}

.secondary-menu--badges a {
	padding: .35em 1.25em;
	border-radius: 5rem;
	border: 0;
	background-color: #f6f9ff;
	transition: all .3s ease
}

.secondary-menu--badges a:hover {
	background-color: #dde8ff
}

.secondary-menu.has-scroll-button {
	padding-right: 2.5rem
}

.secondary-menu li.is-active a {
	border-bottom-color: #5dd39e
}

.secondary-menu--badges li.is-active a {
	background-color: #2f3249;
	color: #fff
}

.secondary-menu-scroll {
	position: absolute;
	top: 0;
	right: -.85rem;
	bottom: .5rem;
	width: 3rem;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 20%, #fff 100%);
	background-color: transparent;
	border: 0;
	outline: 0 !important
}

@media (max-width:991.98px) {
	.secondary-menu-scroll {
		right: -.75rem
	}
}

.secondary-menu-arrow {
	width: 2rem;
	border-radius: 0 1rem 1rem 0;
	background-color: #f6f9ff;
	padding: 0;
	margin: 0;
	display: none;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	border: 0;
	box-shadow: 0 0 2px 0 rgba(47, 50, 73, .1);
	position: absolute;
	top: .75rem;
	bottom: .75rem;
	left: -1rem
}

.secondary-menu-arrow.-right {
	left: auto;
	right: -1rem;
	border-radius: 1rem 0 0 1rem
}

.secondary-menu.-is-overflow .secondary-menu-arrow {
	display: -ms-flexbox;
	display: flex
}

.secondary-menu.-is-overflow {
	position: relative
}

.secondary-menu.-is-overflow ul {
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-left: 1.5rem !important;
	padding-right: 1.5rem !important
}

:root {
	--platform-sidebar-width: 200px
}

.platform-sidebar-menu {
	position: absolute;
	top: 0;
	padding-top: 1rem;
	bottom: 0;
	left: 0;
	width: var(--platform-sidebar-width)
}

@media (max-width:1789.9px) {
	.platform-sidebar-menu {
		display: none
	}
}

.platform-sidebar-menu-inner {
	position: -webkit-sticky;
	position: sticky;
	z-index: 10;
	top: 1rem;
	max-height: calc(100vh - 2rem);
	overflow: auto;
	background-color: #fff;
	border-radius: 0 6px 6px 0;
	scrollbar-color: #2f3249 rgba(47, 50, 73, .05);
	scrollbar-width: thin
}

.platform-sidebar-menu-inner::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: rgba(47, 50, 73, .05)
}

.platform-sidebar-menu-inner::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(47, 50, 73, .05)
}

.platform-sidebar-menu-inner::-webkit-scrollbar-thumb {
	background-color: #5dd39e;
	border: none
}

.platform-sidebar-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 1.125rem
}

.platform-sidebar-menu a {
	display: block;
	padding: .5em 1.75em;
	font-weight: 600;
	color: #2f3249;
	border-radius: 0 6px 6px 0;
	transition: background-color .15s ease;
	white-space: nowrap
}

.platform-sidebar-menu a:hover {
	background-color: #d8e0f8
}

.platform-sidebar-menu a.-pricing {
	background-color: #5dd39e;
	color: #1e2022;
	padding: .65rem .5rem;
	font-size: .925rem;
	text-align: center;
	-ms-flex-pack: center;
	justify-content: center
}

.platform-sidebar-menu a.-pricing:hover {
	background-color: #3dca8b
}

.platform-sidebar-menu .is-active a {
	background-color: #2f3249;
	color: #fff
}

.platform-top-sticky-menu {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 5;
	box-shadow: 0 0 6px 0 rgba(47, 50, 73, .15)
}

.platform-top-sticky-menu .secondary-menu {
	padding: .75rem 0
}

@media (min-width:1790px) {
	.platform-top-sticky-menu .secondary-menu {
		display: none
	}
}

@media (max-width:1199.98px) {
	.platform-top-sticky-menu .container {
		max-width: 100%
	}
}

.card,
.metabox {
	border: none;
	border-radius: .6875rem;
	background-color: #f8fafc;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
	position: relative
}

.card p:last-child,
.metabox p:last-child {
	margin-bottom: 0
}

.card-body {
	max-width: 100%
}

.card p a,
.metabox p a {
	color: inherit;
	text-decoration: none;
	position: relative;
	transition: background-size .2s ease;
	background-image: linear-gradient(0deg, rgba(57, 82, 175, .2) 0, rgba(57, 82, 175, .2) 100%);
	background-position: center bottom;
	background-size: 100% 0;
	background-repeat: no-repeat;
	border-bottom: 1px solid #3952af
}

.card p a:hover,
.metabox p a:hover {
	background-size: 100% 100%
}

.card ul,
.metabox ul {
	position: relative;
	z-index: 20
}

.card ul i.icon,
.metabox ul i.icon {
	-webkit-transform: translateY(.15em);
	transform: translateY(.15em)
}

.card-light {
	background-color: #fff
}

@media (max-width:575.98px) {
	.card-product .card-body {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: start;
		align-items: flex-start;
		font-size: .875rem
	}

	.card-product .card-body .icon-media {
		width: 3.25rem;
		margin-right: 1rem;
		-ms-flex-negative: 0;
		flex-shrink: 0
	}
}

.card-feature {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start
}

@media (min-width:1200px) {
	.card-feature {
		max-width: 390px
	}
}

.card-feature-icon {
	width: 50px;
	text-align: center;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

@media (max-width:767.98px) {
	.card-feature-icon {
		width: 45px
	}
}

.card-feature-icon img {
	max-width: 100%;
	margin: 0 auto
}

.card-feature-content {
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-left: 2.25rem
}

@media (max-width:1479.98px) {
	.card-feature-content {
		padding-left: 2rem
	}
}

@media (max-width:767.98px) {
	.card-feature-content {
		padding-left: 1rem
	}
}

.card-feature-content p:last-child {
	margin-bottom: 0
}

.card-feature-content h3 {
	line-height: 1.15
}

.card-feature--full-width,
.card-feature.-full-width {
	max-width: 100% !important
}

.card-feature--vertical,
.card-feature.-vertical {
	display: block
}

.card-feature--vertical .card-feature-icon,
.card-feature.-vertical .card-feature-icon {
	margin-bottom: .75rem;
	width: auto;
	height: 2.5rem;
	text-align: left
}

.card-feature--vertical .card-feature-icon--lg,
.card-feature.-vertical .card-feature-icon--lg {
	height: 3.5rem
}

.card-feature--vertical .card-feature-icon img,
.card-feature.-vertical .card-feature-icon img {
	max-height: 100%
}

.card-feature--vertical .card-feature-content,
.card-feature.-vertical .card-feature-content {
	padding-left: 0
}

.card-feature--center {
	text-align: center
}

.card-feature--center .card-feature-icon {
	text-align: center
}

.card-feature--link,
.card-feature--link:hover {
	color: inherit
}

.card-feature--link .card-feature-content {
	line-height: 1.3
}

.card-feature--link .card-feature-content h3 {
	position: relative;
	display: inline-block
}

.card-feature--link .card-feature-content h3::after {
	content: "";
	width: 10px;
	height: 10px;
	position: absolute;
	left: 100%;
	top: 58%;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' %3E%3Cpath fill='currentColor' d='M190.5 66.9l22.2-22.2a23.9 23.9 0 0133.9 0L441 239a23.9 23.9 0 010 33.9L246.6 467.3a23.9 23.9 0 01-33.9 0l-22.2-22.2a24 24 0 01.4-34.3L311.4 296H24a24 24 0 01-24-24v-32a24 24 0 0124-24h287.4L190.9 101.2a23.9 23.9 0 01-.4-34.3z'/%3E%3C/svg%3E");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	opacity: 0;
	transition: all .3s ease
}

.card-feature--link:hover .card-feature-content h3:after {
	-webkit-transform: translate(100%, -50%);
	transform: translate(100%, -50%);
	opacity: 1
}

.card-feature--link .card-feature-content .text-muted {
	transition: all .3s ease
}

.card-feature--link:hover .card-feature-content .text-muted {
	color: #2f3249 !important
}

.card-feature.-fill {
	background-color: #f8fafc;
	border-radius: 10px;
	position: relative;
	padding: 65px 70px 50px;
	transition: all .25s ease
}

@media (max-width:1479.98px) {
	.card-feature.-fill {
		padding: 50px 50px 35px
	}
}

@media (max-width:1199.98px) {
	.card-feature.-fill {
		padding: 50px 40px 30px
	}
}

@media (max-width:575.98px) {
	.card-feature.-fill {
		padding: 50px 2rem 1.5rem
	}
}

.card-feature.-fill:hover {
	background-color: #eaf0ff
}

.card-feature.-fill .card-feature-icon {
	height: 60px;
	position: absolute;
	top: -30px
}

.card-feature.-fill .card-feature-content {
	line-height: 1.41
}

.features-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: calc(2rem + 30px) 2rem
}

@media (max-width:991.98px) {
	.features-grid {
		grid-template-columns: 1fr
	}
}

.card-post-thumbnail {
	position: relative;
	margin: 0;
	width: 100%;
	background-color: #ededed
}

.card-post-thumbnail::before {
	content: "";
	width: 100%;
	padding-top: 53%;
	display: block
}

.card-post-thumbnail img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-position: center;
	object-position: center;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 5px
}

.card-post-thumbnail-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 6rem;
	height: auto;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: .45
}

.card-post-body {
	background: #f8fafc;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 1.75rem 2.25rem;
	-ms-flex: 1;
	flex: 1;
	width: 100%
}

@media (max-width:575.98px) {
	.card-post-body {
		padding: 1rem 1.5rem;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px
	}
}

.card-post-categories {
	font-size: .75rem;
	color: #50575e;
	text-transform: uppercase;
	margin-bottom: .5em
}

.card-post-title {
	font-size: 1.5rem;
	line-height: 1.3125;
	color: inherit
}

@media (max-width:1200px) {
	.card-post-title {
		font-size: calc(1.275rem + .3vw)
	}
}

.card-post-title:last-child {
	margin-bottom: 0
}

.card-post-title a {
	color: inherit;
	text-decoration: none;
	transition: background-size .2s ease;
	background-image: linear-gradient(0deg, rgba(47, 50, 73, .3) 0, rgba(47, 50, 73, .3) 100%);
	background-position: center bottom;
	background-size: 100% 0;
	background-repeat: no-repeat;
	border-bottom: 2px solid #2f3249
}

.card-post-title a:hover {
	background-size: 100% 100%
}

.blog-categories {
	height: 0;
	min-height: 0;
	opacity: 0;
	transition: all .3s;
	overflow: hidden
}

.blog-categories-body {
	opacity: 0;
	height: 0;
	overflow: hidden;
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	transition: .6s opacity .2s ease-out, .3s -webkit-transform .3s ease-out;
	transition: .3s transform .3s ease-out, .6s opacity .2s ease-out;
	transition: .3s transform .3s ease-out, .6s opacity .2s ease-out, .3s -webkit-transform .3s ease-out
}

.blog-categories:not(.active) {
	padding: 0 !important
}

.blog-categories.active {
	opacity: 1;
	min-height: 200px;
	height: auto
}

.blog-categories.active .blog-categories-body {
	opacity: 1;
	height: auto;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.card-testimonial {
	border-radius: 10px;
	background-color: #fff;
	color: #0b103f;
	padding: 4rem 3rem 4rem;
	display: block;
	text-align: center
}

@media (max-width:575.98px) {
	.card-testimonial {
		padding: 2.5rem 2rem
	}
}

.card-testimonial .testimonial-body {
	font-size: 1.125rem;
	position: relative;
	overflow: hidden
}

@media (max-width:575.98px) {
	.card-testimonial .testimonial-body {
		font-size: 1rem
	}
}

.card-testimonial .testimonial-author {
	font-size: 1.25rem;
	font-weight: 700;
	margin-top: .25rem
}

a.card-testimonial {
	transition: all .2s
}

a.card-testimonial:hover {
	color: #0b103f
}

a.card-testimonial:hover .testimonial-author {
	opacity: .6
}

a.card-testimonial-expandable:hover,
a.card-testimonial:hover {
	text-decoration: none
}

.card-testimonial-expandable {
	display: block;
	position: relative;
	color: inherit;
	position: relative;
	z-index: 1;
	border-radius: 10px;
	padding: 5rem 2.25rem;
	width: 100%;
	transition: background-color .3s ease, -webkit-transform .3s linear;
	transition: transform .3s linear, background-color .3s ease;
	transition: transform .3s linear, background-color .3s ease, -webkit-transform .3s linear;
	background-color: var(--bg-color, #fff)
}

@media (max-width:991.98px) {
	.card-testimonial-expandable {
		padding: 4rem 1.5rem 2rem
	}
}

.card-testimonial-expandable::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	opacity: .1;
	z-index: -1;
	transition: all .3s ease;
	border: 1px solid currentColor;
	border-radius: 10px
}

.card-testimonial-expandable:hover::before {
	background-color: currentColor
}

.card-testimonial-expandable .testimonial-icon {
	position: absolute;
	top: 1rem;
	right: 1rem;
	line-height: 1;
	font-size: 1.25rem
}

.card-testimonial-expandable .testimonial-author {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
	font-size: 1.25rem;
	color: inherit;
	margin-bottom: 1rem
}

@media (max-width:991.98px) {
	.card-testimonial-expandable .testimonial-author {
		font-size: 1.125rem
	}
}

.card-testimonial-expandable .testimonial-author img {
	width: 3.4375rem;
	height: 3.4375rem;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 1.25rem;
	border-radius: 50%;
	overflow: hidden
}

@media (max-width:991.98px) {
	.card-testimonial-expandable .testimonial-author img {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem
	}
}

.card-testimonial-expandable .testimonial-author div {
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow: hidden
}

.card-testimonial-expandable .testimonial-author div span {
	display: block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.card-testimonial-expandable .testimonial-author div small {
	display: block;
	line-height: 1.25;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.card-testimonial-expandable .testimonial-body {
	--font-size: 1.125rem;
	--line-height: 1.44;
	--lines: 5;
	transition: height .3s linear;
	will-change: height;
	display: block;
	display: -webkit-box;
	font-size: var(--font-size);
	line-height: var(--line-height);
	height: calc(var(--font-size) * var(--line-height) * var(--lines));
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: var(--lines);
	-webkit-box-orient: vertical
}

@media (max-width:767.98px) {
	.card-testimonial-expandable .testimonial-body {
		--font-size: 1rem
	}
}

.card-testimonial-expandable:hover .testimonial-body {
	-webkit-line-clamp: initial
}

.card-review {
	position: relative;
	border-radius: .6125rem;
	padding-top: 100px;
	padding-bottom: 2rem;
	overflow: hidden;
	-webkit-filter: drop-shadow(0 0 20px rgba(0, 0, 0, .06));
	filter: drop-shadow(0 0 20px rgba(0, 0, 0, .06));
	z-index: 1;
	--box-shadow-size: 5000px;
	--box-shadow-color: #ffffff
}

@media (max-width:767.98px) {
	.card-review {
		padding: 65px 0 1.5rem
	}
}

.card-review::before {
	content: "";
	width: 90px;
	height: 90px;
	background-color: transparent;
	box-shadow: 0 0 0 var(--box-shadow-size) var(--box-shadow-color);
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -40%);
	transform: translate(-50%, -40%);
	border-radius: 50%;
	z-index: -1;
	background-position: 50% 60%;
	background-repeat: no-repeat;
	background-size: 1.75rem
}

@media (max-width:767.98px) {
	.card-review::before {
		width: 72px;
		height: 72px;
		background-size: 1.25rem
	}
}

.card-review--link:before {
	background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.084 10.914a6.533 6.533 0 00-9.238 0l-5.935 5.94A6.532 6.532 0 006.53 28a6.49 6.49 0 004.614-1.904l4.9-4.899a.467.467 0 00-.332-.798h-.186a7.854 7.854 0 01-2.986-.578.467.467 0 00-.509.102l-3.523 3.528a2.801 2.801 0 11-3.961-3.962l5.958-5.953a2.8 2.8 0 013.957 0 1.913 1.913 0 002.622 0c.317-.317.51-.738.541-1.185a1.866 1.866 0 00-.541-1.437z' fill='%232F3249'/%3E%3Cpath d='M26.084 1.913a6.532 6.532 0 00-9.238 0l-4.894 4.89a.467.467 0 00.34.798h.173a7.838 7.838 0 012.981.583.466.466 0 00.509-.102l3.513-3.51a2.801 2.801 0 013.962 3.962l-4.377 4.372-.037.042-1.53 1.521a2.8 2.8 0 01-3.957 0 1.913 1.913 0 00-2.623 0 1.866 1.866 0 000 2.632c.54.542 1.172.984 1.867 1.306.098.047.196.084.294.126.098.042.2.075.298.112.098.037.2.07.299.098l.275.075c.187.046.373.084.565.116.23.034.462.056.695.066H15.553l.28-.033c.103-.005.21-.028.332-.028h.158l.322-.047.15-.028.27-.056h.052a6.532 6.532 0 003.028-1.717l5.94-5.94a6.532 6.532 0 000-9.238z' fill='%232F3249'/%3E%3C/svg%3E")
}

.card-review--quote::before {
	background-image: url("data:image/svg+xml,%3Csvg width='25' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.286.174v10.87h7.143c0 3.995-3.204 7.246-7.143 7.246v3.623c5.909 0 10.715-4.876 10.715-10.87V.173H14.286zM0 11.043h7.143c0 3.996-3.204 7.247-7.143 7.247v3.623c5.908 0 10.714-4.876 10.714-10.87V.173H0v10.87z' fill='%232F3249'/%3E%3C/svg%3E")
}

.card-review--facebook::before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%232F3249' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'%3E%3C/path%3E%3C/svg%3E");
	background-size: auto 1.75rem
}

@media (max-width:767.98px) {
	.card-review--facebook::before {
		background-size: auto 1.25rem
	}
}

.card-review--twitter::before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%232F3249' d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'%3E%3C/path%3E%3C/svg%3E");
	background-size: auto 1.75rem
}

@media (max-width:767.98px) {
	.card-review--twitter::before {
		background-size: auto 1.25rem
	}
}

.card-review--dark,
.card-review--facebook,
.card-review--quote {
	--box-shadow-color: #2F3249;
	color: #fff
}

.card-review-body {
	padding: 0 2rem;
	overflow: hidden;
	background-color: var(--box-shadow-color)
}

@media (max-width:767.98px) {
	.card-review-body {
		padding: 0 1rem
	}
}

@media (max-width:767.98px) {
	.card-review-body img {
		max-width: 100%;
		max-height: 100px;
		height: auto;
		width: auto
	}
}

.card-review-body .tweet {
	min-height: 160px;
	max-width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

@media (max-width:767.98px) {
	.card-review-body .tweet {
		max-width: calc(100% + 1rem);
		margin-left: -.5rem;
		margin-right: -.5rem
	}
}

.card-review-body .tweet iframe {
	max-width: 100%
}

.card-review-body .tweet .spinner-border {
	color: #1da1f2;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -1rem;
	margin-left: -1rem
}

.card-review-body .tweet.is-loaded .spinner-border {
	display: none
}

.card-review-body .tweet .twitter-tweet {
	margin: 0 !important
}

.card-review--twitter .card-review-body {
	padding: 0 1.5rem
}

@media (max-width:767.98px) {
	.card-review--twitter .card-review-body {
		padding: 0 1rem
	}
}

.card-review blockquote {
	font-size: 1.125rem;
	font-weight: inherit;
	padding: 0;
	margin: 0 !important;
	line-height: 1.77
}

@media (max-width:767.98px) {
	.card-review blockquote {
		font-size: 1rem
	}
}

.card-review blockquote::before {
	display: none
}

.card-review cite {
	font-style: normal
}

.card-review--facebook blockquote,
.card-review--quote blockquote {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.33
}

@media (max-width:1200px) {

	.card-review--facebook blockquote,
	.card-review--quote blockquote {
		font-size: calc(1.275rem + .3vw)
	}
}

.card-deck {
	--gap: 1rem;
	--columns: 3;
	display: grid;
	grid-template-columns: repeat(var(--columns), 1fr);
	grid-gap: var(--gap);
	gap: var(--gap)
}

@media (max-width:991.98px) {
	.card-deck {
		grid-template-columns: repeat(2, 1fr)
	}
}

@media (max-width:575.98px) {
	.card-deck {
		grid-template-columns: 1fr
	}
}

@media (min-width:576px) {

	.card-deck .-x2.metabox,
	.card-deck .card.-x2 {
		grid-column: span 2
	}
}

.card-deck.-is-wide {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: var(--gap);
	-ms-flex-pack: center;
	justify-content: center
}

.card-deck.-is-wide .card,
.card-deck.-is-wide .metabox {
	-ms-flex: 0 0 calc(100% - var(--gap)/ 2);
	flex: 0 0 calc(100% - var(--gap)/ 2)
}

@media (min-width:768px) {

	.card-deck.-is-wide .card,
	.card-deck.-is-wide .metabox {
		-ms-flex: 0 0 calc(33.3333333333% - var(--gap));
		flex: 0 0 calc(33.3333333333% - var(--gap))
	}

	.card-deck.-is-wide .-x2.metabox,
	.card-deck.-is-wide .card.-x2 {
		-ms-flex: 0 0 calc(66.6666666667% - var(--gap));
		flex: 0 0 calc(66.6666666667% - var(--gap))
	}
}

@media (min-width:992px) {

	.card-deck.-is-wide .card,
	.card-deck.-is-wide .metabox {
		-ms-flex: 0 0 calc(100% / var(--columns) - var(--gap) * (var(--columns) - 1)/ var(--columns));
		flex: 0 0 calc(100% / var(--columns) - var(--gap) * (var(--columns) - 1)/ var(--columns))
	}

	.card-deck.-is-wide .-x2.metabox,
	.card-deck.-is-wide .card.-x2 {
		-ms-flex: 0 0 calc(200% / var(--columns) - var(--gap) * (var(--columns) - 1)/ var(--columns));
		flex: 0 0 calc(200% / var(--columns) - var(--gap) * (var(--columns) - 1)/ var(--columns))
	}
}

.card-tweeter-feed {
	display: block;
	max-height: 360px;
	overflow: auto;
	background-color: #fff;
	scrollbar-color: #2f3249 rgba(47, 50, 73, .05);
	scrollbar-width: thin
}

.card-tweeter-feed::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: rgba(47, 50, 73, .05)
}

.card-tweeter-feed::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(47, 50, 73, .05)
}

.card-tweeter-feed::-webkit-scrollbar-thumb {
	background-color: #5dd39e;
	border: none
}

.card-tweeter-feed iframe {
	max-height: 100% !important
}

.card-event {
	margin-bottom: 2rem
}

.card-event__preview {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	background-color: #eee;
	margin-bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

.card-event__preview::before {
	content: "";
	display: block;
	padding-top: 56.111%
}

.card-event__preview::after {
	content: "";
	width: 100%;
	height: 100%;
	background-color: #2f3249;
	opacity: .1;
	transition: opacity .3s ease;
	will-change: opacity;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2
}

.card-event__preview:hover::after {
	opacity: .25
}

.card-event__preview__time {
	color: #fff;
	font-weight: 600;
	position: absolute;
	bottom: .5rem;
	right: .5rem;
	line-height: 1;
	background-color: rgba(47, 50, 73, .9);
	padding: 3px 4px;
	border-radius: 2px;
	z-index: 3
}

.card-event__preview__time.is-live {
	background-color: rgba(242, 104, 104, .9)
}

.card-event__preview__cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	opacity: 1;
	transition: opacity .3s ease;
	z-index: 1
}

.card-event__preview__play {
	z-index: 1;
	background-color: #fff;
	border: 0;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	color: #5dd39e;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	transition: color .3s ease;
	will-change: color;
	z-index: 3
}

.card-event__preview__play:hover {
	color: #31b378
}

.card-event__preview__play[data-focus-method=key]:active,
.card-event__preview__play[data-focus-method=key]:focus {
	outline: 1px solid #5dd39e !important
}

.card-event__preview__play[data-focus-method=key]:active::after,
.card-event__preview__play[data-focus-method=key]:focus::after {
	outline: 0 !important
}

.card-event__preview__play .icon {
	-webkit-transform: translateX(7.5%);
	transform: translateX(7.5%)
}

.card-event__preview__reminder {
	z-index: 3
}

.card-event.is-blank .card-event__preview__cover,
.card-event.is-blank .card-event__preview__play,
.card-event.is-blank .card-event__preview__reminder,
.card-event.is-blank .card-event__preview__time {
	opacity: 0
}

.card-event__body {
	padding-top: .75rem
}

.card-event.is-blank .card-event__date,
.card-event.is-blank .card-event__name {
	display: grid
}

.card-event.is-blank .card-event__date:after,
.card-event.is-blank .card-event__date:before,
.card-event.is-blank .card-event__name:after,
.card-event.is-blank .card-event__name:before {
	display: block;
	background-color: #eee;
	border-radius: 4px;
	line-height: 1.5;
	height: 1em;
	margin: calc(.5em * var(--line-height) - .5em) 0
}

.card-event.is-blank .card-event__date:before,
.card-event.is-blank .card-event__name:before {
	content: ""
}

.card-event.is-blank .card-event__category:before {
	width: 50%
}

.card-event.is-blank .card-event__name:before {
	width: 94%
}

.card-event.is-blank .card-event__name::after {
	content: "";
	width: 65%
}

.card-event.is-blank .card-event__date:before {
	width: 25%
}

.card-event__category,
.card-event__date {
	--line-height: 1.5;
	font-size: .875rem;
	color: #747183;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
	line-height: var(--line-height)
}

.card-event__name {
	--line-height: 1.375;
	--font-size: 1.175em;
	font-size: var(--font-size);
	font-weight: 600;
	line-height: var(--line-height);
	color: #2f3249;
	display: block;
	display: -webkit-box;
	max-height: calc(2 * var(--line-height, 1.375));
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	-webkit-line-clamp: 2
}

.card-space {
	--line-height: 1.375;
	height: 100%;
	padding: 1.25rem;
	background-image: linear-gradient(70.21deg, #2932f4 -44.57%, #9566f2 122.52%);
	color: #fff;
	border-radius: 1rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.card-space_header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 3.5rem;
	max-width: 100%
}

.card-space_header_meta {
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-right: .75em;
	width: calc(100% - 72px)
}

.card-space_header_users {
	width: 72px;
	height: 72px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	position: relative
}

.card-space_header_users img {
	display: block;
	position: absolute;
	height: auto;
	border-radius: 50%;
	border: 2px solid #fff
}

.card-space_header_users img:nth-child(n+4) {
	display: none
}

.card-space_header_users img:nth-child(1) {
	top: 0;
	right: 0;
	width: 48px;
	z-index: 4
}

.card-space_header_users img:nth-child(2) {
	top: 26px;
	right: 26px;
	width: 32px;
	z-index: 5
}

.card-space_header_users img:nth-child(3) {
	top: 0;
	right: 36px;
	width: 28px;
	z-index: 3
}

.card-space_header_meta_date {
	font-size: 1rem;
	line-height: var(--line-height);
	margin-bottom: .5em
}

.card-space_header_meta_date::before {
	width: 60%
}

.card-space_header_meta_date::after {
	display: none !important
}

.card-space_header_meta_name {
	font-size: 1.325rem;
	font-weight: 700;
	line-height: var(--line-height);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden
}

.card-space_header_meta_name::after {
	width: 80%
}

.card-space.-blank {
	background-image: none;
	background-color: #dcdcde
}

.card-space.-blank .card-space_header_meta_date:after,
.card-space.-blank .card-space_header_meta_date:before,
.card-space.-blank .card-space_header_meta_name:after,
.card-space.-blank .card-space_header_meta_name:before {
	content: "";
	display: block;
	background-color: #eee;
	border-radius: 4px;
	line-height: 1.5;
	height: 1em;
	margin: calc(.5em * var(--line-height) - .45em) 0
}

.card-space_footer {
	margin-top: auto
}

.card-space_footer_reminder {
	width: 100%;
	border: 0;
	background-color: rgba(255, 255, 255, .3);
	border-radius: 50px;
	height: 58px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	font-weight: 700;
	color: #fff;
	font-size: 1.25rem;
	transition: all .3s ease
}

.card-space_footer_reminder:hover {
	background-color: rgba(26, 26, 26, .3)
}

.card-space.-blank .card-space_footer_reminder {
	pointer-events: none
}

.card-broker {
	position: relative
}

.card-broker-image {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100px;
	width: 100%;
	margin: 0;
	padding: .5em 0;
	border-bottom: 1px solid #e1e1e4
}

.card-broker-image img {
	max-width: 100%;
	max-height: 100%;
	height: auto;
	transition: all .15s ease
}

.card-broker:hover .card-broker-image img {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	-webkit-filter: saturate(1.4);
	filter: saturate(1.4)
}

.card-broker-link {
	border-top: 1px solid transparent;
	padding-top: 1em;
	text-align: center;
	font-size: 1.125rem;
	line-height: 1
}

@media (max-width:767.98px) {
	.card-broker-link {
		font-size: 1rem
	}
}

.card-broker-link a {
	font-weight: 600;
	text-transform: uppercase;
	color: #2f3249;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	gap: .75em
}

.card-broker-link a .icon {
	font-size: .875rem;
	color: #d6d8de;
	transition: color .25s ease
}

.card-broker-link a:hover .icon {
	color: #5dd39e
}

.card-broker-image,
.card-broker-link {
	transition: border-color .25s ease
}

.card-broker:hover .card-broker-image,
.card-broker:hover .card-broker-link {
	border-color: #2f3249
}

.card-course {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
	padding: .875rem 0;
	-ms-flex-align: start;
	align-items: flex-start;
	align-items: start
}

@media (max-width:991.98px) {
	.card-course {
		grid-template-columns: 1fr;
		gap: .725rem
	}
}

.card-course__content__title {
	font-size: 1.5rem;
	margin: 0;
	padding: .725rem 0;
	cursor: pointer
}

@media (max-width:1200px) {
	.card-course__content__title {
		font-size: calc(1.275rem + .3vw)
	}
}

@media (max-width:991.98px) {
	.card-course__content__title {
		padding-top: 0
	}
}

.card-course__content__title .toggle {
	font-size: .75em;
	margin-left: .5em;
	transition: all .2s ease;
	will-change: transform;
	position: relative;
	display: inline-block
}

.card-course__content__excerpt .text-formatted {
	line-height: 1.53
}

.card-course.-is-expanded .card-course__content__title .toggle {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.card-course__action {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.25rem;
	-ms-flex-align: center;
	align-items: center
}

@media (max-width:991.98px) {
	.card-course__action {
		grid-template-columns: 1fr;
		gap: .5rem
	}
}

.card-course__action__author {
	text-align: right
}

@media (max-width:991.98px) {
	.card-course__action__author {
		text-align: left
	}
}

.courses {
	width: 100%
}

.courses .card-course+.card-course {
	border-top: 1px solid #e1e2e2
}

.event-banner {
	background-image: url(../images/background/banner-event-bg.jpg);
	box-shadow: 0 0 40px rgba(0, 0, 0, .17);
	border-radius: .375rem;
	overflow: hidden;
	background-color: #2f3249
}

.event-banner .col-auto {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
	margin-top: 0 !important
}

@media (max-width:991.98px) {
	.event-banner {
		background-image: none;
		background-color: #fff;
		color: #2f3249;
		min-height: 330px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center
	}

	.event-banner:before {
		display: none !important
	}
}

.event-banner .btn {
	background-color: #fff;
	color: #2f3249;
	padding-left: 3rem;
	padding-right: 3rem
}

.event-banner .btn:hover {
	background-color: #fff;
	opacity: .8
}

.event-banner .btn-primary {
	background-color: #5dd39e !important
}

.event-banner hr {
	display: none !important
}

.event-banner-content {
	padding: 2rem
}

@media (max-width:991.98px) {
	.event-banner-content {
		position: relative !important;
		padding-left: 2rem;
		padding-right: 2rem
	}
}

@media (max-width:575.98px) {
	.event-banner-content {
		padding: 2rem 1rem
	}
}

.event-banner-content .justify-content-end {
	-ms-flex-pack: start !important;
	justify-content: start !important
}

@media (max-width:1199.98px) {
	.event-banner-content .justify-content-end {
		-ms-flex-pack: center !important;
		justify-content: center !important
	}
}

.event-banner-title {
	font-size: 2rem
}

@media (max-width:1200px) {
	.event-banner-title {
		font-size: calc(1.325rem + .9vw)
	}
}

.banner-award {
	background-color: #2f3249;
	color: #fff;
	font-size: 1.25rem;
	border-radius: .6875rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 2rem 7vw;
	min-height: 21rem;
	position: relative;
	text-align: center
}

@media (min-width:992px) {
	.banner-award {
		text-align: left;
		font-size: 1.375rem
	}
}

.banner-award.-circles {
	background-position: center center;
	background-image: url(../images/background/banner-award-bg.svg);
	background-size: cover
}

.banner-award figure {
	margin-bottom: 0
}

.banner-award figure img {
	max-width: 100%
}

.banner-award_place {
	width: 6rem;
	margin: 1rem auto 1rem !important
}

.banner-award_place img {
	display: block;
	height: auto;
	width: 100%
}

@media (min-width:992px) {
	.banner-award_place {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		margin: 0 !important;
		width: 10rem
	}
}

.banner-award_title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-bottom: .4rem
}

@media (min-width:992px) {
	.banner-award_title {
		font-size: 2.8125rem
	}
}

.banner-award_awardLogo {
	max-width: 80%;
	width: 18rem
}

@media (min-width:992px) {
	.banner-award_awardLogo {
		width: auto
	}
}

.banner-award_placeText {
	display: block;
	font-size: 1.5625rem;
	font-weight: 700;
	line-height: 1
}

@media (min-width:992px) {
	.banner-award_placeText {
		margin-top: 1.2rem
	}

	.banner-award_placeText:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(90deg, #fff 0, #2f3249 100%);
		margin-bottom: 1rem
	}
}

.banner-award_col {
	width: 100%
}

@media (min-width:992px) {
	.banner-award_col {
		width: 50%
	}
}

.banner-award_col:first-child {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center
}

@media (min-width:992px) {
	.banner-award_col:first-child {
		padding-right: 7rem;
		padding-bottom: 0
	}
}

@media (min-width:992px) {
	.banner-award_col:last-child {
		padding-left: 8rem
	}
}

.tabs-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 2.5rem
}

@media (max-width:1199.98px) {
	.tabs-nav {
		display: block
	}
}

.tabs-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	width: 100%;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-pack: center;
	justify-content: center
}

@media (max-width:1199.98px) {
	.tabs-nav ul {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: start;
		justify-content: flex-start;
		overflow-x: scroll
	}
}

.tabs-nav li {
	cursor: pointer;
	font-weight: 700;
	font-size: 1.125rem;
	position: relative;
	padding-bottom: 1rem;
	transition: all .5s
}

@media (max-width:1199.98px) {
	.tabs-nav li {
		text-align: left;
		white-space: nowrap
	}
}

.tabs-nav li+li {
	margin-left: 3rem
}

@media (max-width:1199.98px) {
	.tabs-nav li+li {
		margin-left: 2rem
	}
}

.tabs-nav li:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	bottom: -1px;
	height: 2px;
	width: 100%;
	max-width: 0;
	background-color: #5dd39e;
	transition: all .5s
}

@media (max-width:1199.98px) {
	.tabs-nav li:before {
		bottom: 0;
		height: 1px;
		opacity: 0
	}
}

.tabs-nav li:hover {
	color: #000
}

.tabs-nav li.current {
	color: #000
}

.tabs-nav li.current:before {
	max-width: 100%
}

@media (max-width:1199.98px) {
	.tabs-nav li.current:before {
		opacity: 1
	}
}

.tabs .tab-content {
	display: none
}

.tabs .tab-content.current {
	display: inherit
}

.js-tabs .js-tab-content {
	display: none
}

.js-tabs .js-tab-content.current {
	display: block
}

.pagination {
	font-size: .875rem;
	margin-top: 2rem;
	font-weight: 600
}

.pagination .screen-reader-text {
	display: none
}

.pagination .nav-links {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center
}

.pagination .nav-links a {
	color: inherit
}

.pagination .nav-links .page-numbers {
	height: 1.5625rem;
	width: 1.5625rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	border-radius: 50%;
	transition: all .3s ease;
	margin: 0 .15rem;
	padding-bottom: 1px
}

.pagination .nav-links .page-numbers.current {
	background-color: #5dd39e;
	color: #2f3249
}

.pagination .nav-links .page-numbers:hover {
	background-color: #f8fafc;
	color: #2f3249
}

.pagination .nav-links .page-numbers.next,
.pagination .nav-links .page-numbers.prev {
	width: auto;
	height: auto;
	text-transform: uppercase;
	background-color: transparent !important;
	color: #2f3249
}

.pagination .nav-links .page-numbers.prev {
	margin-right: 1.5rem
}

.pagination .nav-links .page-numbers.next {
	margin-left: 1.5rem
}

.avatar {
	width: 9.3rem;
	height: 9.3rem;
	display: inline-block;
	border-radius: 50%;
	overflow: hidden
}

.avatar img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.screen {
	overflow: hidden;
	position: relative;
	border: 1rem solid #282a3d;
	background-color: #282a3d;
	border-radius: 1.25rem;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, .2);
	margin: 1px
}

@media (max-width:767.98px) {
	.screen {
		border-radius: 1rem;
		border-width: .5rem
	}
}

.screen--phone {
	border-radius: 1rem;
	border-width: .5rem
}

@media (max-width:767.98px) {
	.screen--phone {
		border-radius: .75rem;
		border-width: .25rem
	}
}

.screen-image {
	overflow: hidden;
	position: relative;
	overflow: hidden;
	--aspect-ratio: 56.25%
}

.screen-image--phone {
	--aspect-ratio: 208.33%
}

.screen-image .iframe-placeholder,
.screen-image iframe,
.screen-image img,
.screen-image video {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	display: block;
	border: none;
	border-radius: .5rem;
	overflow: hidden
}

@media (max-width:575.98px) {

	.screen-image .iframe-placeholder,
	.screen-image iframe,
	.screen-image img,
	.screen-image video {
		border-radius: .5rem
	}
}

.screen-image img,
.screen-image video {
	-o-object-position: center;
	object-position: center;
	-o-object-fit: cover;
	object-fit: cover
}

.screen-gray {
	border-color: #e7eaed;
	background-color: #e7eaed
}

.ob-tooltip {
	border: none;
	background: 0 0;
	cursor: pointer;
	position: relative;
	transition: all .1s;
	display: inline-block;
	padding: 0;
	width: 1rem;
	height: 1rem;
	font-size: 1rem
}

@media (max-width:1199.98px) {
	.ob-tooltip {
		width: .9rem;
		height: .9rem
	}
}

.ob-tooltip img,
.ob-tooltip svg {
	display: block;
	width: 100%;
	height: auto;
	transition: all .1s
}

.ob-tooltip_info {
	position: absolute;
	z-index: 2;
	left: 50%;
	bottom: 100%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease-out;
	background-color: #fff;
	box-shadow: 0 0 46px rgba(0, 0, 0, .08);
	-webkit-filter: drop-shadow;
	filter: drop-shadow;
	border-radius: .375rem;
	padding: 1rem;
	min-width: 260px;
	line-height: 1.4
}

.ob-tooltip_info:after {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 100%;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #fff
}

.ob-tooltip_info.active {
	visibility: visible;
	opacity: 1
}

.ob-tooltip:active,
.ob-tooltip:focus {
	outline: 0
}

.ob-tooltip:hover img,
.ob-tooltip:hover svg {
	opacity: .6
}

.ob-table {
	margin-bottom: 2.875rem
}

@media (max-width:767.98px) {
	.ob-table {
		font-size: .875rem
	}
}

@media (max-width:575.98px) {
	.ob-table {
		font-size: 12px
	}
}

.ob-table_footer,
.ob-table_header {
	background-color: #2f3249;
	color: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	padding: 1rem 2rem;
	margin: 0;
	min-height: 4rem;
	border-radius: .375rem;
	font-size: 1.125rem;
	text-transform: uppercase;
	font-weight: 600
}

@media (max-width:1199.98px) {

	.ob-table_footer,
	.ob-table_header {
		padding-left: 1.5rem
	}
}

@media (max-width:991.98px) {

	.ob-table_footer,
	.ob-table_header {
		font-size: 1rem;
		min-height: 3rem
	}
}

@media (max-width:575.98px) {

	.ob-table_footer,
	.ob-table_header {
		text-align: center;
		padding-left: 1rem;
		padding-right: 1rem
	}
}

.ob-table_footer {
	color: #2f3249;
	background-color: #eff4f9;
	margin-top: 1rem
}

.ob-table_row {
	display: -ms-flexbox;
	display: flex
}

.ob-table_row:last-child .ob-table_col {
	border-bottom: 1px solid #eff4f9
}

.ob-table_header+.ob_table_row:first-child .ob-table_col {
	border-top: 0
}

.ob-table_col {
	border-left: 1px solid #eff4f9;
	border-top: 1px solid #eff4f9;
	background-color: #fff;
	line-height: 1.2;
	position: relative;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	min-height: 3.75rem;
	padding: .5rem 2rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex: 1;
	flex: 1
}

@media (max-width:1199.98px) {
	.ob-table_col {
		padding: .5rem 1rem
	}
}

@media (max-width:575.98px) {
	.ob-table_col {
		padding: .8rem .5rem .5rem;
		display: block;
		min-height: 3.75rem
	}
}

.ob-table_col :last-child {
	margin-bottom: 0
}

.ob-table_col a:not(.btn) {
	color: inherit;
	text-decoration: underline;
	transition: all .2s
}

.ob-table_col a:not(.btn):hover {
	color: #5dd39e
}

.ob-table_col .ob-tooltip {
	position: absolute;
	right: 1.5rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

@media (max-width:1199.98px) {
	.ob-table_col .ob-tooltip {
		right: 1rem
	}
}

.ob-table_col-heading {
	background-color: #f8fafc;
	font-size: 1.125rem;
	-ms-flex-align: start;
	align-items: flex-start;
	border: none !important;
	z-index: 2;
	-ms-flex-preferred-size: 300px;
	flex-basis: 300px;
	-ms-flex-positive: 0;
	flex-grow: 0;
	padding-right: 3rem
}

.ob-table_col-heading .ob-tooltip {
	right: 2rem
}

@media (max-width:1199.98px) {
	.ob-table_col-heading {
		font-size: 15px;
		-ms-flex-preferred-size: 200px;
		flex-basis: 200px;
		padding-right: 1rem;
		padding-left: 1.5rem
	}

	.ob-table_col-heading .ob-tooltip {
		right: .8rem
	}
}

@media (max-width:767.98px) {
	.ob-table_col-heading {
		-ms-flex-preferred-size: 180px;
		flex-basis: 180px
	}
}

.ob-table_col:first-child {
	border-left: 0
}

.ob-table_col:last-child {
	border-right: 1px solid #eff4f9
}

.ob-table_col .green {
	color: #2e843f !important
}

.ob-table_moblabel {
	display: none
}

.ob-table_moblabel+* {
	margin-top: .4rem
}

.ob-table-pricing {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10;
	text-align: center
}

.ob-table-pricing .ob-table_row {
	-ms-flex-align: center;
	align-items: center
}

@media (max-width:991.98px) {
	.ob-table-pricing .ob-table_row {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
}

.ob-table-pricing .secondary-menu {
	background-color: #f7f7f7
}

.ob-table-pricing .ob-table_col {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border: none !important;
	display: -ms-flexbox !important;
	display: flex !important
}

@media (max-width:991.98px) {
	.ob-table-pricing .ob-table_col {
		padding-top: .6rem;
		padding-bottom: .7rem
	}
}

.ob-table-pricing .ob-table_col:after {
	content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	height: 80%;
	border-left: 1px solid #eff4f9
}

.ob-table-pricing .ob-table_col:last-child:after {
	display: none
}

.ob-table-pricing .ob-table_col-heading {
	background-color: #2f3249;
	color: #fff;
	font-size: 1.25rem;
	border-radius: .375rem;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 116px
}

@media (max-width:991.98px) {
	.ob-table-pricing .ob-table_col-heading {
		width: 100%;
		-ms-flex: auto;
		flex: auto;
		min-height: 0
	}

	.ob-table-pricing .ob-table_col-heading p {
		margin-bottom: .5rem
	}
}

.ob-table-pricing .ob-table_col-heading .form-group-select::after {
	border-radius: .375rem
}

.ob-table-pricing .ob-table_col-heading select {
	height: 40px;
	font-size: 1.125rem;
	border-radius: .375rem
}

.ob-table-pricing .ob-table_col-heading select:active,
.ob-table-pricing .ob-table_col-heading select:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(84, 184, 139, .5);
	border-color: #5dd39e
}

@media (max-width:991.98px) {
	.ob-table-pricing .ob-table_col-heading select {
		height: 35px;
		font-size: 1rem
	}
}

.ob-table-pricing .ob-table_col-heading:after {
	display: none
}

.ob-table-pricing_options {
	-ms-flex: 1;
	flex: 1;
	display: -ms-flexbox;
	display: flex;
	box-shadow: 0 0 38.4701px rgba(47, 50, 73, .11);
	border-radius: .375rem;
	overflow: hidden
}

@media (max-width:991.98px) {
	.ob-table-pricing_options {
		width: 100%;
		-ms-flex: auto;
		flex: auto
	}

	.ob-table-pricing_options .h3,
	.ob-table-pricing_options .h4 {
		font-size: 16px !important
	}

	.ob-table-pricing_options .text-lg,
	.ob-table-pricing_options .text-lg p {
		font-size: 16px !important
	}

	.ob-table-pricing_options .text-md,
	.ob-table-pricing_options .text-md p {
		font-size: 14px !important
	}
}

.ob-table-pricing_options .h2,
.ob-table-pricing_options .h3,
.ob-table-pricing_options .h4 {
	margin-bottom: 0
}

.ob-table-pricing_options .price-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	margin: .2rem auto .3rem;
	min-height: 50px
}

@media (max-width:991.98px) {
	.ob-table-pricing_options .price-wrapper {
		margin: 0 auto .3rem
	}
}

.ob-table-pricing_options .price-wrapper .ts-price__normal {
	font-weight: 700
}

.ob-table-pricing_options .price-wrapper p {
	line-height: 1.2;
	margin-bottom: 0
}

.ob-table-pricing_options .price-wrapper p small {
	display: block
}

@media (max-width:767.98px) {
	.ob-table_col:first-child {
		width: 15%
	}

	.ob-table .ob-tooltip {
		display: none
	}
}

@media (max-width:575.98px) {
	.ob-table_col:first-child {
		display: none
	}

	.ob-table_col:nth-child(2) {
		width: 25%
	}

	.ob-table_moblabel {
		display: block
	}
}

.toggle-content-item {
	display: none
}

.toggle-content-item.active {
	display: block
}

@media (max-width:767.98px) {

	.table,
	.text-formatted table {
		font-size: .825rem
	}
}

.table thead,
.text-formatted table thead {
	font-weight: 600;
	text-transform: uppercase
}

.table thead.thead-light-dark,
.text-formatted table thead.thead-light-dark {
	background-color: #ebeff8
}

.table thead.thead-dark-light,
.text-formatted table thead.thead-dark-light {
	background-color: #2f3249;
	color: #fff
}

.table thead th,
.text-formatted table thead th {
	border: none !important;
	white-space: nowrap
}

.table thead th:first-child,
.text-formatted table thead th:first-child {
	border-top-left-radius: .375rem;
	border-bottom-left-radius: .375rem
}

.table thead th:last-child,
.text-formatted table thead th:last-child {
	border-top-right-radius: .375rem;
	border-bottom-right-radius: .375rem
}

.table td,
.table th,
.text-formatted table td,
.text-formatted table th {
	padding-left: 1rem;
	padding-right: 1rem
}

@media (max-width:767.98px) {

	.table td,
	.table th,
	.text-formatted table td,
	.text-formatted table th {
		padding-left: .5rem;
		padding-right: .5rem
	}
}

.table-sm td,
.table-sm th {
	padding-left: .5rem;
	padding-right: .5rem
}

.table-sm th {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(244, 246, 253, .53)
}

@media (max-width:767.98px) {
	.scrollable-table {
		overflow-x: auto
	}

	.scrollable-table table {
		width: auto;
		min-width: 100%
	}
}

.scrollable-table.limit-height {
	max-height: 400px;
	overflow-y: auto;
	margin-right: -.5rem;
	padding-right: .5rem
}

.scrollable-table.limit-height::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: rgba(47, 50, 73, .05)
}

.scrollable-table.limit-height::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(47, 50, 73, .05)
}

.scrollable-table.limit-height::-webkit-scrollbar-thumb {
	background-color: #2f3249;
	border: none
}

.scrollable-table.limit-height table {
	position: relative
}

.scrollable-table.limit-height thead {
	position: -webkit-sticky;
	position: sticky;
	top: -1px
}

.metabox {
	position: relative;
	padding: 4.5rem 2rem 3.5rem
}

@media (max-width:1199.98px) {
	.metabox {
		padding: 3rem 2rem 2.5rem
	}
}

@media (max-width:575.98px) {
	.metabox {
		padding: 2.5rem 1rem 2rem
	}
}

.metabox-icon {
	width: 6.375rem;
	height: 6.375rem;
	margin: 0;
	background-color: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%
}

@media (max-width:1199.98px) {
	.metabox-icon {
		width: 4.5rem;
		height: 4.5rem
	}
}

.metabox-icon img {
	display: block;
	height: auto;
	margin: auto;
	max-width: 45%
}

.metabox-header {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.metabox-header:not(:last-child) {
	margin-bottom: 2rem !important
}

.metabox-header .section-heading-label {
	font-weight: 400;
	font-size: .75rem
}

.metabox-header .section-heading-title {
	margin-bottom: .75rem;
	line-height: 1.15
}

@media (min-width:992px) and (max-width:1199.98px) {
	.metabox-header .section-heading-title {
		font-size: 2rem !important
	}
}

@media (max-width:1199.98px) {
	.metabox-header .section-heading-title br {
		display: none
	}
}

.metabox-header .section-heading-subtitle {
	color: #585858;
	max-width: 475px;
	margin-left: auto;
	margin-right: auto;
	line-height: 1.44
}

.metabox-body {
	width: 100%;
	padding: 2rem 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

.metabox-footer {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-top: auto
}

.metabox--auto-height {
	min-height: unset
}

.metabox--step {
	padding-top: 0
}

.metabox--step .metabox-header {
	text-align: center
}

.metabox--step .metabox-header .section-heading-title {
	margin-top: -.65em;
	position: relative
}

.metabox--step .metabox-header .section-heading-title[data-step]::before {
	content: attr(data-step);
	width: 1.72em;
	height: 1.72em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	line-height: 1;
	font-size: .8em;
	text-align: center;
	background-color: #3952af;
	border-radius: 50%;
	color: #fff;
	margin-right: .5em
}

.metabox--step .metabox-header .section-heading-subtitle {
	margin-top: -1rem;
	font-size: 1.25rem
}

@media (max-width:991.98px) {
	.metabox--step .metabox-header .section-heading-subtitle {
		font-size: 1rem
	}
}

.metabox .slider-testimonials {
	width: 100%;
	max-width: 470px;
	margin: 0 auto
}

@media (max-width:1479.98px) {
	.metabox .slider-testimonials {
		max-width: 450px
	}
}

.metabox-app {
	position: relative
}

.metabox-app-image {
	position: absolute;
	bottom: 0;
	left: 1rem;
	max-width: 50%;
	height: auto
}

@media (max-width:575.98px) {
	.metabox-app-image {
		left: 0
	}
}

.metabox-app-image.-light {
	display: none
}

.metabox-app:hover .metabox-app-image.-dark {
	display: none
}

.metabox-app:hover .metabox-app-image.-light {
	display: block
}

.metabox-app-image-arrow {
	position: absolute;
	left: 60%;
	bottom: 12%;
	width: 15.4%;
	height: auto
}

@media (max-width:767.98px) {
	.metabox-app-image-arrow {
		bottom: 8%
	}
}

.metabox-app .metabox-body {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: calc(50% + 1rem)
}

@media (max-width:991.98px) {
	.metabox-app .metabox-body {
		height: 360px
	}
}

@media (max-width:767.98px) {
	.metabox-app .metabox-body {
		height: 260px
	}
}

@media (max-width:575.98px) {
	.metabox-app .metabox-body {
		height: calc(200px + 10vw)
	}
}

.metabox-app-links {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem
}

.metabox-app-links a {
	width: 150px;
	margin: 0;
	transition: all .15s ease
}

@media (max-width:575.98px) {
	.metabox-app-links a {
		width: 120px
	}
}

.metabox-app-links a:hover {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	opacity: .75
}

.metabox-award {
	position: relative
}

.metabox-extension .extension {
	position: relative;
	text-transform: none;
	font-size: 1.25rem;
	line-height: 1.22;
	letter-spacing: initial
}

@media (min-width:992px) and (max-width:1199.98px) {
	.metabox-extension .extension {
		font-size: 1rem
	}
}

@media (max-width:575.98px) {
	.metabox-extension .extension {
		font-size: 1rem
	}
}

.metabox-extension .extension::before {
	content: "";
	padding-top: 48.5%;
	display: block
}

.metabox-extension .extension__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center
}

.metabox-extension .extension .symbol {
	color: #4c9564;
	text-decoration: underline;
	position: relative
}

.metabox-extension .extension .symbol::before {
	content: "";
	width: 4.33em;
	height: 1.25em;
	position: absolute;
	bottom: 100%;
	right: 60%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='104.2' height='29.6' xml:space='preserve'%3E%3Cpath d='M104.2 18.4 98 .9c0-.1-.1-.2-.2-.3-.3-.5-.8-.7-1.4-.6-.7.1-1 .7-.8 1.3l5.1 14.4C82.3 4.4 64.2-.3 46.8 1.8 17.5 5.5.4 27.7.2 27.9c-.4.5-.2 1.2.4 1.5.6.4 1.3.3 1.7-.2C2.4 29 19 7.6 47.3 4c16.4-2 33.6 2.3 51 12.9l-17.4-2.3c-.7-.1-1.3.3-1.3.9 0 .6.5 1.2 1.2 1.3l22.2 3c.4.1.8-.1 1.1-.3.1-.3.2-.7.1-1.1z' fill='%233a3d58'/%3E%3C/svg%3E");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat
}

.metabox-extension .extension .symbol img {
	position: absolute;
	top: 100%;
	left: 0;
	border-radius: 4px;
	width: 400px;
	height: auto;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
	z-index: 10;
	display: none
}

.metabox-extension .extension .symbol:hover img {
	display: block
}

.metabox-extension .extension .symbol {
	display: none !important
}

.metabox-container {
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
	-ms-flex-align: stretch;
	align-items: stretch;
	grid-template-columns: repeat(6, minmax(0, 1fr))
}

@media (max-width:991.98px) {
	.metabox-container {
		grid-template-columns: 1fr
	}
}

@media (min-width:992px) {
	.metabox-container .metabox {
		grid-column: span 3
	}

	.metabox-container .metabox-5000-inc-small,
	.metabox-container .metabox-award,
	.metabox-container .metabox-bill,
	.metabox-container .metabox-discord,
	.metabox-container .metabox-extension,
	.metabox-container .metabox-signalstack {
		grid-column: span 2
	}
}

.metabox-5000-inc {
	display: -ms-flexbox;
	display: flex;
	position: relative
}

@media (min-width:992px) {
	.metabox-5000-inc {
		grid-column: span 6 !important;
		padding: 0 3rem !important
	}
}

@media (min-width:1200px) {
	.metabox-5000-inc {
		padding: 0 6rem !important
	}
}

.metabox-5000-inc .inc-award__year {
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transform: translate(50%, 0) rotate(90deg);
	transform: translate(50%, 0) rotate(90deg);
	line-height: 1;
	background-color: #2f3249;
	color: #fff;
	padding: .15em .8em;
	letter-spacing: .2em;
	font-weight: 600
}

@media (max-width:991.98px) {
	.metabox-5000-inc .inc-award__year {
		top: calc(3rem + 106px)
	}
}

@media (max-width:575.98px) {
	.metabox-5000-inc .inc-award__year {
		top: calc(2.5rem + 106px)
	}
}

.inc-award {
	width: 100%;
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
	-ms-flex-align: center;
	align-items: center;
	grid-template-columns: repeat(2, 1fr)
}

@media (min-width:992px) {
	.inc-award {
		grid-template-columns: repeat(5, 1fr)
	}

	.inc-award__badge {
		-ms-flex-order: 3;
		order: 3;
		grid-column: span 1 !important
	}
}

@media (max-width:575.98px) {
	.inc-award {
		grid-gap: 2rem 1rem;
		gap: 2rem 1rem
	}
}

.inc-award__badge {
	grid-column: span 2;
	text-align: center
}

.inc-award__stats {
	font-size: 1.5rem;
	text-transform: uppercase;
	line-height: 1;
	text-align: center
}

@media (max-width:1479.98px) {
	.inc-award__stats {
		font-size: 1.25rem
	}
}

@media (max-width:575.98px) {
	.inc-award__stats {
		font-size: 1rem
	}
}

@media (min-width:992px) {
	.inc-award__stats {
		text-align: left
	}

	.inc-award__stats.-chicago,
	.inc-award__stats.-illinois {
		text-align: right
	}

	.inc-award__stats__inner {
		display: inline-block;
		text-align: left
	}
}

.inc-award__stats strong {
	font-size: 2.625rem;
	display: block;
	line-height: 1;
	margin-bottom: .75rem
}

@media (max-width:1200px) {
	.inc-award__stats strong {
		font-size: calc(1.3875rem + 1.65vw)
	}
}

.metabox-5000-inc-small {
	overflow: hidden
}

.metabox-5000-inc-small .section-heading-title {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	white-space: nowrap
}

.metabox-5000-inc-small .section-heading-title .swiper-slider.-disabled .swiper-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: baseline;
	align-items: baseline
}

.metabox-5000-inc-small .section-heading-title .swiper-slider.-disabled .swiper-slide {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.metabox-5000-inc-small .section-heading-title .swiper-slide span {
	position: relative
}

.metabox-5000-inc-small .section-heading-title .swiper-slide span::after,
.metabox-5000-inc-small .section-heading-title .swiper-slide span:before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: currentColor;
	opacity: .1;
	border-radius: 50%;
	position: absolute;
	top: 55%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.metabox-5000-inc-small .section-heading-title .swiper-slide span::before {
	left: -.75em
}

.metabox-5000-inc-small .section-heading-title .swiper-slide span::after {
	right: -.75em
}

.metabox-discord .section-heading-label img {
	position: absolute;
	height: 145%;
	width: auto;
	left: 50%;
	top: 60%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

@media (min-width:1200px) {
	.metabox-discord .section-heading-label img {
		height: 165%;
		top: 55%
	}
}

.real-time-markets {
	position: relative;
	width: 100%;
	max-width: 540px
}

.real-time-markets::before {
	content: "";
	width: 100%;
	display: block;
	padding-top: 50%
}

.real-time-markets .bubble {
	background-color: #eff4f9;
	border-radius: 50%;
	position: absolute;
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.1;
	text-align: center;
	color: #2f3249;
	transition: background-color .3s ease, color .3s ease;
	will-change: transform;
	-webkit-animation: float 6s ease-in-out infinite;
	animation: float 6s ease-in-out infinite
}

@media (min-width:992px) and (max-width:1199.98px) {
	.real-time-markets .bubble {
		font-size: 1rem
	}
}

@media (max-width:767.98px) {
	.real-time-markets .bubble {
		font-size: 1rem
	}
}

@media (max-width:575.98px) {
	.real-time-markets .bubble {
		font-size: 2.783vw
	}
}

.real-time-markets .bubble:nth-child(even) {
	-webkit-animation-delay: .5s;
	animation-delay: .5s;
	-webkit-animation-duration: 5s;
	animation-duration: 5s
}

.real-time-markets .bubble:nth-child(3n) {
	-webkit-animation-delay: .75s;
	animation-delay: .75s;
	-webkit-animation-duration: 4.5s;
	animation-duration: 4.5s
}

.real-time-markets .bubble:hover {
	background-color: #2f3249;
	color: #fff;
	z-index: 20
}

.real-time-markets .bubble::before {
	content: "";
	width: 100%;
	padding-top: 100%;
	display: block
}

.real-time-markets .bubble .-live {
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #f26868;
	position: absolute;
	top: 12%;
	right: 12%;
	z-index: 5;
	box-shadow: 0 0 0 0 #f26868;
	-webkit-transform: scale(1) translate(50%, -50%);
	transform: scale(1) translate(50%, -50%);
	-webkit-animation: pulse-live 2s infinite;
	animation: pulse-live 2s infinite
}

.real-time-markets .bubble .-live::after {
	width: 100px;
	color: #fff;
	background-color: rgba(242, 104, 104, .85);
	padding: .5rem
}

.real-time-markets .bubble__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	padding: 0 5%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center
}

.real-time-markets .bubble .label--hover::before,
.real-time-markets .bubble .label::before {
	content: attr(data-label)
}

.real-time-markets .bubble .label {
	font-size: 75%
}

.real-time-markets .bubble .label--hover {
	font-size: 130%
}

.real-time-markets .bubble--forex {
	width: 29.63%;
	left: 0;
	bottom: 6%
}

.real-time-markets .bubble--crypto {
	width: 19.07%;
	bottom: .25%;
	left: 30.5%
}

.real-time-markets .bubble--etfs {
	width: 16%;
	top: 0;
	left: 12%
}

.real-time-markets .bubble--stock-assets {
	width: 24.44%;
	top: 8%;
	left: 28.5%
}

.real-time-markets .bubble--indices {
	width: 17.04%;
	top: 0;
	left: 55%
}

.real-time-markets .bubble--futures {
	width: 26.66%;
	bottom: 8%;
	left: 50%
}

.real-time-markets .bubble--otc {
	width: 24.44%;
	right: 0;
	top: 6%
}

.real-time-markets .bubble--crypto-perps {
	width: 19%;
	right: 3%;
	bottom: 2%
}

@-webkit-keyframes float {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}

	50% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px)
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}
}

@keyframes float {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}

	50% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px)
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}
}

@-webkit-keyframes pulse-live {
	0% {
		-webkit-transform: scale(.95) translate(-50%, -50%);
		transform: scale(.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(242, 104, 104, .7)
	}

	70% {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10px rgba(242, 104, 104, 0)
	}

	100% {
		-webkit-transform: scale(.95) translate(-50%, -50%);
		transform: scale(.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(242, 104, 104, 0)
	}
}

@keyframes pulse-live {
	0% {
		-webkit-transform: scale(.95) translate(-50%, -50%);
		transform: scale(.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(242, 104, 104, .7)
	}

	70% {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10px rgba(242, 104, 104, 0)
	}

	100% {
		-webkit-transform: scale(.95) translate(-50%, -50%);
		transform: scale(.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(242, 104, 104, 0)
	}
}

.body-menu,
.list-block,
.list-check,
.list-inline,
.list-reset,
.list-steps {
	padding: 0;
	margin: 0;
	list-style-type: none
}

.body-menu li,
.list-block li,
.list-check li,
.list-inline li,
.list-reset li,
.list-steps li {
	list-style-type: none
}

.list-inline {
	display: -ms-inline-flexbox;
	display: inline-flex
}

.list-inline .menu-item,
.list-inline-item {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 0;
	padding-right: 1rem
}

@media (max-width:1479.98px) {

	.list-inline .menu-item,
	.list-inline-item {
		padding-right: .8rem
	}
}

.list-inline .menu-item:last-child,
.list-inline-item:last-child {
	padding-right: 0
}

.list-inline .menu-item .icon,
.list-inline-item .icon {
	vertical-align: middle
}

.list-inline .menu-item .icon+*,
.list-inline-item .icon+* {
	margin-left: 1rem
}

.list-inline .menu-item a,
.list-inline-item a {
	color: inherit;
	transition: all .2s ease-out
}

.list-inline .menu-item a:hover,
.list-inline-item a:hover {
	opacity: .6
}

.list-inline.sm .list-inline-item {
	padding-right: .6rem
}

.list-check li {
	display: block;
	position: relative;
	padding-left: 2.5rem;
	margin-bottom: 1.2rem
}

.list-check li:last-child {
	margin-bottom: 0
}

.list-check li:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: .1em;
	width: 1.4em;
	height: 1.4em;
	border-radius: 50%;
	background-color: #5dd39e;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9'%3E%3Cpath fill='%231e2022' d='M11.33 2.066L5.1 8.296a.956.956 0 01-1.354 0L.63 5.18a.957.957 0 111.355-1.355l2.437 2.437L9.975.71a.957.957 0 111.355 1.355z'/%3E%3C/svg%3E");
	background-size: 40% auto
}

.list-check-sm li {
	padding-left: 1.75em;
	margin-bottom: .8em
}

.list-check-sm li:before {
	top: .4em;
	width: 1em;
	height: 1em;
	background-size: 50% auto
}

.list-check-red li:before {
	background-color: #f26868
}

.list-steps {
	position: relative;
	font-size: 1.125rem;
	display: inline-block;
	text-align: left;
	overflow: hidden;
	padding-top: 1rem;
	padding-bottom: 1rem
}

@media (max-width:575.98px) {
	.list-steps {
		font-size: 1rem
	}
}

@media (min-width:768px) {
	.list-steps:before {
		content: "";
		display: block;
		position: absolute;
		left: 1.5rem;
		top: 50%;
		height: calc(100% - 6rem);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		border-right: 1px dashed #2e843f
	}
}

@media (min-width:768px) and (max-width:991.98px) {
	.list-steps:before {
		left: 1.25rem
	}
}

@media (min-width:768px) {
	.list-steps.step-animation:before {
		opacity: 0;
		transition: all 1s .2s ease-out
	}

	.list-steps.step-animation.animated:before {
		opacity: 1
	}
}

.list-steps li {
	position: relative;
	z-index: 2;
	padding-left: 5.6rem;
	margin-bottom: 2rem;
	padding-bottom: .2rem;
	padding-top: .2rem
}

@media (max-width:991.98px) {
	.list-steps li {
		padding-bottom: 0;
		padding-top: 0;
		margin-bottom: 1rem;
		line-height: 1.6
	}
}

@media (max-width:575.98px) {
	.list-steps li {
		padding-left: 3rem
	}
}

.list-steps li:last-of-type {
	margin-bottom: 0
}

.list-steps li:before {
	content: "1";
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-55%);
	transform: translateY(-55%);
	width: 3.1rem;
	height: 3.1rem;
	border-radius: 50%;
	background-color: #2e843f;
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 40% auto;
	padding-top: .15em
}

@media (max-width:991.98px) {
	.list-steps li:before {
		width: 2.5rem;
		height: 2.5rem;
		font-size: 1.125rem
	}
}

@media (max-width:575.98px) {
	.list-steps li:before {
		width: 2rem;
		height: 2rem;
		font-size: 1rem
	}
}

.list-steps li:nth-child(2):before {
	content: "2"
}

.list-steps li:nth-child(3):before {
	content: "3"
}

.list-steps li:nth-child(4):before {
	content: "4"
}

.list-steps li:nth-child(5):before {
	content: "5"
}

.list-steps li b,
.list-steps li strong {
	font-weight: 700
}

.list-steps li p:last-child {
	margin-bottom: 0
}

.list-block li {
	display: block;
	position: relative;
	padding-left: 2rem;
	margin-bottom: 2rem;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.4
}

.list-block li:last-child {
	margin-bottom: 0
}

.list-block li:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 3px;
	height: calc(100% + .2em);
	border-radius: 3px;
	background-color: #2e843f
}

.list-social {
	list-style: none;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	align-content: center;
	margin-left: -.75rem;
	margin-right: -.75rem
}

.list-social-item {
	padding: 0 .75rem
}

.list-social a {
	color: inherit;
	transition: all .3s ease
}

.list-social a:hover {
	color: #5dd39e
}

.list-social-vertical {
	-ms-flex-direction: column;
	flex-direction: column
}

.list-social-vertical .list-social-item+.list-social-item {
	margin-top: .8rem
}

.symbols-list {
	max-height: 75vh;
	overflow: auto;
	font-size: .875rem;
	scrollbar-color: #2f3249 rgba(47, 50, 73, .05);
	scrollbar-width: thin
}

@media (max-width:991.98px) {
	.symbols-list {
		height: 256px
	}
}

.symbols-list::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: rgba(47, 50, 73, .05)
}

.symbols-list::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(47, 50, 73, .05)
}

.symbols-list::-webkit-scrollbar-thumb {
	background-color: #5dd39e;
	border: none
}

.symbols-list a {
	color: inherit;
	display: block;
	font-weight: 700;
	padding: .6em .5em;
	transition: all .3s ease
}

.symbols-list li:not(:last-child) {
	border-bottom: 1px solid rgba(47, 50, 73, .075)
}

.symbols-list a:hover,
.symbols-list li.is-active {
	background-color: rgba(47, 50, 73, .075)
}

.accordeon {
	width: 100%;
	margin: 0
}

.accordeon-item {
	width: 1140px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-radius: .75rem;
	background-color: #f6f9ff
}

.accordeon-item-heading {
	padding: 1.5rem 6rem 1.5rem 3rem;
	font-size: 1.75rem;
	font-family: "Source Sans Pro", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 700;
	margin-bottom: 0;
	position: relative;
	line-height: 1.5;
	cursor: pointer;
	background-color: #f6f9ff;
	border-radius: .75rem;
	transition: all .3s ease
}

@media (max-width:991.98px) {
	.accordeon-item-heading {
		padding: 1rem 3rem 1rem 2rem
	}
}

@media (max-width:767.98px) {
	.accordeon-item-heading {
		font-size: 1.125rem
	}
}

.accordeon-item-heading .arrow,
.accordeon-item-heading .circle-plus {
	position: absolute;
	right: 3rem;
	top: 50%;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	font-size: 1rem;
	transition: all .3s ease
}

@media (max-width:991.98px) {

	.accordeon-item-heading .arrow,
	.accordeon-item-heading .circle-plus {
		right: 1.5rem
	}
}

.accordeon-item.is-active .accordeon-item-heading {
	background-color: #2f3249;
	color: #fff
}

.accordeon-item.is-active .accordeon-item-heading .arrow {
	-webkit-transform: translate(50%, -50%) scaleY(-1);
	transform: translate(50%, -50%) scaleY(-1)
}

.accordeon-item-content {
	padding: 2rem 3rem;
	background-color: #f6f9ff;
	border-bottom-left-radius: .75rem;
	border-bottom-right-radius: .75rem;
	font-weight: 400;
	display: none
}

@media (max-width:991.98px) {
	.accordeon-item-content {
		padding: 1.5rem 2rem
	}
}

@media (max-width:767.98px) {
	.accordeon-item-content {
		padding: 1.5rem 1rem;
		font-size: 1rem
	}
}

.accordeon-item-content [itemprop=text] {
	position: relative;
	max-width: 100%
}

.accordeon-item-content [itemprop=text] p:last-child {
	margin-bottom: 0
}

.accordeon-item-content [itemprop=text]>:last-child {
	margin-bottom: 0
}

.circle-plus {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	background-color: #eff4f9;
	padding: .2rem
}

@media (max-width:991.98px) {
	.circle-plus {
		height: 1.8rem;
		width: 1.8rem
	}
}

.circle-plus .circle {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 100%
}

.circle-plus .circle .horizontal {
	position: absolute;
	background-color: #000;
	width: 14px;
	height: 1px;
	left: 50%;
	margin-left: -7px;
	top: 50%;
	margin-top: -1px;
	border-radius: 2px
}

.circle-plus .circle .vertical {
	position: absolute;
	background-color: #000;
	width: 1px;
	height: 14px;
	left: 50%;
	margin-left: -1px;
	top: 50%;
	margin-top: -7px;
	border-radius: 2px
}

@media (max-width:991.98px) {
	.circle-plus .circle .vertical {
		height: 18px;
		margin-top: -9px
	}
}

.vertical {
	transition: all .5s ease-in-out;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.horizontal {
	transition: all .5s ease-in-out;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	opacity: 0
}

.is-collapsed {
	opacity: 1
}

.is-collapsed .vertical {
	transition: all .5s ease-in-out;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg)
}

.is-collapsed .horizontal {
	transition: all .5s ease-in-out;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	opacity: 1
}

.form-control {
	color: #000;
	border: 1px solid #dedede;
	background-color: transparent
}

.form-control:active,
.form-control:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(84, 184, 139, .5);
	border-color: #5dd39e;
	color: #000
}

textarea.form-control {
	height: 160px;
	resize: none
}

select:not(.form-control) {
	padding-left: 1rem;
	padding-right: 2.5rem;
	width: 100%;
	border: none;
	font-weight: 600;
	-webkit-appearance: none
}

select.form-control {
	-webkit-appearance: none;
	padding-right: 1.1rem !important;
	background-repeat: no-repeat;
	background-position: right .5rem center;
	background-size: .5rem auto;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E")
}

.form-control::-webkit-file-upload-button,
.form-control::file-selector-button {
	padding: .8125rem 1.875rem;
	margin: -.8125rem -1.875rem;
	-webkit-margin-end: 1.875rem;
	margin-inline-end: 1.875rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

::file-selector-button {
	font: inherit;
	-webkit-appearance: button
}

.form-group-select {
	position: relative
}

.form-group-select:after {
	pointer-events: none;
	content: "";
	display: block;
	position: absolute;
	z-index: 2;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0;
	width: 2rem;
	height: calc(100% - 1px);
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 8px auto;
	background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36206 4.96497L8.40983 2.346C8.44405 2.32385 8.46356 2.29362 8.46356 2.26195V0.119151C8.46356 0.0710609 8.41866 0.0274907 8.34986 0.00915418C8.28105 -0.00918236 8.20229 0.00106289 8.1493 0.0348677L4.23178 2.5698L0.314281 0.0348677C0.261654 0.000817364 0.182549 -0.00941673 0.113726 0.00915418C0.044919 0.0277251 2.00272e-05 0.0710609 2.00272e-05 0.119151V2.26195C2.00272e-05 2.29361 0.0195284 2.32385 0.053751 2.34623L4.10153 4.96521C4.17365 5.01164 4.28993 5.01164 4.36206 4.96497Z' fill='black'/%3E%3C/svg%3E%0A")
}

.form-group-select select {
	background-color: #fff;
	color: #1e2022
}

.contact-form {
	max-width: 100%
}

@media (min-width:1200px) {
	.contact-form {
		margin-right: 0;
		margin-left: auto
	}
}

.g-recaptcha {
	max-width: 100%
}

.g-recaptcha div,
.g-recaptcha iframe {
	max-width: 100%
}

.info-popup {
	width: 800px;
	max-width: 100%;
	border-radius: .375rem
}

@media (max-width:767.98px) {
	.fancybox-content {
		padding: 2rem
	}
}

@media (max-width:350px) {
	.fancybox-content {
		padding: 2rem 1rem
	}
}

.input-group .invalid-feedback {
	position: absolute;
	top: 100%
}

.form-notifications .alert {
	font-size: .875rem
}

.alert {
	border-left-width: 4px
}

.subscribe-form {
	max-width: 460px;
	width: 100%;
	margin: 0 auto
}

.subscribe-form .input-group::before {
	content: "";
	width: 20px;
	height: 16px;
	position: absolute;
	z-index: 4;
	top: 50%;
	left: 17px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url(../images/icons/icon-envelope-green.svg);
	background-size: 20px;
	background-position: center;
	background-repeat: no-repeat
}

.subscribe-form .form-control {
	background-color: #fff;
	padding-left: 54px;
	margin-right: .5rem;
	background-color: #eff4f9;
	border-color: #eff4f9;
	border-radius: 6px !important
}

.subscribe-form .btn {
	border-radius: 6px !important;
	position: relative;
	z-index: 4;
	padding-left: 0;
	padding-right: 0;
	width: 58px
}

.form-contact {
	padding: 4.5rem 3.75rem 3rem 3.75rem;
	border-radius: 1rem;
	box-shadow: 0 0 32px rgba(0, 0, 0, .08);
	background-color: #fff
}

@media (max-width:1199.98px) {
	.form-contact {
		padding: 3.5rem 3rem 2.5rem
	}
}

@media (max-width:767.98px) {
	.form-contact {
		padding: 3rem 1.5rem 1.5rem
	}
}

.switch {
	position: relative;
	line-height: 1
}

@media (max-width:575.98px) {
	.switch {
		-webkit-transform: scale(.9);
		transform: scale(.9)
	}
}

.switch-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.25rem;
	opacity: 0
}

.switch-label {
	height: 1.85rem;
	margin: 0;
	position: relative;
	cursor: pointer;
	border-radius: 2.85rem
}

.switch-label::before {
	content: "";
	width: 3.185rem;
	height: 100%;
	display: block;
	border-radius: 1.85rem;
	transition: all .3s ease;
	background: #fff
}

.switch-label::after {
	content: "";
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 50%;
	background-color: #2f3249;
	position: absolute;
	top: 50%;
	left: .3rem;
	transition: all .3s ease;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.switch-input:checked+.switch-label::before {
	background-color: #ffdd4a;
	border-color: #ffd521
}

.switch-input:checked+.switch-label::after {
	background-color: #2f3249;
	left: 1.76rem
}

.switch-input:focus+.switch-label::before {
	border-color: #2f3249;
	box-shadow: 0 0 0 .2rem rgba(47, 50, 73, .25)
}

.switch-holder {
	display: -ms-inline-flexbox;
	display: inline-flex;
	position: relative
}

.switch-holder .discount-warning {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	left: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-left: 1rem;
	font-size: 14px;
	white-space: nowrap
}

@media (max-width:767.98px) {
	.switch-holder .discount-warning {
		font-size: 10px
	}

	.switch-holder .discount-warning .discount-label {
		font-size: 10px
	}
}

@media (max-width:575.98px) {
	.switch-holder .discount-warning {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: start;
		align-items: flex-start;
		margin-left: .4rem;
		font-size: 8px
	}

	.switch-holder .discount-warning .discount-label {
		font-size: 8px
	}

	.switch-holder .discount-warning span {
		color: #585858
	}

	.switch-holder .discount-warning span span {
		display: none
	}
}

.switch-holder .discount-warning .discount-label+* {
	margin-left: .4rem
}

@media (max-width:575.98px) {
	.switch-holder .discount-warning .discount-label+* {
		margin-left: 0;
		margin-top: .2rem
	}
}

.custom-switch {
	--gap: 4px;
	--active-color: #2F3249;
	position: relative;
	z-index: 1;
	display: grid;
	margin: 0;
	grid-template-columns: 1fr 1fr;
	gap: var(--gap);
	padding: var(--gap);
	border: 1px solid currentColor;
	border-radius: 5px;
	cursor: pointer
}

.custom-switch input {
	opacity: 0;
	position: absolute;
	top: -1px;
	left: -1px;
	width: 1px;
	height: 1px
}

.custom-switch .focus-outline {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: -1;
	border-radius: inherit
}

.custom-switch input:active~.focus-outline,
.custom-switch input:focus~.focus-outline {
	outline: 1px solid #3952af;
	outline-offset: 2px
}

.custom-switch .label-off,
.custom-switch .label-on {
	padding: .25em 1em;
	position: relative;
	z-index: 1;
	transition: all .3s ease;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.custom-switch .label-off {
	color: var(--active-color)
}

.custom-switch .indicator {
	position: absolute;
	width: calc(50% - 1.5 * var(--gap));
	top: var(--gap);
	bottom: var(--gap);
	left: var(--gap);
	background-color: #ffdd4a;
	z-index: 0;
	transition: all .3s ease;
	border-radius: 3px
}

.custom-switch input:checked~.label-on {
	color: var(--active-color)
}

.custom-switch input:checked~.label-off {
	color: inherit
}

.custom-switch input:checked~.indicator {
	left: calc(50% + .5 * var(--gap))
}

.price-switch-badge {
	font-size: 12px;
	line-height: 1;
	padding: .4167em .75em .4167em 1.5em;
	background-color: #ffdd4a;
	color: #2f3249;
	border-radius: 3px;
	-webkit-clip-path: url(#arrow);
	clip-path: url(#arrow);
	display: block;
	white-space: nowrap
}

@media (max-width:767.98px) {
	.price-switch-badge {
		-webkit-clip-path: none;
		clip-path: none
	}

	.price-switch-badge::before {
		content: "";
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #ffdd4a;
		position: absolute;
		bottom: 100%;
		left: 75%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%)
	}
}

.price-switch-wrap {
	--gutter: 1.5rem;
	padding: 0 var(--gutter);
	position: relative
}

.price-switch-wrap .price-switch-badge-wrap {
	padding-right: var(--gutter);
	background-color: inherit;
	position: absolute;
	top: 50%;
	left: 100%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

@media (max-width:767.98px) {
	.price-switch-wrap .price-switch-badge-wrap {
		display: block;
		position: relative;
		left: auto;
		top: auto;
		padding: 0;
		-webkit-transform: none;
		transform: none;
		text-align: center;
		margin-top: 1.25rem
	}
}

.search-form {
	position: relative;
	padding-left: 2.8rem;
	transition: all .2s ease-out;
	background: #eff4f9;
	color: #fff;
	overflow: hidden;
	border-radius: 10rem;
	width: 520px;
	max-width: 100%
}

@media (max-width:767.98px) {
	.search-form {
		padding-left: 1.5rem
	}
}

.search-form .form-control {
	height: 60px !important;
	width: 100%;
	border-radius: 0;
	border: none;
	background: 0 0;
	opacity: 1;
	padding-left: 0;
	height: 2.8rem;
	padding-top: 0;
	padding-bottom: 0;
	color: #2f3249;
	border: 0 !important
}

.search-form .form-control:active,
.search-form .form-control:focus {
	box-shadow: none
}

.search-form .search-form-submit {
	position: absolute;
	width: 60px;
	height: 60px;
	padding: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: 0 0;
	border: none;
	border-radius: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #5dd39e
}

.search-form .search-form-submit:active,
.search-form .search-form-submit:focus {
	outline: 0;
	box-shadow: none
}

.market-data-search {
	max-width: 690px;
	margin: 0 auto;
	margin-top: -2.21875rem
}

.market-data-search .input-group {
	position: relative
}

.market-data-search .input-group-prepend {
	padding: 0 1.25rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #f6f9ff;
	color: #2f3249;
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.market-data-search .input-group .icon {
	font-size: 1rem
}

.market-data-search .form-control {
	border-color: #f6f9ff;
	background-color: #f6f9ff;
	padding-left: 0;
	font-weight: 700
}

.discount-label {
	padding: 0 6px 0 14px;
	color: #fff;
	text-decoration: none;
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
	position: relative;
	font-size: 12px;
	color: #2f3249;
	font-weight: 700;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 24px;
	-ms-flex-align: center;
	align-items: center;
	background-image: url("data:image/svg+xml,%3Csvg width='65' height='20' viewBox='0 0 65 20' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cpath d='M8.31565 1.7425C9.43963 0.626376 10.9594 0 12.5434 0H59C62.3137 0 65 2.68629 65 6V14C65 17.3137 62.3137 20 59 20H12.5434C10.9594 20 9.43964 19.3736 8.31565 18.2575L0 10L8.31565 1.7425Z' fill='%23FFDD4A'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%
}

@media (max-width:575.98px) {
	.discount-label {
		padding: 0 4px 0 10px;
		height: 18px
	}
}

.info-label {
	height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 1rem;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px
}

.swiper-arrow {
	width: 50px;
	height: 50px;
	border: 1px solid #e4e4eb;
	border-radius: 50%;
	padding: 0;
	background-color: transparent;
	color: #2f3249;
	transition: all .3s ease;
	position: absolute;
	outline: 0 !important
}

.swiper-arrow:hover {
	background-color: rgba(47, 50, 73, .1)
}

.swiper-arrow[data-focus-method=key] {
	transition-duration: 0s !important
}

.swiper-arrow[data-focus-method=key]:active,
.swiper-arrow[data-focus-method=key]:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af !important
}

.swiper-arrow:after {
	display: none
}

.swiper-pagination {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%
}

.swiper-pagination-bullet {
	margin: 0 .35rem;
	outline: 0 !important;
	opacity: 1;
	background-color: #eff4f9
}

.swiper-pagination-bullet-active {
	background-color: #50575e
}

.swiper-pagination-bullet:active,
.swiper-pagination-bullet:focus {
	outline-offset: 2px;
	outline: 1px solid #3952af !important
}

.image-slider.-disabled .swiper-wrapper {
	display: -ms-flexbox;
	display: flex
}

.image-slider.-disabled .swiper-slide {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.screen-slider {
	width: 1100px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 3;
	padding-left: 4rem;
	padding-right: 4rem
}

@media (min-width:1200px) {
	.screen-slider--with-phone {
		-webkit-transform: translateX(-4rem);
		transform: translateX(-4rem)
	}
}

@media (max-width:1479.98px) {
	.screen-slider {
		width: 1000px
	}
}

@media (max-width:767.98px) {
	.screen-slider {
		padding-left: 1rem;
		padding-right: 1rem
	}
}

@media (max-width:575.98px) {
	.screen-slider {
		padding-left: 0;
		padding-right: 0
	}
}

.screen-slider .swiper-container:not(.swiper-container-initialized) .swiper-wrapper {
	display: -ms-flexbox;
	display: flex
}

.screen-slider .swiper-container:not(.swiper-container-initialized) .swiper-slide {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.screen-slider-slide {
	margin-left: auto;
	margin-right: auto
}

.screen-slider-slide img {
	opacity: 0;
	transition: all .4s 0s cubic-bezier(0, 0, .5, 1);
	-webkit-transform-origin: center center;
	transform-origin: center center
}

.screen-slider-slide img.swiper-lazy-loaded {
	opacity: 1
}

.screen-slider-slide.swiper-slide-active img {
	transition: all .7s .1s cubic-bezier(0, 0, .5, 1)
}

.screen-slider .swiper-pagination-bullets {
	position: absolute;
	top: 100%;
	width: 100%;
	left: 0;
	right: 0
}

@media (max-width:575.98px) {
	.screen-slider .swiper-pagination-bullets {
		display: none
	}
}

.screen-slider .swiper-pagination-bullet-active {
	background-color: #5dd39e
}

.screen-slider .swiper-arrow {
	width: 40px;
	height: 40px;
	color: #fff;
	position: absolute;
	bottom: 3rem;
	top: auto;
	left: 50%;
	right: auto;
	background: rgba(47, 50, 73, .15);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px)
}

@media (max-width:991.98px) {
	.screen-slider .swiper-arrow {
		bottom: 2rem
	}
}

@media (max-width:767.98px) {
	.screen-slider .swiper-arrow {
		bottom: 1.25rem
	}
}

.screen-slider .swiper-arrow.swiper-button-prev {
	-webkit-transform: translateX(calc(-100% - .5rem));
	transform: translateX(calc(-100% - .5rem))
}

.screen-slider .swiper-arrow.swiper-button-next {
	-webkit-transform: translateX(.5rem);
	transform: translateX(.5rem)
}

@media (min-width:1200px) {
	.screen-slider--with-phone .swiper-arrow {
		left: calc(50% + 4rem)
	}
}

.screen-slider .mobile-slider-container,
.screen-slider .mobile-slider-facade {
	width: 200px;
	position: absolute;
	bottom: 0;
	right: -4rem;
	z-index: 10;
	-webkit-transform: translateY(20px);
	transform: translateY(20px)
}

@media (max-width:1199.98px) {

	.screen-slider .mobile-slider-container,
	.screen-slider .mobile-slider-facade {
		width: 180px;
		right: -.5rem
	}
}

@media (max-width:991.98px) {

	.screen-slider .mobile-slider-container,
	.screen-slider .mobile-slider-facade {
		width: 120px
	}
}

@media (max-width:767.98px) {

	.screen-slider .mobile-slider-container,
	.screen-slider .mobile-slider-facade {
		width: 100px;
		right: -.75rem
	}
}

@media (max-width:575.98px) {

	.screen-slider .mobile-slider-container,
	.screen-slider .mobile-slider-facade {
		width: 22vw
	}
}

.screen-slider .mobile-slider-container:not(.swiper-container-initialized) .swiper-wrapper,
.screen-slider .mobile-slider-facade:not(.swiper-container-initialized) .swiper-wrapper {
	display: -ms-flexbox;
	display: flex
}

.screen-slider .mobile-slider-container:not(.swiper-container-initialized) .swiper-slide,
.screen-slider .mobile-slider-facade:not(.swiper-container-initialized) .swiper-slide {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.screen-slider .mobile-slider-container img,
.screen-slider .mobile-slider-facade img {
	opacity: 0;
	transition: all .4s 0s cubic-bezier(0, 0, .5, 1);
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center
}

.screen-slider .mobile-slider-container img.swiper-lazy-loaded,
.screen-slider .mobile-slider-facade img.swiper-lazy-loaded {
	opacity: 1
}

.slider-testimonials {
	overflow: hidden;
	max-width: 100%;
	border-radius: 10px;
	box-shadow: 0 0 46px rgba(0, 0, 0, .08);
	position: relative;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	height: 250px;
	transition: opacity .5s ease, visibility .5s ease
}

.slider-testimonials.swiper-container-initialized {
	opacity: 1;
	visibility: visible;
	height: auto
}

.slider-testimonials-arrow {
	width: 92px;
	height: 92px;
	background-color: transparent;
	border-radius: 50%;
	position: absolute;
	z-index: 4;
	top: 50%;
	margin-top: -46px;
	border: 0;
	z-index: 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	transition: all .3s ease;
	outline: 0 !important
}

@media (max-width:575.98px) {
	.slider-testimonials-arrow {
		width: 60px;
		height: 60px;
		margin-top: -30px
	}
}

.slider-testimonials-arrow.prev {
	left: -2.25rem;
	padding-right: calc(92px - 2.5rem)
}

@media (max-width:575.98px) {
	.slider-testimonials-arrow.prev {
		left: -1.65rem;
		padding-right: calc(60px - 1.75rem)
	}
}

.slider-testimonials-arrow.prev:hover {
	-webkit-transform: translateX(-.25rem);
	transform: translateX(-.25rem)
}

.slider-testimonials-arrow.next {
	right: -2.25rem;
	padding-left: calc(92px - 2.5rem)
}

@media (max-width:575.98px) {
	.slider-testimonials-arrow.next {
		right: -1.65rem;
		padding-left: calc(60px - 1.75rem)
	}
}

.slider-testimonials-arrow.next:hover {
	-webkit-transform: translateX(.25rem);
	transform: translateX(.25rem)
}

.slider-testimonials-pagination {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3
}

.slider-testimonials-pagination .swiper-pagination-bullet {
	background-color: #eff4f9
}

.slider-testimonials-pagination .swiper-pagination-bullet-active {
	background-color: #5dd39e
}

.slider-testimonials-quote {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 35px;
	z-index: 3
}

.slider-testimonials-wide {
	--gap: 16px;
	--slides: 1;
	margin-bottom: 200px;
	margin-top: 30px
}

@media (max-width:575.98px) {
	.slider-testimonials-wide {
		padding: 0 50px
	}
}

@media (min-width:576px) {
	.slider-testimonials-wide {
		--slides: 2
	}
}

@media (min-width:992px) {
	.slider-testimonials-wide {
		--slides: 3
	}
}

@media (min-width:1200px) {
	.slider-testimonials-wide {
		--slides: 4;
		--gap: 28px
	}
}

.slider-testimonials-wide.-disabled {
	overflow: hidden
}

.slider-testimonials-wide.-disabled .swiper-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.slider-testimonials-wide.-disabled .swiper-slide {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: calc(100% / var(--slides) - var(--gap) * (var(--slides) - 1)/ var(--slides));
	margin-right: var(--gap)
}

.slider-testimonials-wide.-disabled~.swiper-button {
	opacity: 0;
	position: absolute
}

.slider-testimonials-wide-wrapper {
	margin-bottom: -200px;
	margin-top: -30px;
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: visible
}

.slider-testimonials-wide .swiper-wrapper {
	-ms-flex-align: stretch;
	align-items: stretch
}

.slider-testimonials-wide .swiper-wrapper .swiper-slide {
	height: auto
}

.slider-partners {
	--gap: 16px;
	--slides: 3
}

@media (min-width:576px) {
	.slider-partners {
		--slides: 4
	}
}

@media (min-width:992px) {
	.slider-partners {
		--slides: 5;
		--gap: 32px
	}
}

@media (min-width:1200px) {
	.slider-partners {
		--slides: 7;
		--gap: 32px
	}
}

.slider-partners.-disabled {
	overflow: hidden
}

.slider-partners.-disabled .swiper-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.slider-partners.-disabled .swiper-slide {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: calc(100% / var(--slides) - var(--gap) * (var(--slides) - 1)/ var(--slides));
	margin-right: var(--gap)
}

.slider-partners .swiper-wrapper {
	-ms-flex-align: center;
	align-items: center;
	transition-timing-function: linear
}

.slider-partners .swiper-slide img {
	display: block;
	margin: 0 auto
}

.swiper-vertical-screenshots {
	--ratio: 52%;
	width: 100%;
	max-width: 670px;
	margin: 0 auto;
	position: relative !important;
	overflow: visible !important
}

@media (max-width:991.98px) {
	.swiper-vertical-screenshots {
		max-width: 480px
	}
}

.swiper-vertical-screenshots::before {
	content: "";
	width: 100%;
	display: block;
	padding-top: var(--ratio)
}

.swiper-vertical-screenshots.-disabled {
	overflow: hidden !important
}

.swiper-vertical-screenshots.-disabled .swiper-slide {
	transition: none !important
}

.swiper-vertical-screenshots .swiper-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%
}

.swiper-vertical-screenshots .swiper-slide .inner {
	background-color: #282a3d;
	border-radius: .75rem;
	transition: all .5s ease-in-out;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0
}

.swiper-vertical-screenshots .swiper-slide::before {
	content: "";
	width: 100%;
	display: block;
	padding-top: var(--ratio)
}

.swiper-vertical-screenshots .swiper-slide img {
	position: absolute;
	width: calc(100% - 1.5rem);
	height: calc(100% - 1.5rem);
	top: .75rem;
	left: .75rem;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: .25rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.swiper-vertical-screenshots .swiper-slide.swiper-slide-active {
	z-index: 20
}

.swiper-vertical-screenshots .swiper-slide.swiper-slide-next,
.swiper-vertical-screenshots .swiper-slide.swiper-slide-prev {
	opacity: .35 !important
}

.swiper-vertical-screenshots .swiper-slide:not(.swiper-slide-active) .inner {
	-webkit-transform: scale(.875) translateY(-21%);
	transform: scale(.875) translateY(-21%)
}

.swiper-vertical-screenshots .swiper-slide.swiper-slide-active~.swiper-slide .inner {
	-webkit-transform: scale(.875) translateY(21%);
	transform: scale(.875) translateY(21%)
}

.video-library {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.video-library-navigation {
	width: 400px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: -2rem;
	padding: 1.5rem 2rem 1.5rem 4rem;
	height: 430px
}

@media (max-width:1479.98px) {
	.video-library-navigation {
		width: 340px;
		height: 384px
	}
}

@media (max-width:1199.98px) {
	.video-library-navigation {
		width: 300px;
		height: 350px
	}
}

@media (max-width:991.98px) {
	.video-library-navigation {
		width: 100%;
		height: 330px;
		margin-left: 0;
		padding: 1.5rem
	}
}

.video-library-player {
	-ms-flex-positive: 1;
	flex-grow: 1
}

@media (max-width:991.98px) {
	.video-library-player {
		width: 100%;
		margin-bottom: 2rem
	}
}

.video-library-navigation {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	border-top-right-radius: .6125rem;
	border-bottom-right-radius: .6125rem;
	overflow: hidden
}

@media (max-width:991.98px) {
	.video-library-navigation {
		border-radius: .6125rem
	}
}

.video-library-navigation .navigation-header+.navigation-contents {
	margin-top: 1rem
}

.video-library-navigation .navigation-contents {
	max-height: 100%;
	overflow: auto;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-size: 1.125rem;
	scrollbar-color: #2f3249 rgba(47, 50, 73, .05);
	scrollbar-width: thin
}

@media (max-width:1479.98px) {
	.video-library-navigation .navigation-contents {
		font-size: 1rem
	}
}

.video-library-navigation .navigation-contents::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: rgba(47, 50, 73, .05)
}

.video-library-navigation .navigation-contents::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(47, 50, 73, .05)
}

.video-library-navigation .navigation-contents::-webkit-scrollbar-thumb {
	background-color: #5dd39e;
	border: none
}

.video-library-navigation .navigation-contents a {
	color: inherit;
	border-bottom: 2px solid transparent;
	transition: all .3s ease
}

.video-library-navigation .navigation-contents a.is-active,
.video-library-navigation .navigation-contents a:hover {
	border-color: currentColor;
	text-decoration: none
}

.video-library-navigation .navigation-contents li {
	padding-right: 1rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: baseline;
	align-items: baseline
}

.video-library-navigation .navigation-contents li:not(:last-child) {
	margin-bottom: .6em
}

.video-library-navigation .navigation-contents li .time {
	margin-left: auto;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	font-size: 1rem
}

.video-library-navigation .navigation-contents li .time:not(:empty) {
	padding-left: 1em
}

.video-library-description {
	width: 100%;
	padding: 3rem 2rem 0
}

@media (max-width:991.98px) {
	.video-library-description {
		padding: 2rem 0 0
	}
}

.video-library-description .text-formatted {
	color: #6c7a88;
	line-height: 1.5
}

@media (min-width:1200px) {
	.video-library-description .text-formatted {
		padding-right: 4rem
	}
}

@media (max-width:991.98px) {
	.video-library-description .text-formatted {
		font-size: 1rem
	}
}

.video-library-description .history {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.3
}

.video-library-description .expand {
	display: none !important
}

.video-library-description .expand img {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.video-library-description.has-expand .expand {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important
}

.video-library-description.has-expand:not(.is-expanded) .text-formatted>:not(:first-child) {
	display: none
}

.video-library-description.is-expanded .expand {
	margin-top: 1rem
}

.video-library-description.is-expanded .expand img {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.video-preview {
	z-index: 3;
	cursor: pointer
}

.video-preview img {
	-o-object-fit: cover;
	object-fit: cover
}

.video-preview:after {
	content: "";
	display: block;
	width: 3.1rem;
	height: 3.1rem;
	z-index: 3;
	border-radius: 50%;
	background-color: #fff;
	background-image: url(../images/icons/icon-play.svg);
	background-repeat: no-repeat;
	background-position: 55% center;
	background-size: 30% auto;
	transition: all .2s
}

.video-preview-lg:after {
	width: 5.4rem;
	height: 5.4rem
}

.video-preview:hover:after {
	-webkit-transform: translate(-50%, -50%) scale(1.1);
	transform: translate(-50%, -50%) scale(1.1)
}

@media (max-width:991.98px) {
	.video-preview:hover:after {
		font-size: 1rem;
		line-height: 1.6
	}
}

.masonry-grid {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	margin: 0 -1rem
}

@media (min-width:576px) and (max-width:767.98px) {
	.masonry-grid {
		margin: 0 -1.25rem !important
	}
}

.masonry-grid .grid-item,
.masonry-grid .grid-sizer {
	margin: 1rem 0
}

@media (min-width:576px) and (max-width:767.98px) {

	.masonry-grid .grid-item,
	.masonry-grid .grid-sizer {
		margin: .25rem 0;
		padding: 0 .25rem !important
	}
}

.masonry-filters {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0 -1rem 1.5rem;
	font-size: 1.25rem
}

@media (max-width:767.98px) {
	.masonry-filters {
		font-size: 1rem;
		margin: 0 -.5rem 1.5rem;
		-ms-flex-pack: center;
		justify-content: center
	}
}

.masonry-filters li {
	padding: 0 1rem
}

.masonry-filters a {
	color: inherit
}

.masonry-filters a.is-active {
	font-weight: 700
}

.chart-analysis {
	transition: all .5s ease
}

.chart-analysis.is-loading {
	pointer-events: none;
	opacity: .75
}

.chart-analysis #chart-image {
	opacity: 0;
	visibility: hidden;
	transition: all .5s ease
}

.chart-analysis #chart-image.is-loaded {
	opacity: 1;
	visibility: visible
}

.chart-compare-filters {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.chart-compare-filters .form-control {
	height: 39px;
	border-color: #e3e3e3;
	padding: 0 .75rem;
	font-size: 1rem;
	max-width: 100%
}

@media (max-width:991.98px) {
	.chart-compare-filters .form-control {
		height: 34px;
		font-size: .875rem;
		padding: 0 .5rem
	}
}

@media (max-width:420px) {
	.chart-compare-filters .form-control {
		font-size: 12px
	}
}

@media (max-width:767.98px) {
	.chart-compare-filters .form-group label {
		width: 100%
	}
}

.chart-compare-filters .btn {
	padding-top: .51875rem;
	padding-bottom: .51875rem;
	margin-bottom: 0
}

@media (max-width:991.98px) {
	.chart-compare-filters .btn {
		padding-top: .35rem;
		padding-bottom: .35rem
	}
}

.chart-compare-filters #zoom {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.25rem;
	opacity: 0
}

.chart-compare-filters #zoom+.btn .-on {
	display: none
}

.chart-compare-filters #zoom+.btn .-off {
	display: inline
}

.chart-compare-filters #zoom:checked+.btn .-on {
	display: inline
}

.chart-compare-filters #zoom:checked+.btn .-off {
	display: none
}

.chart-compare-images {
	position: relative;
	border-color: #e3e3e3 !important;
	opacity: 0;
	visibility: hidden;
	transition: opacity .5s ease, visibility .5s ease
}

.chart-compare-images.is-loading {
	height: auto !important
}

.chart-compare-images.is-loading::before {
	content: "";
	display: block;
	padding-top: 63.84%;
	width: 100%
}

.chart-compare-images.is-visible {
	opacity: 1;
	visibility: visible
}

.chart-compare-images.is-zoomed img {
	max-width: 250%
}

.chart-compare-images .twentytwenty-left-arrow {
	border-right-color: #2e843f !important
}

.chart-compare-images .twentytwenty-right-arrow {
	border-left-color: #2e843f !important
}

@media (max-width:767.98px) {
	.partners {
		overflow-x: auto
	}
}

.partners .table,
.partners .text-formatted table,
.text-formatted .partners table {
	margin-bottom: 0;
	max-width: 100%;
	width: 100% !important;
	table-layout: fixed
}

@media (max-width:1199.98px) {

	.partners .table,
	.partners .text-formatted table,
	.text-formatted .partners table {
		font-size: .875rem
	}
}

@media (max-width:991.98px) {

	.partners .table,
	.partners .text-formatted table,
	.text-formatted .partners table {
		font-size: 12px
	}
}

@media (max-width:767.98px) {

	.partners .table,
	.partners .text-formatted table,
	.text-formatted .partners table {
		width: 630px !important;
		max-width: 630px
	}
}

.partners .table .name,
.partners .text-formatted table .name,
.text-formatted .partners table .name {
	width: 20.6%
}

.partners .table .level,
.partners .text-formatted table .level,
.text-formatted .partners table .level {
	width: 14.9%
}

.partners .table .services,
.partners .text-formatted table .services,
.text-formatted .partners table .services {
	width: 27.6%
}

.partners .table .style,
.partners .text-formatted table .style,
.text-formatted .partners table .style {
	width: 19.7%
}

.partners .table .assets,
.partners .text-formatted table .assets,
.text-formatted .partners table .assets {
	width: 16.6%
}

.partners .table thead,
.partners .text-formatted table thead,
.text-formatted .partners table thead {
	border-radius: 6px;
	overflow: hidden;
	--border-radius: 6px
}

.partners .table thead td,
.partners .table thead th,
.partners .text-formatted table thead td,
.partners .text-formatted table thead th,
.text-formatted .partners table thead td,
.text-formatted .partners table thead th {
	padding: .875em;
	border: 0
}

.partners .table thead .labels,
.partners .text-formatted table thead .labels,
.text-formatted .partners table thead .labels {
	text-transform: uppercase;
	background-color: #2f3249;
	color: #fff
}

.partners .table thead .labels th,
.partners .text-formatted table thead .labels th,
.text-formatted .partners table thead .labels th {
	padding: .5em .875em;
	cursor: pointer;
	font-weight: 400
}

.partners .table thead .labels th:focus,
.partners .text-formatted table thead .labels th:focus,
.text-formatted .partners table thead .labels th:focus {
	outline: 0
}

.partners .table thead .labels th:first-child,
.partners .text-formatted table thead .labels th:first-child,
.text-formatted .partners table thead .labels th:first-child {
	border-top-left-radius: var(--border-radius)
}

.partners .table thead .labels th:last-child,
.partners .text-formatted table thead .labels th:last-child,
.text-formatted .partners table thead .labels th:last-child {
	border-top-right-radius: var(--border-radius)
}

.partners .table thead .labels th.sorting::after,
.partners .table thead .labels th.sorting_asc::after,
.partners .table thead .labels th.sorting_desc::after,
.partners .text-formatted table thead .labels th.sorting::after,
.partners .text-formatted table thead .labels th.sorting_asc::after,
.partners .text-formatted table thead .labels th.sorting_desc::after,
.text-formatted .partners table thead .labels th.sorting::after,
.text-formatted .partners table thead .labels th.sorting_asc::after,
.text-formatted .partners table thead .labels th.sorting_desc::after {
	content: "";
	width: 5px;
	height: 13px;
	display: inline-block;
	vertical-align: -.1em;
	margin-left: .5em;
	transition: all .3s ease;
	background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0l2.165 4.583H.335L2.5 0z' fill='%23ffffff'/%3E%3Cpath d='M2.5 12.111L.335 7.528h4.33L2.5 12.11z' fill='%23ffffff'/%3E%3C/svg%3E")
}

.partners .table thead .labels th.sorting_asc::after,
.partners .text-formatted table thead .labels th.sorting_asc::after,
.text-formatted .partners table thead .labels th.sorting_asc::after {
	background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0l2.165 4.583H.335L2.5 0z' fill='%235DD39E'/%3E%3Cpath d='M2.5 12.111L.335 7.528h4.33L2.5 12.11z' fill='transparent'/%3E%3C/svg%3E")
}

.partners .table thead .labels th.sorting_desc::after,
.partners .text-formatted table thead .labels th.sorting_desc::after,
.text-formatted .partners table thead .labels th.sorting_desc::after {
	background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0l2.165 4.583H.335L2.5 0z' fill='transparent'/%3E%3Cpath d='M2.5 12.111L.335 7.528h4.33L2.5 12.11z' fill='%235DD39E'/%3E%3C/svg%3E")
}

.partners .table thead .labels th.sorting_asc,
.partners .table thead .labels th.sorting_desc,
.partners .text-formatted table thead .labels th.sorting_asc,
.partners .text-formatted table thead .labels th.sorting_desc,
.text-formatted .partners table thead .labels th.sorting_asc,
.text-formatted .partners table thead .labels th.sorting_desc {
	background-color: #252739
}

.partners .table thead .filters,
.partners .text-formatted table thead .filters,
.text-formatted .partners table thead .filters {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 0 38.4701px rgba(47, 50, 73, .11)
}

.partners .table thead .filters td:first-child,
.partners .text-formatted table thead .filters td:first-child,
.text-formatted .partners table thead .filters td:first-child {
	border-bottom-left-radius: var(--border-radius)
}

.partners .table thead .filters td:last-child,
.partners .text-formatted table thead .filters td:last-child,
.text-formatted .partners table thead .filters td:last-child {
	border-bottom-right-radius: var(--border-radius)
}

.partners .table thead .form-control,
.partners .text-formatted table thead .form-control,
.text-formatted .partners table thead .form-control {
	height: 2.5em;
	padding: 0 .75em;
	font-size: 1em
}

.partners .table tbody td,
.partners .text-formatted table tbody td,
.text-formatted .partners table tbody td {
	border: 1px solid #f4f5f7;
	line-height: 1.25;
	padding: 1em .875em
}

.partners .table tbody td:nth-child(2),
.partners .text-formatted table tbody td:nth-child(2),
.text-formatted .partners table tbody td:nth-child(2) {
	text-transform: capitalize
}

.partners .table tbody tr:first-child td,
.partners .text-formatted table tbody tr:first-child td,
.text-formatted .partners table tbody tr:first-child td {
	border-top: 0
}

.partners .table tbody tr.odd,
.partners .text-formatted table tbody tr.odd,
.text-formatted .partners table tbody tr.odd {
	background-color: #f9fafe
}

.partners .table tbody tr.odd td.sorting_1,
.partners .table tbody tr.odd:hover,
.partners .text-formatted table tbody tr.odd td.sorting_1,
.partners .text-formatted table tbody tr.odd:hover,
.text-formatted .partners table tbody tr.odd td.sorting_1,
.text-formatted .partners table tbody tr.odd:hover {
	background-color: #f0f3fd
}

.partners .table tbody tr.even,
.partners .text-formatted table tbody tr.even,
.text-formatted .partners table tbody tr.even {
	background-color: #fff
}

.partners .table tbody tr.even td.sorting_1,
.partners .table tbody tr.even:hover,
.partners .text-formatted table tbody tr.even td.sorting_1,
.partners .text-formatted table tbody tr.even:hover,
.text-formatted .partners table tbody tr.even td.sorting_1,
.text-formatted .partners table tbody tr.even:hover {
	background-color: #fafafa
}

.partners .table tbody a,
.partners .text-formatted table tbody a,
.text-formatted .partners table tbody a {
	font-weight: 700;
	font-size: 1.25em;
	color: #3e425c
}

.partners .table tbody a:hover,
.partners .text-formatted table tbody a:hover,
.text-formatted .partners table tbody a:hover {
	text-decoration: underline
}

.partners .dataTables_filter,
.partners .dataTables_info {
	display: none
}

.partner-info {
	max-width: 1120px !important;
	border-radius: .75rem
}

@media (max-width:767.98px) {
	.partner-info {
		max-width: 360px;
		padding-left: 1.5rem;
		padding-right: 1.5rem
	}
}

.partner-info__logo img {
	max-width: 220px
}

.partner-info__type {
	line-height: 1.25
}

.partner-info__link {
	font-size: 1.125rem
}

.partner-info__link a {
	color: #288bc3;
	text-decoration: underline
}

.partner-info__level {
	display: inline-block;
	background-color: #fff;
	border: 2px solid #2f3249;
	border-radius: 6px;
	text-transform: uppercase;
	min-width: 170px
}

.partner-info__level img {
	height: 1em;
	width: auto;
	margin: .5em 0
}

.partner-info__level span {
	background-color: #2f3249;
	color: #fff;
	display: block;
	line-height: 1;
	padding: .5em 0
}

.partner-info__links {
	font-weight: 700
}

.partner-info__links a {
	color: #5dd39e
}

.partner-info__links .icon {
	color: #5dd39e
}

.partner-info__description ol,
.partner-info__description ul {
	padding-left: 1.25em
}

.partner-info__description img,
.partner-info__description video {
	max-width: 100%;
	height: auto
}

.cta-bill-of-right {
	background-color: #f8fafc;
	border-radius: .75rem;
	padding: 40px 0;
	position: relative;
	overflow: hidden;
	z-index: 1
}

@media (max-width:1479.98px) {
	.cta-bill-of-right {
		padding: 2.5rem 1rem
	}
}

@media (max-width:575.98px) {
	.cta-bill-of-right {
		padding-left: 0;
		padding-right: 0
	}
}

.cta-bill-of-right::after {
	content: "";
	width: 650px;
	height: 650px;
	border: 145px solid #2f3249;
	position: absolute;
	right: -150px;
	bottom: -50px;
	border-radius: 50%;
	z-index: -1;
	pointer-events: none
}

@media (max-width:1479.98px) {
	.cta-bill-of-right::after {
		width: 520px;
		height: 520px;
		border-width: 120px;
		right: -125px;
		bottom: -40px
	}
}

@media (max-width:991.98px) {
	.cta-bill-of-right::after {
		width: 450px;
		height: 450px;
		border-width: 100px;
		right: -110px;
		bottom: -35px
	}
}

@media (max-width:767.98px) {
	.cta-bill-of-right::after {
		width: 50vw;
		height: 50vw;
		border-width: 12.5vw;
		right: -21vw;
		bottom: auto;
		top: -21vw
	}
}

.cta-subscribe {
	padding: 50px 0;
	background-color: #fff;
	border-radius: .75rem
}

@media (max-width:1479.98px) {
	.cta-subscribe {
		padding: 40px 1rem
	}
}

@media (max-width:575.98px) {
	.cta-subscribe {
		padding: 2.5rem 0 2rem
	}
}

.cta-subscribe .subscribe-form {
	margin-right: 0
}

@media (max-width:1199.98px) {
	.cta-subscribe .subscribe-form {
		margin: 0 auto
	}
}

.cta-apply {
	padding: 50px 0;
	background-color: #fff;
	border-radius: .75rem;
	position: relative
}

@media (max-width:1479.98px) {
	.cta-apply {
		padding: 40px 1rem
	}
}

@media (max-width:575.98px) {
	.cta-apply {
		padding: 2.5rem 0 2rem
	}
}

.cta-mobile-app {
	padding: 60px 3rem;
	background-color: #f9fafc;
	border-radius: .75rem;
	position: relative
}

@media (max-width:1199.98px) {
	.cta-mobile-app {
		padding-top: 150px;
		margin-top: 120px
	}
}

@media (max-width:575.98px) {
	.cta-mobile-app {
		padding-left: 1rem;
		padding-right: 1rem
	}
}

.cta-mobile-app .h1 {
	position: relative
}

.cta-mobile-app-phone {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-40%, -50%);
	transform: translate(-40%, -50%);
	width: 340px;
	height: auto;
	-webkit-filter: drop-shadow(-10px 10px 20px rgba(47, 50, 73, .25));
	filter: drop-shadow(-10px 10px 20px rgba(47, 50, 73, .25))
}

@media (max-width:1479.98px) {
	.cta-mobile-app-phone {
		width: 300px;
		-webkit-transform: translate(-45%, -50%);
		transform: translate(-45%, -50%)
	}
}

@media (max-width:1199.98px) {
	.cta-mobile-app-phone {
		height: 240px;
		width: auto;
		top: 0;
		-webkit-transform: translate(-50%, -120px);
		transform: translate(-50%, -120px)
	}
}

.cta-mobile-app .badge-image {
	height: 60px
}

@media (max-width:1479.98px) {
	.cta-mobile-app .badge-image {
		height: 52px
	}
}

@media (max-width:575.98px) {
	.cta-mobile-app .badge-image {
		height: 36px
	}
}

.list-menu {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.list-menu-item:not(:last-child) {
	margin-bottom: .25em
}

.list-menu a {
	color: inherit
}

.list-menu a:hover {
	text-decoration: underline
}

.list-menu a .icon {
	font-size: 75%;
	margin-left: .25em
}

.legal-menu {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -.5rem;
	padding: 0;
	list-style: none
}

.legal-menu li {
	padding: 0 .5rem
}

.legal-menu a {
	color: inherit;
	text-decoration: underline
}

.legal-menu a:hover {
	text-decoration: underline
}

.fluid-form {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 130%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	pointer-events: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

.fluid-form svg {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	margin: auto;
	display: block
}

.fluid-form svg .fluid-path {
	fill: currentColor !important
}

.modal-popup {
	display: none;
	width: 100%;
	max-width: 640px !important;
	border-radius: .375rem
}

@media (max-width:767.98px) {
	.modal-popup {
		padding-left: 1.5rem;
		padding-right: 1.5rem
	}
}

.modal-popup.modal-form {
	max-width: 540px !important
}

.modal-popup--wide {
	max-width: 800px !important
}

.modal-popup--xl-wide {
	max-width: 900px !important
}

.modal-popup .modal-header {
	margin-bottom: 1rem;
	text-align: center
}

.modal-popup .text-formatted {
	line-height: 1.6
}

.modal-popup .ob-table_col-heading {
	border-bottom: 1px solid #eff4f9 !important
}

.ts-popup__offer {
	position: relative;
	width: 100%;
	max-width: 580px;
	margin: 0 auto;
	background-color: rgba(7, 8, 8, .07);
	border-radius: .5rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	line-height: 1.25;
	font-size: 1.125rem
}

@media (max-width:575.98px) {
	.ts-popup__offer {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		background-color: transparent
	}
}

.ts-popup__offer__label {
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding: .25rem 1rem
}

@media (max-width:575.98px) {
	.ts-popup__offer__label {
		width: 100%;
		padding: 1rem;
		margin-bottom: .5rem;
		background-color: rgba(38, 40, 57, .07);
		border-radius: .5rem;
		text-align: center
	}
}

.ts-popup__offer__button {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	background-color: #3e425c;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	padding: .75rem 1.25rem;
	border-radius: .5rem;
	position: relative;
	cursor: pointer
}

@media (max-width:575.98px) {
	.ts-popup__offer__button {
		width: 100%;
		text-align: center
	}
}

.ts-popup__offer__button:after {
	content: "";
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	position: absolute;
	top: 4px;
	left: 4px;
	pointer-events: none;
	border-radius: .4rem;
	border: 1px dashed currentColor;
	opacity: .5;
	z-index: 0
}

.montly-to-yearly {
	--columns: 3;
	max-width: 735px;
	margin: 0 auto;
	display: grid;
	gap: .75rem;
	grid-template-columns: repeat(var(--columns), 1fr)
}

@media (max-width:767.98px) {
	.montly-to-yearly {
		--columns: 1;
		gap: 2rem
	}
}

.montly-to-yearly.-show-2 {
	max-width: 500px
}

@media (min-width:576px) {
	.montly-to-yearly.-show-2 {
		--columns: 2;
		gap: .75rem
	}
}

.mty-box {
	background-color: #f7f7f7;
	border-radius: 10px;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
	padding: 2rem .75rem .75rem
}

.mty-box.-yearly {
	background-color: #444969;
	color: #fff
}

.mty-box.-yearly::before {
	content: "";
	width: 2rem;
	height: 2rem;
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.326 17.248a.75.75 0 0 1-.743-.832l.578-5.318H3.743a.75.75 0 0 1-.622-1.17l5.917-8.85a.75.75 0 0 1 .878-.285.75.75 0 0 1 .487.75l-.577 5.355h4.417a.75.75 0 0 1 .623 1.17l-5.918 8.85a.75.75 0 0 1-.622.33Z' fill='%235779D1'/%3E%3C/svg%3E");
	background-size: 1.25rem auto;
	background-position: center;
	background-repeat: no-repeat
}

.mty-box-name {
	--lines: 2;
	--line-height: 1.22;
	--font-size: 1.125rem;
	font-size: var(--font-size);
	line-height: var(--line-height);
	font-weight: 600;
	height: calc(1em * var(--lines) * var(--line-height));
	margin-bottom: 2rem
}

.mty-box-name .badge {
	padding: .25em .5em !important;
	margin-left: .5em
}

.mty-box-price {
	margin-bottom: 2.5rem;
	-ms-flex-positive: 1;
	flex-grow: 1;
	line-height: 1.25;
	font-size: 1.0625rem
}

.mty-box-price .price {
	font-size: 1.5rem;
	font-weight: 600
}

.mty-box-price .price u {
	text-decoration: underline;
	-webkit-text-decoration-color: rgba(47, 50, 73, .4);
	text-decoration-color: rgba(47, 50, 73, .4)
}

.mty-box-price .description {
	opacity: .5
}

.mty-box-price .description .price {
	font-size: 1.22em
}

.mty-box.-yearly .mty-box-price .price u {
	-webkit-text-decoration-color: rgba(255, 255, 255, .62);
	text-decoration-color: rgba(255, 255, 255, .62)
}

.mty-box.-yearly .mty-box-price .description {
	opacity: 1
}

.mty-box .badge,
.mty-box-footer .price {
	padding: 0 .4em .15em;
	border-radius: 6px;
	background-color: #e50300;
	color: #fff;
	font-size: 75%
}

.mty-box-footer {
	margin-top: auto;
	line-height: 1.1875;
	font-weight: 600
}

.mty-box-footer .price {
	background-color: #5779d1;
	color: #fff;
	font-size: 1em
}

.mty-box-footer .price .ts-price__old {
	color: currentColor;
	opacity: .62
}

.mty-box.-yearly .mty-box-footer {
	padding: .75em;
	background-color: #5779d1;
	border-radius: 6px;
	color: #fff
}

.plan-prepay {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	width: 100%;
	margin: 0 auto
}

@media (max-width:767.98px) {
	.plan-prepay {
		gap: .75rem
	}
}

@media (max-width:575.98px) {
	.plan-prepay {
		grid-template-columns: 1fr
	}
}

.plan-prepay-box {
	background-color: #f7f7f7;
	border-radius: 10px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 1.5rem;
	border: 2px solid transparent
}

@media (max-width:767.98px) {
	.plan-prepay-box {
		padding: 1.5rem 1rem 1rem
	}
}

.plan-prepay-box.-prepay {
	border-color: #5dd39e
}

@media (min-width:576px) {
	.plan-prepay-box.-prepay {
		-ms-flex-order: 2;
		order: 2
	}
}

.plan-prepay-box__heading {
	font-size: 1.35rem;
	margin-bottom: 1.5rem
}

@media (max-width:1200px) {
	.plan-prepay-box__heading {
		font-size: calc(1.26rem + .12vw)
	}
}

.plan-prepay-box__discounts {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
	font-size: 1.125rem
}

@media (max-width:767.98px) {
	.plan-prepay-box__discounts {
		font-size: 1rem
	}
}

.plan-prepay-box__discounts li {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: baseline;
	align-items: baseline;
	padding: .55em 0;
	border-bottom: 1px solid #e7eaed;
	line-height: 1.2
}

.plan-prepay-box__discounts li span {
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-right: 1rem
}

.plan-prepay-box__discounts li strong {
	text-align: right;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.plan-prepay-box__savings {
	padding: 2.25em 0
}

.plan-prepay-box__savings u {
	font-weight: 600;
	background-color: #5dd39e;
	color: #2f3249;
	padding: .35em 1em;
	border-radius: 4px;
	display: inline-block;
	line-height: 1;
	text-decoration: none;
	font-size: 1.125rem;
	margin-bottom: .75rem
}

.plan-prepay-box__savings .ts-price {
	font-weight: 700;
	font-size: 1.75em;
	display: block;
	line-height: 1
}

.plan-prepay-box__savings .per-day {
	margin-top: 1.5rem;
	font-size: 1.125rem
}

.plan-prepay-box__savings .per-day .ts-price {
	display: inline;
	font-size: 1em;
	font-weight: 700
}

.plan-prepay-box.-normal .plan-prepay-box__savings u {
	background-color: #2f3249;
	color: #fff
}

.plan-prepay-box__action {
	margin-top: auto
}

.plan-prepay-box__action .btn {
	width: 100%
}

.countdown {
	display: inline-block;
	font-weight: 700;
	font-size: 2.5rem;
	color: inherit;
	line-height: 1.1;
	padding-bottom: .75rem
}

@media (max-width:1200px) {
	.countdown {
		font-size: calc(1.375rem + 1.5vw)
	}
}

.countdown .delimeter {
	padding: 0 .1em
}

.countdown .value {
	position: relative
}

.countdown .value::after {
	text-align: center;
	font-weight: 300;
	font-size: .75rem;
	position: absolute;
	left: 50%;
	bottom: -.75rem;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%)
}

.countdown .value.days::after {
	content: "Days"
}

.countdown .value.hours::after {
	content: "Hours"
}

.countdown .value.minutes::after {
	content: "Minutes"
}

.countdown .value.seconds::after {
	content: "Seconds"
}

.timeline {
	padding: 40px 0;
	position: relative;
	line-height: 1.39;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10rem
}

@media (max-width:1199.98px) {
	.timeline {
		gap: 4rem
	}
}

@media (max-width:991.98px) {
	.timeline {
		grid-template-columns: 1fr;
		gap: 2.5rem;
		padding: 0
	}
}

@media (min-width:992px) {
	.timeline::before {
		content: "";
		width: 13px;
		height: 13px;
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: #5779d1;
		border-radius: 50%;
		z-index: 2;
		box-shadow: 0 0 0 10px rgba(87, 121, 209, .18)
	}

	.timeline::after {
		content: "";
		width: 0;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		border-right: 1px dashed rgba(47, 50, 73, .17);
		z-index: 1
	}
}

.timeline-list {
	font-size: 1.125rem
}

@media (max-width:991.98px) {
	.timeline-list {
		font-size: 1rem
	}
}

.timeline-list .icon {
	-webkit-transform: translateY(.25em);
	transform: translateY(.25em)
}

.timeline-mockup {
	-webkit-filter: drop-shadow(9px 0 13px rgba(47, 50, 73, .28));
	filter: drop-shadow(9px 0 13px rgba(47, 50, 73, .28))
}

@media (min-width:1200px) {
	.timeline-mockup {
		max-width: calc(100% + 4rem);
		margin-right: -4rem
	}
}

.timeline-arrow {
	width: 1rem;
	height: 1rem;
	background-color: transparent;
	border: 1px solid rgba(47, 50, 73, .17);
	border-top-color: transparent;
	border-left-color: transparent;
	position: absolute;
	top: 100%;
	left: calc(50% + .5px);
	-webkit-transform: translate(-50%, -50%) rotate(45deg);
	transform: translate(-50%, -50%) rotate(45deg)
}

.table-before-after {
	background-color: #fff;
	border-radius: 1rem;
	box-shadow: 0 0 32px rgba(0, 0, 0, .08);
	width: 100%;
	font-size: 14px;
	line-height: 1.25;
	overflow: hidden
}

@media (max-width:1199.98px) {
	.table-before-after {
		font-size: 13px
	}
}

@media (max-width:767.98px) {
	.table-before-after {
		font-size: 12px
	}
}

@media (max-width:420px) {
	.table-before-after {
		font-size: 11px
	}
}

.table-before-after td,
.table-before-after th {
	padding: .43em 1em;
	border: 1px solid rgba(0, 0, 0, .03)
}

.table-before-after thead {
	font-size: 125%;
	color: #3952af;
	text-align: center
}

.table-before-after thead td:first-child,
.table-before-after thead th:first-child {
	background-color: #2f3249;
	border-color: rgba(255, 255, 255, .02)
}

.table-before-after tbody th {
	font-weight: 400;
	background-color: #2f3249;
	color: #fff;
	text-align: right;
	border-color: rgba(255, 255, 255, .02)
}

.table-before-after tbody td {
	text-align: center
}

.platform-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: .5rem 1rem
}

@media (max-width:575.98px) {
	.platform-grid {
		grid-template-columns: 1fr
	}
}

.platform-grid .site-menu-link {
	min-height: 90px;
	position: relative;
	-ms-flex-align: center;
	align-items: center;
	padding: 1rem;
	border-radius: 10px;
	transition: background-color .25s ease
}

@media (max-width:1479.98px) {
	.platform-grid .site-menu-link {
		min-height: 70px
	}
}

@media (max-width:767.98px) {
	.platform-grid .site-menu-link {
		background-color: #f8fafc
	}
}

.platform-grid .site-menu-link.-all,
.platform-grid .site-menu-link:hover {
	background-color: #f8fafc
}

.platform-grid .site-menu-link__icon {
	-webkit-transform: none;
	transform: none;
	position: relative
}

.platform-grid .site-menu-link.-all {
	text-align: center;
	-ms-flex-pack: center;
	justify-content: center
}

@media (max-width:1479.98px) {
	.testimonials {
		background-size: 140px;
		background-position: 40px 50%, right 40px top 50%
	}
}

@media (max-width:991.98px) {
	.testimonials {
		background-size: 100px;
		background-position: 30px 95%, right 30px top 95%
	}
}

@media (max-width:767.98px) {
	.testimonials {
		padding: 45px 2rem 40px
	}
}

@media (max-width:575.98px) {
	.testimonials {
		background-size: 70px;
		background-position: 10px 95%, right 10px top 95%;
		padding: 45px 1rem 40px
	}
}

.testimonials .slider-testimonials,
.testimonials-title {
	width: 550px;
	margin: 0 auto
}

@media (max-width:1199.98px) {

	.testimonials .slider-testimonials,
	.testimonials-title {
		width: 500px
	}
}

@media (max-width:991.98px) {

	.testimonials .slider-testimonials,
	.testimonials-title {
		width: 100%
	}
}

.testimonials .slider-testimonials {
	box-shadow: none
}

.testimonials .slider-testimonials:not(.swiper-container-initialized) {
	height: 125px
}

.testimonials .slider-testimonials-pagination {
	position: relative;
	bottom: auto;
	margin-top: .75rem
}

.testimonials .slider-testimonials-pagination .swiper-pagination-bullet {
	background-color: #ddd
}

.testimonials .slider-testimonials-pagination .swiper-pagination-bullet-active {
	background-color: #3952af
}

.testimonials .text-formatted {
	line-height: 1.36
}

@media (min-width:992px) {
	.testimonials .text-formatted {
		font-size: 1.375rem
	}
}

.stats-grid {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: baseline;
	align-items: baseline;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: -2rem
}

.stats-grid .stats-item {
	padding: 0 1rem;
	text-align: center;
	margin-bottom: 2rem
}

@media (min-width:576px) {
	.stats-grid .stats-item {
		padding: 0 1.5rem
	}
}

@media (min-width:992px) {
	.stats-grid .stats-item {
		padding: 0 2rem;
		text-align: left
	}

	.stats-grid .stats-item:not(:last-child) {
		border-right: 1px solid #eff4f9
	}
}

@media (min-width:1200px) {
	.stats-grid .stats-item {
		padding: 0 55px
	}
}

@media (max-width:767.98px) {
	.stats-grid .stats-item {
		width: 50%
	}
}

.stats-item-title {
	line-height: .75;
	font-weight: 700;
	margin-bottom: .65rem;
	font-size: 2.5rem
}

@media (max-width:1200px) {
	.stats-item-title {
		font-size: calc(1.375rem + 1.5vw)
	}
}

@media (min-width:1200px) {
	.stats-item-title {
		font-size: 3.75rem
	}
}

.stats-item-label {
	font-size: 1.125rem;
	white-space: nowrap
}

@media (max-width:575.98px) {
	.stats-item-label {
		font-size: .875rem
	}
}

.news-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 2rem;
	gap: 2rem
}

@media (max-width:1199.98px) {
	.news-grid {
		grid-template-columns: repeat(2, 1fr)
	}
}

@media (max-width:991.98px) {
	.news-grid {
		grid-template-columns: 1fr
	}
}

.partners-brokers {
	--columns: 3;
	--gap: 65px;
	display: grid;
	gap: var(--gap);
	grid-template-columns: repeat(var(--columns), 1fr)
}

@media (max-width:1199.98px) {
	.partners-brokers {
		--gap: 3rem 2rem
	}
}

@media (max-width:767.98px) {
	.partners-brokers {
		--columns: 2;
		--gap: 2rem
	}
}

.become-partner-broker {
	max-width: 975px;
	margin: 0 auto;
	background-color: #f4f4f4;
	border-radius: 10px;
	padding: 20px;
	font-size: 1.25rem;
	line-height: 1.35;
	font-weight: 600;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 1rem 2.5rem
}

@media (max-width:767.98px) {
	.become-partner-broker {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
}

.become-partner-broker .btn {
	padding-top: .55rem;
	padding-bottom: .55rem
}

[data-badge] {
	position: relative;
	font-size: 12px;
	text-transform: none;
	padding: .3em .7em;
	font-weight: 400;
	border-radius: .5em;
	line-height: 1;
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition-property: opacity, visibility, -webkit-transform;
	transition-property: opacity, visibility, transform;
	transition-property: opacity, visibility, transform, -webkit-transform;
	transition-duration: .15s;
	transition-timing-function: cubic-bezier(.61, .13, .42, 1.285);
	will-change: transform
}

[data-badge][aria-hidden] {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: scale(.55);
	transform: scale(.55)
}

[data-badge].-sale {
	background-color: #e50300;
	color: #fff
}

[data-badge].-promo {
	background-color: #5dd39e;
	color: #fff
}

.sale-notice {
	--accent-bg-color: #F26868;
	--accent-color: #ffffff;
	--bg-color: #5DD39E;
	--color: #ffffff;
	position: absolute;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 150;
	background-color: var(--bg-color);
	color: var(--color);
	text-align: center;
	line-height: 1;
	height: var(--header-top-height);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: .25em 1.25em;
	font-size: 1rem
}

@media (max-width:1199.98px) {
	.sale-notice {
		font-size: .75rem
	}
}

@media (max-width:991.98px) {
	.sale-notice {
		font-size: .65rem
	}
}

@media (max-width:767.98px) {
	.sale-notice {
		font-size: .55rem
	}
}

@media (max-width:575.98px) {
	.sale-notice {
		font-size: .55rem;
		height: 70px
	}
}

.sale-notice__title {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.5em
}

.sale-notice__discount {
	font-size: 1.5em;
	font-weight: 700;
	padding: .25em .5em;
	background-color: var(--accent-bg-color);
	color: var(--accent-color);
	border-radius: .25em;
	margin: 0 1em;
	text-transform: uppercase
}

.sale-notice__countdown {
	display: -ms-inline-flexbox;
	display: inline-flex
}

.sale-notice__countdown .px-2 {
	padding: 0 !important
}

@media (max-width:575.98px) {
	.sale-notice__countdown {
		width: 100%;
		-ms-flex-pack: center;
		justify-content: center
	}
}

.sale-notice .sale-countdown {
	font-size: 1em;
	margin: 0;
	line-height: inherit;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center
}

.sale-notice .sale-countdown::after,
.sale-notice .sale-countdown::before {
	display: none
}

.sale-notice .sale-countdown .text-nowrap {
	display: none
}

.sale-notice .sale-countdown .pl-md-3 {
	padding-left: 0 !important
}

.sale-notice .sale-countdown .label,
.sale-notice .sale-countdown .value {
	font-size: 1.125em
}

.sale-notice .sale-countdown .label:not(:last-child) {
	margin-right: .35em
}

.sale-notice .sale-countdown .value {
	font-weight: 700
}

.sale-notice__cta {
	padding: 0 1.25em
}

.sale-notice__cta .btn {
	font-size: 1.125em;
	padding: .25em 1em
}

.sale-notice__close {
	position: absolute;
	right: 1.25em;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	text-transform: uppercase
}

@media (max-width:991.98px) {
	.sale-notice__close {
		top: 100%;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: var(--bg-color);
		border-radius: 0 0 4px 4px;
		width: 6em
	}
}

.sale-notice__close a {
	color: inherit
}

@media (max-width:991.98px) {
	.sale-notice__close a {
		display: block;
		padding: .25em .25em
	}
}

body.home .sale-notice,
body.pricing-page .sale-notice {
	display: none !important
}

.sale-notice.-sale-winter-sale {
	--accent-bg-color: #E50300;
	--accent-color: #fff;
	--bg-color: #9BC8FF;
	--color: #fff
}

.sale-notice.-sale-holiday-sale {
	--accent-bg-color: #E0D6C6;
	--accent-color: #1d1f2f;
	--bg-color: #C12B2B;
	--color: #fff
}

.sale-notice.-sale-new-year-sale {
	--accent-bg-color: #E50300;
	--accent-color: #fff;
	--bg-color: #3C99FF;
	--color: #fff
}

.sale-notice.-sale-start-of-year-sale {
	--accent-bg-color: #E50300;
	--accent-color: #fff;
	--bg-color: #F5BD52;
	--color: #000000
}

.section {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center
}

.section-full-page-bg {
	position: absolute;
	width: 100vw;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 0
}

.section-full-page-bg.stick-bottom {
	bottom: 0
}

.section-full-page-bg.stick-top {
	top: 0
}

.section-full-page-bg.stick-center {
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.section-edge {
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: auto;
	z-index: 0;
	pointer-events: none
}

@media (max-width:767.98px) {
	.section-edge {
		bottom: -1px
	}
}

.section-edge--top {
	bottom: auto;
	top: 0
}

.section-hero {
	background-size: cover;
	background-position: center center;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	background-color: #2f3249;
	position: relative;
	min-height: 700px;
	height: auto;
	padding-top: 80px
}

@media (max-width:1479.98px) {
	.section-hero {
		min-height: 270px
	}
}

@media (max-width:1199.98px) {
	.section-hero {
		min-height: 200px;
		text-align: center
	}

	.section-hero form {
		margin-left: auto !important;
		margin-right: auto !important
	}
}

@media (max-width:575.98px) {
	.section-hero {
		padding-top: 65px
	}
}

.section-hero-sm {
	min-height: 380px;
	padding: 60px 0 80px
}

@media (max-width:1479.98px) {
	.section-hero-sm {
		min-height: 320px
	}
}

@media (max-width:575.98px) {
	.section-hero-sm {
		padding: 60px 0
	}
}

.section-hero-auto {
	height: auto;
	min-height: initial
}

.section-hero-full {
	min-height: 100%
}

@media (max-width:1479.98px) {
	.section-hero .section-heading:not(:last-child) {
		margin-bottom: 2rem
	}
}

@media (max-width:1199.98px) {
	.section-hero .section-heading {
		margin-left: auto !important;
		margin-right: auto !important;
		text-align: center
	}

	.section-hero .section-heading br {
		display: none
	}

	.section-hero .section-heading .display-3 {
		font-size: calc(1rem + 3.9vw)
	}
}

@media (max-width:767.98px) {
	.section-hero .section-heading {
		max-width: 100%
	}

	.section-hero .section-heading .display-2,
	.section-hero .section-heading .display-3,
	.section-hero .section-heading .display-4 {
		line-height: 1.2
	}

	.section-hero .section-heading:not(:last-child) {
		margin-bottom: 1rem
	}
}

.section-hero .section-heading-subtitle {
	max-width: 95%;
	font-size: 1.25rem
}

@media (max-width:1199.98px) {
	.section-hero .section-heading-subtitle {
		margin: 0 auto;
		font-size: 1.125rem
	}
}

@media (max-width:575.98px) {
	.section-hero .section-heading-subtitle {
		font-size: 1rem;
		max-width: 100%
	}
}

.section-hero .section-heading footer .btn-wide {
	min-width: 215px
}

.section-hero .list-social {
	position: absolute;
	z-index: 10;
	left: 1.6vw;
	bottom: 12vw
}

@media (max-width:1199.98px) {

	.section-hero .list-social .btn-icon,
	.section-hero .list-social .btn-icon .icon {
		font-size: 12px
	}
}

.section-hero .hero-negative-lg {
	position: relative;
	z-index: 2;
	margin-bottom: calc((280px + 1rem) * -1)
}

@media (max-width:1199.98px) {
	.section-hero .hero-negative-lg {
		margin-bottom: calc((280px + 2rem) * -1)
	}
}

@media (max-width:991.98px) {
	.section-hero .hero-negative-lg {
		margin-bottom: calc((160px + 4.5rem) * -1)
	}
}

.section-hero .hero-negative-md {
	position: relative;
	z-index: 2;
	margin-bottom: -180px
}

@media (max-width:1199.98px) {
	.section-hero .hero-negative-md {
		margin-bottom: calc((100px + 6rem) * -1)
	}
}

@media (max-width:991.98px) {
	.section-hero .hero-negative-md {
		margin-bottom: calc((100px + 4.5rem) * -1)
	}
}

.section-hero.text-center .section-heading {
	margin-left: auto;
	margin-right: auto
}

.section-hero.text-center .section-heading .section-heading-subtitle {
	margin-left: auto;
	margin-right: auto
}

.section-hero.has-negative-lg {
	margin-bottom: 240px
}

@media (max-width:991.98px) {
	.section-hero.has-negative-lg {
		margin-bottom: 160px
	}
}

.section-hero.has-negative-md {
	margin-bottom: 100px
}

.circle-decoration {
	position: relative
}

.circle-decoration>div {
	position: relative;
	z-index: 2
}

.circle-decoration img {
	-webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .2));
	filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .2))
}

.circle-decoration .card-testimonial {
	width: 420px;
	max-width: 100%;
	padding: 3.5rem 2rem 3.5rem
}

@media (max-width:767.98px) {
	.circle-decoration .card-testimonial {
		padding: 3rem 1rem
	}
}

.circle-decoration .card-testimonial .testimonial-body {
	font-size: 1.5rem;
	line-height: 1.3
}

@media (max-width:767.98px) {
	.circle-decoration .card-testimonial .testimonial-body {
		font-size: 1.25rem
	}
}

.circle-decoration:before {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 90%;
	width: 920px;
	height: 920px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-image: url(../images/background/circle.svg);
	background-size: 100% 100%
}

@media (max-width:1700px) {
	.circle-decoration:before {
		width: 840px;
		height: 840px
	}
}

@media (max-width:1479.98px) {
	.circle-decoration:before {
		width: 800px;
		height: 800px
	}
}

@media (max-width:1199.98px) {
	.circle-decoration:before {
		display: none
	}
}

.circle-decoration-primary:before {
	width: 780px;
	height: 780px;
	left: 88%;
	background-image: url(../images/background/circle-primary.svg)
}

@media (max-width:1479.98px) {
	.circle-decoration-primary:before {
		left: 109%
	}
}

.section-hero-pricing .list-social {
	bottom: auto;
	bottom: 13vw
}

@media (max-width:1280px) {
	.section-hero-pricing .list-social {
		display: none !important
	}
}

.section-hero-pricing-lp {
	position: relative;
	padding: 90px 0 150px;
	background-color: #2f3249
}

@media (max-width:1199.98px) {
	.section-hero-pricing-lp {
		padding: 60px 0 80px
	}
}

@media (max-width:575.98px) {
	.section-hero-pricing-lp {
		padding-top: 50px
	}
}

.section-hero-pricing-lp::before {
	content: "";
	width: 100%;
	height: 40px;
	background: linear-gradient(180deg, #2f3249 0, rgba(47, 50, 73, 0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5
}

.section-hero-pricing-lp .section-edge {
	z-index: 2
}

.section-hero-pricing-lp .section-heading {
	position: relative;
	z-index: 10
}

.section-hero-pricing-lp .section-heading-title {
	line-heigth: .95
}

.section-hero-pricing-lp .section-heading-title span {
	display: inline-block
}

.section-hero-pricing-lp .rotate-block {
	display: block;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	position: relative;
	height: .95em;
	white-space: nowrap
}

@media (max-width:1199.98px) {
	.section-hero-pricing-lp .rotate-block {
		width: 100% !important;
		text-align: inherit;
		white-space: normal
	}
}

.section-hero-pricing-lp .rotate-block .rotate-word {
	display: block;
	-webkit-transform: rotateX(85deg) translateY(-150%);
	transform: rotateX(85deg) translateY(-150%);
	opacity: 0;
	-webkit-transform-origin: center left 50%;
	transform-origin: center left 50%;
	position: absolute;
	transition: all .35s ease-in-out
}

@media (max-width:1199.98px) {
	.section-hero-pricing-lp .rotate-block .rotate-word {
		display: block;
		width: 100%;
		-webkit-transform-origin: center center 50%;
		transform-origin: center center 50%
	}
}

.section-hero-pricing-lp .rotate-block .rotate-word.-is-active {
	opacity: 1;
	-webkit-transform: rotateX(0) translateY(0);
	transform: rotateX(0) translateY(0)
}

.section-hero-pricing-lp .rotate-block .rotate-word.-is-active~.rotate-word {
	opacity: 0;
	-webkit-transform: rotateX(-85deg) translateY(150%);
	transform: rotateX(-85deg) translateY(150%)
}

.section-before-footer {
	position: relative;
	z-index: 1
}

.section-before-footer .cta-apply,
.section-before-footer .cta-subscribe {
	position: relative;
	z-index: 1
}

.section-before-footer::after {
	content: "";
	width: 100%;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	background-color: #2f3249;
	z-index: 0
}

.epyt-gallery .epyt-gallery-subbutton {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
	-ms-flex-align: center !important;
	align-items: center !important
}

.epyt-gallery .epyt-gallery-subbutton img {
	padding-bottom: 0 !important
}

.epyt-gallery .epyt-pagenumbers>div,
.epyt-gallery .epyt-pagination>div {
	vertical-align: initial !important
}

.epyt-gallery .epyt-gallery-thumb {
	padding: 0 !important;
	overflow-y: visible !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-direction: column;
	flex-direction: column
}

.epyt-gallery .epyt-gallery-thumb>* {
	width: 100%
}

.epyt-gallery .epyt-gallery-thumb .epyt-gallery-img {
	border-radius: 4px
}

.epyt-gallery .epyt-gallery-thumb .epyt-gallery-img-box {
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.epyt-gallery .epyt-gallery-thumb .epyt-gallery-title {
	font-size: 1.5rem;
	font-weight: 700;
	text-align: left !important;
	line-height: 1.33;
	padding: .75rem 1.5rem 1rem;
	box-shadow: 0 0 46px rgba(0, 0, 0, .08);
	border-bottom-left-radius: .75rem;
	border-bottom-right-radius: .75rem;
	-ms-flex-positive: 1;
	flex-grow: 1
}

@media (max-width:1200px) {
	.epyt-gallery .epyt-gallery-thumb .epyt-gallery-title {
		font-size: calc(1.275rem + .3vw)
	}
}

@media (max-width:767.98px) {
	.epyt-gallery .epyt-gallery-thumb .epyt-gallery-title {
		font-size: 1.25rem;
		padding: .75rem 1rem
	}
}

@media (max-width:575.98px) {
	.epyt-gallery .epyt-gallery-thumb .epyt-gallery-title {
		font-size: 1rem
	}
}

.epyt-gallery .epyt-gallery-allthumbs {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin: 0 -1rem
}

.epyt-gallery .epyt-gallery-allthumbs .epyt-gallery-rowbreak {
	display: none
}

.epyt-gallery .epyt-gallery-allthumbs.epyt-cols-3 .epyt-gallery-thumb {
	margin-bottom: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
	width: calc(100% - 2rem) !important
}

@media (min-width:576px) {
	.epyt-gallery .epyt-gallery-allthumbs.epyt-cols-3 .epyt-gallery-thumb {
		width: calc(50% - 2rem) !important;
		margin-bottom: 2rem
	}
}

@media (min-width:992px) {
	.epyt-gallery .epyt-gallery-allthumbs.epyt-cols-3 .epyt-gallery-thumb {
		width: calc(33.33% - 2rem) !important
	}
}

.epyt-gallery .epyt-gallery-subscribe {
	padding-bottom: 0 !important
}

.epyt-gallery .epyt-gallery-list {
	border-top: 1px solid #eff4f9;
	padding-top: 3rem;
	margin: 3rem 0 0 0 !important
}

.epyt-gallery .epyt-gallery-list>.epyt-pagination:first-child {
	display: none
}

.section-show-preview {
	max-width: 1240px;
	margin: 0 auto;
	border-radius: 1rem
}

.section-partnership-proposal {
	position: relative
}

.section-partnership-proposal::before {
	content: "";
	width: 13px;
	height: 13px;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #5779d1;
	border-radius: 50%;
	z-index: 2;
	box-shadow: 0 0 0 10px #e1e7f7, 0 0 0 20px #fff
}

.section-partnership-proposal-content {
	background-color: #f8fafc;
	border-radius: .75rem;
	padding: 50px 1rem 100px
}

@media (max-width:991.98px) {
	.section-partnership-proposal-content {
		padding: 50px .75rem
	}
}

.section-platform {
	background-color: #2f3249;
	color: #fff;
	padding: 60px 0;
	min-height: calc(100vh - var(--header-height, 100px) + 2px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center
}

.section-platform .inner {
	max-width: 2500px;
	margin: 0 auto
}

.bg-img,
.event-banner {
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center center
}

.bg-img-top-center {
	background-position: center top
}

.bg-img-top-right {
	background-position: right top
}

.bg-img-top-left {
	background-position: left top
}

.bg-img-bottom-center {
	background-position: center bottom
}

.bg-img-bottom-right {
	background-position: right bottom
}

.bg-img-bottom-left {
	background-position: left bottom
}

.bg-img-center-center {
	background-position: center center
}

.bg-img-center-right {
	background-position: right center
}

.bg-img-center-left {
	background-position: left center
}

.bg-img-auto {
	background-size: auto
}

.bg-img-cover {
	background-size: cover
}

.bg-img-100,
.event-banner {
	background-size: 100% 100%
}

.bg-img-x-100 {
	background-size: 100% auto
}

.bg-img-y-100 {
	background-size: auto 100%
}

.bg-img-repeat {
	background-repeat: repeat
}

.bg-img-repeat-x {
	background-repeat: repeat-x
}

.bg-img-repeat-y {
	background-repeat: repeat-y
}

.bg-img-no-repeat {
	background-repeat: no-repeat
}

.bg-gray-100 {
	background-color: #f7f7f7;
	--bg-color: #f7f7f7
}

.bg-gray-200 {
	background-color: #f8fafc;
	--bg-color: #F8FAFC
}

.bg-gray-300 {
	background-color: #eff4f9;
	--bg-color: #EFF4F9
}

.bg-gray-400 {
	background-color: #e7eaed;
	--bg-color: #E7EAED
}

.bg-gray-500 {
	background-color: #97a4af;
	--bg-color: #97a4af
}

.bg-gray-600 {
	background-color: #8c98a4;
	--bg-color: #8c98a4
}

.bg-gray-700 {
	background-color: #77838f;
	--bg-color: #77838f
}

.bg-gray-800 {
	background-color: #343a40;
	--bg-color: #343a40
}

.bg-gray-900 {
	background-color: #1e2022;
	--bg-color: #1e2022
}

.bg-primary {
	--bg-color: #5DD39E
}

.bg-secondary {
	--bg-color: #50575e
}

.bg-success {
	--bg-color: #2e843f
}

.bg-info {
	--bg-color: #3952AF
}

.bg-warning {
	--bg-color: #FFDD4A
}

.bg-danger {
	--bg-color: #F26868
}

.bg-light {
	--bg-color: #ffffff
}

.bg-dark {
	--bg-color: #2F3249
}

.bg-cheddar {
	--bg-color: #E8BB28
}

.bg-whales {
	--bg-color: #3C5DE2
}

.bg-super {
	--bg-color: #ccc
}

.bg-scanner {
	background-color: #f4fbfd;
	--bg-color: #F4FBFD
}

.text-scanner {
	color: #f4fbfd
}

.bg-charting {
	background-color: #fffaf4;
	--bg-color: #FFFAF4
}

.text-charting {
	color: #fffaf4
}

.bg-insights {
	background-color: #fbf6fd;
	--bg-color: #FBF6FD
}

.text-insights {
	color: #fbf6fd
}

.bg-backtesting {
	background-color: #f5f7ff;
	--bg-color: #F5F7FF
}

.text-backtesting {
	color: #f5f7ff
}

.bg-alerts {
	background-color: #f5fcf4;
	--bg-color: #F5FCF4
}

.text-alerts {
	color: #f5fcf4
}

.bg-raindrops {
	background-color: #f1fbfa;
	--bg-color: #F1FBFA
}

.text-raindrops {
	color: #f1fbfa
}

.bg-unusual-options {
	background-color: #fff4f4;
	--bg-color: #FFF4F4
}

.text-unusual-options {
	color: #fff4f4
}

.bg-trading-bots {
	background-color: #fefbf2;
	--bg-color: #FEFBF2
}

.text-trading-bots {
	color: #fefbf2
}

.bg-patterns {
	background-color: #fcfcf4;
	--bg-color: #FCFCF4
}

.text-patterns {
	color: #fcfcf4
}

.container-narrow {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto
}

@media (min-width:576px) {
	.container-narrow {
		max-width: 540px
	}
}

@media (min-width:768px) {
	.container-narrow {
		max-width: 720px
	}
}

@media (min-width:992px) {
	.container-narrow {
		max-width: 960px
	}
}

@media (min-width:1200px) {
	.container-narrow {
		max-width: 1140px
	}
}

@media (min-width:1480px) {
	.container-narrow {
		max-width: 1420px
	}
}

@media (min-width:1480px) {
	.container-narrow {
		width: 1140px !important;
		max-width: 1140px !important
	}
}

.container-wide {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto
}

@media (min-width:576px) {
	.container-wide {
		max-width: 540px
	}
}

@media (min-width:768px) {
	.container-wide {
		max-width: 720px
	}
}

@media (min-width:992px) {
	.container-wide {
		max-width: 960px
	}
}

@media (min-width:1200px) {
	.container-wide {
		max-width: 1140px
	}
}

@media (min-width:1480px) {
	.container-wide {
		max-width: 1420px
	}
}

.event-banner,
.ratio,
.screen-image {
	position: relative
}

.event-banner,
.ratio-16x9.screen-image,
.ratio.ratio-16x9 {
	--aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ratio-content iframe {
	width: 100%;
	height: 100%
}

.event-banner:before,
.ratio:before,
.screen-image:before {
	display: block;
	padding-top: var(--aspect-ratio);
	content: ""
}

.grid-preview-title {
	line-height: 1.1;
	font-size: 1.5em;
	margin-bottom: 1rem
}

@media (max-width:1199.98px) {
	.grid-preview-title {
		margin-bottom: 1rem
	}
}

@media (max-width:991.98px) {
	.grid-preview-col:first-child {
		margin-bottom: 2rem
	}
}

.grid-preview-col .iframe-placeholder,
.grid-preview-col .video-preview,
.grid-preview-col iframe,
.grid-preview-col video {
	border-radius: 4px;
	overflow: hidden
}

.grid-preview-col div+div {
	margin-top: 9%
}

@media (max-width:1199.98px) {
	.grid-preview-col div+div {
		margin-top: 1rem
	}
}

.top-0 {
	top: 0
}

.right-0 {
	right: 0
}

.bottom-0 {
	bottom: 0
}

.left-0 {
	left: 0
}

.top-100 {
	top: 100%
}

.left-50 {
	left: 50%
}

.translate-n50 {
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.absoulte-fill,
.event-banner-content,
.video-preview,
.video-preview img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%
}

.absolute-center,
.absoulte-center,
.video-preview:after {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.event-banner-content,
.flex-column {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.event-banner-content,
.flex-center {
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center
}

.position-center {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.position-vertical-center {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.position-horizontal-center {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%)
}

.line-height-lg {
	line-height: 2 !important
}

.line-height-sm {
	line-height: 1.4 !important
}

.text-lg,
.text-lg p {
	font-size: 1.25rem !important
}

.text-md,
.text-md p {
	font-size: 1.125rem !important
}

.text-sm,
.text-sm p {
	font-size: .875rem !important
}

.text-xl,
.text-xl p {
	font-size: 1.75rem !important
}

.font-size-base,
.text-base {
	font-size: 1rem !important
}

.text-larger {
	font-size: 112.5%
}

.font-weight-light {
	font-weight: 300
}

.font-weight-normal {
	font-weight: 400
}

.font-weight-medium {
	font-weight: 500
}

.font-weight-semibold {
	font-weight: 600
}

.font-weight-bold,
strong {
	font-weight: 700
}

.font-weight-black {
	font-weight: 900
}

.h1.lg {
	font-size: 2.8125rem
}

@media (max-width:1200px) {
	.h1.lg {
		font-size: calc(1.40625rem + 1.875vw)
	}
}

.display-4 {
	line-height: 1.1
}

.h2,
.h3,
.h4,
.h5 {
	line-height: 1.375
}

@media (min-width:1200px) {
	.text-lg-dark {
		color: #2f3249 !important
	}
}

hr.vr {
	width: 0;
	border-top: 0;
	border-right: 1px solid #eff4f9;
	height: 100%;
	margin: 0
}

.text-muted {
	color: currentColor !important;
	opacity: .75
}

.z-index-1 {
	z-index: 1 !important
}

.z-index-2 {
	z-index: 2 !important
}

.z-index-n1 {
	z-index: -1 !important
}

.z-index-n2 {
	z-index: -2 !important
}

.z-index-20 {
	z-index: 20
}

@media (min-width:1200px) {
	.mt-xl-n10 {
		margin-top: -11rem !important
	}
}

.embed-responsive-item-cover {
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	z-index: 10;
	cursor: pointer
}

.cursor-zoom {
	cursor: -webkit-zoom-in;
	cursor: zoom-in
}

[data-tooltip] {
	position: relative
}

[data-tooltip]::after {
	content: attr(data-tooltip);
	width: 200px;
	position: absolute;
	z-index: 10;
	bottom: calc(100% + 4px);
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	font-size: 14px;
	border-radius: .5rem;
	padding: .75rem;
	font-weight: 400;
	line-height: 1.25;
	font-style: normal;
	background-color: #fff;
	box-shadow: 0 0 4px 0 rgba(47, 50, 73, .15);
	opacity: 0;
	visibility: hidden;
	transition: all .15s ease
}

@media (max-width:767.98px) {
	[data-tooltip]::after {
		left: auto;
		right: 0;
		-webkit-transform: translateX(0);
		transform: translateX(0)
	}
}

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
	opacity: 1;
	visibility: visible
}